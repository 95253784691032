import { DateField } from '@daisy/daisy-common';
import { FormControl, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import { toast } from 'react-toastify';

import LabelNodeComponent from './label-node.component';

interface Props extends DateField {
  error: boolean;
  helperText: string;
  value: string;
  onChange: (field: string, value: string | string[]) => void;
}

const DateFieldComponent: React.FC<Props> = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl variant="standard" sx={{ mb: 3, display: 'block' }}>
        <DesktopDatePicker
          label={
            <LabelNodeComponent
              title={props.label}
              description={props.description}
            />
          }
          inputFormat="DD-MM-YYYY"
          value={props.value}
          onChange={(value) => {
            try {
              const date = new Date(value ?? '').toISOString();
              props.onChange(props.name, date);
            } catch (error) {
              toast.error('Invalid date');
              props.onChange(props.name, new Date(Date.now()).toISOString());
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              sx={{ minWidth: 1 / 1 }}
              error={props.error}
              helperText={props.helperText}
            />
          )}
        />
      </FormControl>
    </LocalizationProvider>
  );
};

export default DateFieldComponent;
