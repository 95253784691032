import { InventoryNumberField } from '@daisy/daisy-common';
import { FormControl, InputAdornment, TextField } from '@mui/material';
import { FormikHandlers } from 'formik';
import React from 'react';

import LabelNodeComponent from './label-node.component';

interface Props extends InventoryNumberField {
  error: boolean;
  helperText: string;
  value: string;
  onChange: FormikHandlers['handleChange'];
}

const InventoryNumberFieldComponent: React.FC<Props> = (props) => {
  return (
    <FormControl variant="standard" sx={{ mb: 3, display: 'flex' }}>
      <TextField
        name={props.name}
        label={
          <LabelNodeComponent
            title={props.label}
            description={props.description}
          />
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{props.prefix}</InputAdornment>
          ),
        }}
        sx={{ minWidth: 1 / 1 }}
        error={props.error}
        value={props.value ?? ''}
        variant="standard"
        onChange={props.onChange}
        helperText={props.helperText}
      />
    </FormControl>
  );
};

export default InventoryNumberFieldComponent;
