import {
  MissionDto,
  MissionUserDto,
  NodeType,
  UserRole,
} from '@daisy/daisy-common';
import { Divider, Step, StepLabel, Stepper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import EntitiesFormComponent from '../../components/entities-form/entities-form.component';
import MissionConfiguratorReviewComponent from '../../components/mission-configurator-review/mission-configurator-review.component';
import MissionConfiguratorSubmissionComponent from '../../components/mission-configurator-submission/mission-configurator-submission.component';
import MissionDetailsFormComponent from '../../components/mission-details-form/mission-details-form.component';
import MissionUsersFormComponent from '../../components/mission-users-form/mission-users-form.component';
import { useAdmin } from '../../hooks/useAdmin';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { selectNodeConfig } from '../../store/features/config';
import {
  resetConfigurator,
  selectMissionDetails,
  selectStep,
  selectUsers,
  setMissionDetails,
  setStep,
  setUsers,
} from '../../store/features/mission-configurator';
import { selectUser } from '../../store/features/user';

const MissionConfiguratorPage: React.FC = () => {
  useAdmin();
  const { nodeType } = useSelector(selectNodeConfig);
  const navigate = useNavigate();
  useEffect(() => {
    if (nodeType === NodeType.CHILD) {
      navigate(-1);
      toast.error("Can't create new mission when in child mode"); // eslint-disable-line
    }
  }, []);
  const step = useSelector(selectStep);
  const [activeStep, setActiveStep] = useState(step || 0);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const initialDetailsData = useSelector(selectMissionDetails);
  const initialUsersData = { users: useSelector(selectUsers) };
  const handleDetailsSubmit = (values: MissionDto) => {
    dispatch(setMissionDetails(values));
    dispatch(setStep(activeStep + 1));
  };

  const handleEntitiesSubmit = () => {
    dispatch(setStep(activeStep + 1));
  };
  const handleUsersSubmit = ({ users }: { users: MissionUserDto[] }) => {
    const data = [...users];
    if (!users.find((usr) => usr.userId === user?.id)) {
      data.unshift({
        userId: user?.id || '',
        userRole: UserRole.ADMIN,
      });
    }
    dispatch(setUsers(data));
    dispatch(setStep(activeStep + 1));
  };
  useEffect(() => {
    if (step || step === 0) {
      setActiveStep(step);
    }
  }, [step]);
  useBreadcrumb([
    { name: 'Expeditions', path: '/expeditions' },
    { name: 'Configurator', path: '/create' },
  ]);
  const [blockUnload, setBlockUnload] = useState(true);

  useBeforeunload((e) => {
    if (blockUnload) {
      e.preventDefault();
    }
  });

  useEffect(() => {
    return () => {
      dispatch(resetConfigurator());
    };
  }, []);
  return (
    <div>
      <Helmet title="Configure expedition" />
      <div className="div-btn-header width100" style={{ marginBottom: '2%' }}>
        <Stepper activeStep={activeStep}>
          <Step key="details">
            <StepLabel>Expedition details</StepLabel>
          </Step>
          <Step key="entities">
            <StepLabel>Entities</StepLabel>
          </Step>
          <Step key="users">
            <StepLabel>Users</StepLabel>
          </Step>
          <Step key="review">
            <StepLabel>Review</StepLabel>
          </Step>
          <Step key="submission">
            <StepLabel>Submission</StepLabel>
          </Step>
        </Stepper>
      </div>
      <div className="width100">
        <Divider sx={{ mb: 3 }} />
        {activeStep === 0 && (
          <MissionDetailsFormComponent
            initialData={initialDetailsData}
            handleSubmit={handleDetailsSubmit}
          />
        )}
        {activeStep === 1 && (
          <EntitiesFormComponent
            activeStep={activeStep}
            handleSubmit={handleEntitiesSubmit}
          />
        )}
        {activeStep === 2 && (
          <MissionUsersFormComponent
            activeStep={activeStep}
            initialData={initialUsersData}
            handleSubmit={handleUsersSubmit}
          />
        )}
        {activeStep === 3 && (
          <MissionConfiguratorReviewComponent activeStep={activeStep} />
        )}
        {activeStep === 4 && (
          <MissionConfiguratorSubmissionComponent
            activeStep={activeStep}
            setBlockUnload={setBlockUnload}
          />
        )}
      </div>
    </div>
  );
};

export default MissionConfiguratorPage;
