import { Button, TextField } from '@mui/material';
import React from 'react';

type Props = {
  formik: any; // eslint-disable-line
  isLoading: boolean;
  closeCallback?: () => void;
};

const RegisterFormComponent: React.FC<Props> = ({
  formik,
  isLoading,
  closeCallback,
}) => {
  return (
    <div>
      <div className="login-inputs">
        <TextField
          id="email"
          className="width100"
          label="email"
          margin="normal"
          variant="standard"
          type="email"
          onChange={formik.handleChange}
          value={formik.values.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          disabled={isLoading}
        />
      </div>
      <div className="login-inputs">
        <TextField
          id="name"
          className="width100"
          label="full name"
          margin="normal"
          variant="standard"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.name}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          disabled={isLoading}
        />
      </div>
      <div className="login-inputs">
        <TextField
          id="password"
          className="width100"
          label="password"
          type="password"
          margin="normal"
          variant="standard"
          onChange={formik.handleChange}
          value={formik.values.password}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          disabled={isLoading}
        />
      </div>
      <div className="login-inputs">
        <TextField
          id="confirmPassword"
          className="width100"
          label="confirm password"
          type="password"
          margin="normal"
          variant="standard"
          onChange={formik.handleChange}
          value={formik.values.confirmPassword}
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
          disabled={isLoading}
        />
      </div>

      <div className="btn-form-footer btn-footer-login flex">
        {closeCallback && (
          <Button
            className="login-button add-btn"
            sx={{ mr: 3 }}
            color="primary"
            type="button"
            disabled={isLoading}
            onClick={closeCallback}
          >
            <span className="add-btn-text">cancel</span>
          </Button>
        )}
        <Button
          className="login-button add-btn"
          color="primary"
          type="submit"
          disabled={isLoading}
        >
          <span className="add-btn-text">enter</span>
        </Button>
      </div>
    </div>
  );
};

export default RegisterFormComponent;
