import { FileField } from '@daisy/daisy-common';
import { FormControl, FormHelperText, Typography } from '@mui/material';
import { FormikErrors } from 'formik';
import React from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { toast } from 'react-toastify';

import LabelNodeComponent from './label-node.component';

interface Props extends FileField {
  error: boolean;
  helperText: string;
  value: string;
  onChange: (
    field: string,
    value: File | File[],
  ) => Promise<FormikErrors<any>> | Promise<void>; // eslint-disable-line
}

const FileFieldComponent: React.FC<Props> = (props: Props) => {
  /* eslint-disable */
  const handleFilesChange = (files: any) => {
    /* eslint-enable */
    if (files.length === 1 && !props.multi) {
      props.onChange(props.name, files);
    } else {
      const filesList: File[] = [];
      for (let index = 0; index < files.length; index++) {
        filesList[index] = files.item(index);
      }
      props.onChange(props.name, filesList);
    }
    toast.success('File added!');
  };
  return (
    <FormControl
      variant="standard"
      sx={{ mb: 3, minWidth: 1 / 1, display: 'block' }}
    >
      <Typography variant="h6">
        <LabelNodeComponent
          title={props.label}
          description={props.description}
        />
      </Typography>
      {[...props.allowedFormats].length ? (
        <FileUploader
          handleChange={handleFilesChange}
          name={props.name}
          types={[...props.allowedFormats].map((el) => el.replace('.', ''))}
          multiple={props.multi}
          label={props.label}
          classes="uploader"
          onTypeError={(err: string) => toast.error(err)}
        />
      ) : (
        <FileUploader
          handleChange={handleFilesChange}
          name={props.name}
          multiple={props.multi}
          label={props.label}
          classes="uploader"
          onTypeError={(err: string) => toast.error(err)}
        />
      )}
      {props.error && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
};

export default FileFieldComponent;
