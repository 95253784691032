import { Item, ListResponse, urlGenerator } from '@daisy/daisy-common';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const itemApi = createApi({
  reducerPath: 'itemApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/item' }),
  tagTypes: ['entities', 'Item'],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getItems: builder.query<
      ListResponse<Item>,
      { missionId: string; entityId: string; page: number }
    >({
      query: ({ missionId, entityId, page }) => ({
        url: `/${missionId}/schema/${entityId}?${page ? `page=${page}` : ''}`,
        credentials: 'include',
      }),
      providesTags: ['entities'],
    }),
    deleteItem: builder.mutation<void, { missionId: string; itemId: string }>({
      query: ({ missionId, itemId }) => ({
        url: `/${missionId}/${itemId}`,
        method: 'DELETE',
        credentials: 'include',
      }),
      invalidatesTags: ['entities', 'Item'],
    }),
    createItem: builder.mutation<
      Item,
      {
        missionId: string;
        schemaId: string;
        data: FormData;
      }
    >({
      query: ({ missionId, schemaId, data }) => ({
        url: `/${missionId}/${schemaId}`,
        method: 'POST',
        credentials: 'include',
        body: data,
      }),
      invalidatesTags: ['entities'],
    }),
    getItemById: builder.query<Item, { missionId: string; itemId: string }>({
      query: ({ missionId, itemId }) => ({
        url: `/${missionId}/${itemId}`,
        credentials: 'include',
      }),
      providesTags: ['Item'],
    }),
    getItemsByList: builder.query<
      Item[],
      { missionId: string; itemsIds: string[] }
    >({
      query: ({ missionId, itemsIds }) => ({
        url: urlGenerator(
          `/${missionId}/items/list`,
          `${window.location.protocol}//${window.location.host}`,
          itemsIds.map((id) => ['item', id]),
        ),
        credentials: 'include',
      }),
    }),
    updateItem: builder.mutation<
      Item,
      {
        missionId: string;
        itemId: string;
        data: FormData;
      }
    >({
      query: ({ missionId, itemId, data }) => ({
        url: `/${missionId}/${itemId}`,
        method: 'PATCH',
        credentials: 'include',
        body: data,
      }),
      invalidatesTags: ['Item'],
    }),
    deleteItemMedia: builder.mutation<
      void,
      { itemId: string; missionId: string; mediaId: string }
    >({
      query: ({ itemId, missionId, mediaId }) => ({
        url: `/remove-media/${missionId}/${itemId}/${mediaId}`,
        method: 'DELETE',
        credentials: 'include',
      }),
      invalidatesTags: ['Item'],
    }),
  }),
});

export const {
  useGetItemsQuery,
  useDeleteItemMutation,
  useCreateItemMutation,
  useGetItemByIdQuery,
  useUpdateItemMutation,
  useDeleteItemMediaMutation,
  useGetItemsByListQuery,
} = itemApi;
