import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useApiError } from '../../hooks/useApiError';
import { useConfirmEmailMutation } from '../../store/api/auth.api';

const ConfirmEmailPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [confirm, { error, isSuccess }] = useConfirmEmailMutation();
  useEffect(() => {
    if (searchParams.has('token') && searchParams.has('email')) {
      confirm({
        token: searchParams.get('token') as string,
        email: searchParams.get('email') as string,
      });
    }
  }, []);

  useApiError(error);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isSuccess) {
      toast.success('Email confirmed successfully, now you can login.');
      timeout = setTimeout(() => {
        navigate('/');
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isSuccess]);

  return (
    <div className="flex space-between login-main-container">
      <Helmet title="Confirming email" />
      <div className="login-container ">
        <div>
          <h2 className={'h2-login-title'}>hello</h2>
          <h2 className={'h2-login-info'}>
            confirming your email <br />
            in progress... <br />
          </h2>
          <h2 className={'h2-login-info'}>
            you will be redirected <br />
            to login page <br />
            when finished
          </h2>
        </div>

        <div className="btn-form-footer btn-footer-login">
          <Link to="/">
            <Button
              className="login-button add-btn"
              color="primary"
              type="submit"
            >
              <span className="add-btn-text">back to login</span>
            </Button>
          </Link>
        </div>
      </div>
      <div>
        <img
          style={{ maxWidth: '500px' }}
          src={'/pictures/logo_university_centrum.jpg'}
        />
      </div>
    </div>
  );
};

export default ConfirmEmailPage;
