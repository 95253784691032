import HelpIcon from '@mui/icons-material/Help';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
type Props = {
  title: string;
  description: string;
};

const LabelNodeComponent: React.FC<Props> = ({ title, description }) => {
  return (
    <>
      {title}{' '}
      {description && (
        <Tooltip disableFocusListener disableTouchListener title={description}>
          <IconButton>
            <HelpIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default LabelNodeComponent;
