import { InventoryNumberField } from '@daisy/daisy-common';
import React from 'react';
import { Link } from 'react-router-dom';

import { useGetItemsByListQuery } from '../../store/api/item.api';
import { useGetMissionByIdQuery } from '../../store/api/mission.api';

interface Props {
  value: string | string[];
  missionId: string;
  schemaId: string[];
  labelPath: Record<string, string>;
}

const RelationDetailsComponent: React.FC<Props> = ({
  missionId,
  schemaId,
  value,
  labelPath,
}) => {
  const { data } = useGetItemsByListQuery({
    missionId,
    itemsIds: Array.isArray(value) ? value : [value],
  });
  const missionQuery = useGetMissionByIdQuery(missionId);
  return (
    <>
      {data &&
        schemaId.map((schema) =>
          Array.isArray(value) ? (
            value.map((val) => {
              const item = data.find((item) => item.id === val);
              if (!item || item.schemaID !== schema) {
                return '';
              }
              return (
                <Link
                  style={{ paddingRight: '10px' }}
                  key={val}
                  to={`/expeditions/${missionId}/${schema}/${val}`}
                >
                  {
                    (
                      (missionQuery.data?.missionConfig.config.entities
                        .find((ent) => ent.id === schema)
                        ?.fields.find(
                          (f) => f.name === labelPath[schema],
                        ) as InventoryNumberField) ?? {}
                    ).prefix
                  }
                  {item.content[labelPath[schema]] as string}
                </Link>
              );
            })
          ) : (
            <Link
              key={schema}
              to={`/expeditions/${missionId}/${schema}/${value}`}
            >
              <>
                {
                  (
                    (missionQuery.data?.missionConfig.config.entities
                      .find((ent) => ent.id === schema)
                      ?.fields.find(
                        (f) => f.name === labelPath[schema],
                      ) as InventoryNumberField) ?? {}
                  ).prefix
                }
                {data[0] ? (data[0].content[labelPath[schema]] as string) : ''}
              </>
            </Link>
          ),
        )}
    </>
  );
};

export default RelationDetailsComponent;
