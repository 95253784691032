import { Typography } from '@mui/material';
import React from 'react';

import HelpPopupComponent from '../common/help-popup/help-popup.component';

const EntityHelpComponent: React.FC = () => {
  return (
    <HelpPopupComponent>
      <Typography variant="h4">Expedition entities</Typography>
      <Typography variant="body1">
        This section of configurator is responsible for adding entities to
        expedition. Each entity contains separate form to collect data and
        possibility to list, search and browse it. To add new entity click
        &apos;add entity&apos; button. Each one of them has name, short
        description familiarizing user with it and label choosing field from
        defined later to be used as label of items in list view. This two fields
        are required. You can delete entity using button with garbage can icon.
        Each entity use fields to collect data, and has to have at least one.
        Click &apos;add field&apos; button inside entity box to add new field.
        It can be deleted by clicking on corresponding button with garbage can
        icon.
      </Typography>
      <Typography variant="h6">Fields</Typography>
      <Typography variant="body1">
        In order to build entity form you can use 10 different types of fields.
        Each type has some common options but also specific ones. Let&apos;s
        start with common (all options are not mandatory if not mentioned
        otherwise):
        <ul>
          <li>
            field type - specifies type of added field, more on that below,
            it&apos;s required
          </li>
          <li>
            field name - specifies path used to save data into database,
            it&apos;s required
          </li>
          <li>
            field label - contains short field label visible in form, it&apos;s
            required
          </li>
          <li>
            field description - contains short hint about field content,
            it&apos;s required
          </li>
          <li>
            &apos;Is this field required?&apos; - describes if it is mandatory
            to fill in this field during creating or editing items in this
            entity
          </li>
          <li>
            &apos;Is this field indexed?&apos; - describes if content of this
            field is used for creating indexes for facets searches
          </li>
        </ul>
        Next section of inputs contains options specific for each kind of
        available fields:
        <ul>
          <li>
            text field:
            <ul>
              <li>
                placeholder - contains placeholder shown in form where
                there&apos;s no value in it
              </li>
              <li>min length - minimum length of content in characters</li>
              <li>max length - maximum length of content in characters</li>
              <li>
                default - default value inserted into field if user does not
                make any changes
              </li>
            </ul>
          </li>
          <li>
            number field:
            <ul>
              <li>min - minimum value</li>
              <li>max - maximum value</li>
              <li>step - value to use for rounding</li>
              <li>
                default - default value inserted into field if user does not
                make any changes
              </li>
            </ul>
          </li>
          <li>
            text area field (input for larger chunks of text):
            <ul>
              <li>
                placeholder - contains placeholder shown in form where
                there&apos;s no value in it
              </li>
              <li>min length - minimum length of content in characters</li>
              <li>max length - maximum length of content in characters</li>
              <li>
                rows - number of rows in field describing how big it should be
              </li>
              <li>
                default - default value inserted into field if user does not
                make any changes
              </li>
            </ul>
          </li>
          <li>
            checkbox (input for true/false):
            <ul>
              <li>
                &apos;Is this field checked by default?&apos; - describes if the
                field is true by default
              </li>
            </ul>
          </li>
          <li>
            radio buttons field (list to choose from):
            <ul>
              <li>
                options - contains list of all available options for this field,
                each one contains label and value, they can be added or removed
                but it&apos;s required to have at least one
              </li>
              <li>default - value selected by default</li>
            </ul>
          </li>
          <li>
            select field (list to choose from, pretty similar to previous one,
            more condense presentation):
            <ul>
              <li>
                options - contains list of all available options for this field,
                each one contains label and value, they can be added or removed
                but it&apos;s required to have at least one
              </li>
              <li>default - value selected by default</li>
            </ul>
          </li>
          <li>
            multi select field (variant of previous field with possibility to
            select multiple options):
            <ul>
              <li>
                &apos;Is this field extendable?&apos; - describes if user can
                add custom values
              </li>
              <li>
                options - contains list of all available options for this field,
                each one contains label and value, they can be added or removed
                but it&apos;s required to have at least one
              </li>
              <li>default - value selected by default</li>
            </ul>
          </li>
          <li>
            relation field (list to choose from, allowing to show connection to
            other item in the same entity or in others in the same expedition):
            <ul>
              <li>target - entity to point to, it&apos;s required</li>
              <li>
                &apos;Can this field have multiple values?&apos; - describes if
                this field can point to multiple items
              </li>
            </ul>
          </li>
          <li>
            image field (allows to add images):
            <ul>
              <li>
                image type - describes what kind of image wil be supplied in
                this field, raw or standard, it&apos;s required and has to be
                set in order to allow right selection in next field
              </li>
              <li>
                image allowed formats - describes what image formats can be used
                to supply image into this field, it&apos;s required and content
                depends on previous field
              </li>
              <li>
                &apos;Can this field have multiple values?&apos; - describes if
                user can add more than one file here
              </li>
            </ul>
          </li>
          <li>
            file field (similar than previous but allows to add files not only
            images):
            <ul>
              <li>
                file allowed formats - describes what file formats can be used
                to supply file into this field, multiple values can by typed and
                selected here it&apos;s required
              </li>
              <li>
                &apos;Can this field have multiple values?&apos; - describes if
                user can add more than one file here
              </li>
            </ul>
          </li>
        </ul>
      </Typography>
    </HelpPopupComponent>
  );
};

export default EntityHelpComponent;
