import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { useApiError } from '../../hooks/useApiError';
import { useGetUsersQuery } from '../../store/api/user.api';
import {
  selectConfig,
  selectMissionDetails,
  selectUsers,
  setStep,
} from '../../store/features/mission-configurator';
import EntitiesReviewComponent from './entities-review.component';
import UsersReviewComponent from './users-review.component';

type Props = {
  activeStep: number;
};

const MissionConfiguratorReviewComponent: React.FC<Props> = ({
  activeStep,
}) => {
  const dispatch = useDispatch();
  const missionDetails = useSelector(selectMissionDetails);
  const entities = useSelector(selectConfig);
  const users = useSelector(selectUsers);
  const { error, isLoading, data } = useGetUsersQuery();
  const [showAlert, setShowAlert] = useState(false);
  useApiError(error);
  return (
    <div className="width100" style={{ marginBottom: '80px' }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Expedition review
      </Typography>
      <Divider sx={{ mb: 3 }} />
      <Box sx={{ position: 'relative' }}>
        <Button
          type="button"
          onClick={() => {
            dispatch(setStep(0));
          }}
          className="edit-icon"
        >
          <EditIcon />
        </Button>
        <Typography variant="h5" sx={{ mb: 3 }}>
          Expedition details
        </Typography>
        <Typography variant="subtitle1">Name:</Typography>
        <Typography variant="body1">{missionDetails.name}</Typography>
        {missionDetails.description ? (
          <>
            <Typography variant="subtitle1">Description:</Typography>
            <Typography variant="body1">
              {missionDetails.description}
            </Typography>
          </>
        ) : (
          ''
        )}
        <Divider sx={{ mb: 3 }} />
      </Box>
      <EntitiesReviewComponent entities={entities} />
      <UsersReviewComponent data={data} isLoading={isLoading} users={users} />
      <FormControl variant="standard" sx={{ mb: 3, minWidth: 320 }} fullWidth>
        <div
          className="width100 flex"
          style={{
            justifyContent: 'flex-end',
            padding: '20px 10px',
            borderTop: 'solid 1px rgba(0, 0, 0, 0.12)',
          }}
        >
          <Button
            className={'back-btn'}
            type="button"
            onClick={() => dispatch(setStep(activeStep - 1))}
          >
            <span className={'add-btn-text'}>back</span>
          </Button>
          <Button
            className={'new-mission-btn'}
            onClick={() => setShowAlert(true)}
          >
            <span className={'add-btn-text'}>next</span>
          </Button>
          <Dialog open={showAlert} onClose={() => setShowAlert(false)}>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure that all settings are correct and new expedition
                configuration should be submitted?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                className="login-button add-btn"
                type="button"
                onClick={() => setShowAlert(false)}
              >
                cancel
              </Button>
              <Button
                className="login-button add-btn"
                type="button"
                onClick={() => dispatch(setStep(activeStep + 1))}
                autoFocus
              >
                save
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </FormControl>
    </div>
  );
};

export default MissionConfiguratorReviewComponent;
