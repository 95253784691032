import 'react-medium-image-zoom/dist/styles.css';

import { Media, MediaType } from '@daisy/daisy-common';
import { Box, Button, Card, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Zoom from 'react-medium-image-zoom';

import styles from './style.module.scss';

interface Props {
  medias: Media[];
  missionId: string;
  onDelete?: (mediaId: string) => void;
}

const ImagesWrapperComponent: React.FC<Props> = ({
  medias,
  missionId,
  onDelete,
}) => {
  const [files, setFiles] = useState<{ images: Media[]; raws: Media[] }>({
    images: [],
    raws: [],
  });
  useEffect(() => {
    setFiles({
      images: medias.filter((media) => media.type === MediaType.IMAGE),
      raws: medias.filter((media) => media.type === MediaType.RAW_IMAGE),
    });
  }, [medias]);
  return (
    <>
      {files.images.length ? (
        <Box sx={{ mb: 3 }}>
          <Typography variant="h4" sx={{ mb: 3 }}>
            Images
          </Typography>
          <div className="width100 flex" style={{ flexWrap: 'wrap' }}>
            {files.images.map((image) => (
              <Card key={image.id} className={styles.wrapper}>
                {!!image.processedFileName && (
                  <Zoom zoomMargin={200}>
                    <img
                      className={styles.img}
                      src={`/api/media-storage/${missionId}/${image.id}?processed=true`}
                    />
                  </Zoom>
                )}
                <div
                  className="buttons-wrapper"
                  style={{
                    marginTop: '24px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <a
                    href={`/api/media-storage/${missionId}/download/${image.fileName}`}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button className="new-mission-btn">
                      <span className="add-btn-text">download</span>
                    </Button>
                  </a>
                  {onDelete && (
                    <Button
                      className="new-mission-btn"
                      onClick={() => onDelete(image.id)}
                    >
                      <span className="add-btn-text">delete</span>
                    </Button>
                  )}
                </div>
              </Card>
            ))}
          </div>
        </Box>
      ) : (
        ''
      )}
      {files.raws.length ? (
        <Box sx={{ mb: 3 }}>
          <Typography variant="h4">Raw images</Typography>
          <div className="width100">
            {files.images.map((image) => (
              <Card key={image.id} className={styles.wrapper}>
                {!!image.processedFileName && (
                  <Zoom zoomMargin={200}>
                    <img
                      className={styles.img}
                      src={`/api/media-storage/${missionId}/${image.id}?processed=true`}
                    />
                  </Zoom>
                )}
                <div
                  className="buttons-wrapper"
                  style={{
                    marginTop: '24px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    href={`/api/media-storage/${missionId}/download/${image.fileName}`}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                    className="new-mission-btn"
                  >
                    <span className="add-btn-text">download</span>
                  </Button>
                  {onDelete && (
                    <Button
                      className="new-mission-btn"
                      onClick={() => onDelete(image.id)}
                    >
                      <span className="add-btn-text">delete</span>
                    </Button>
                  )}
                </div>
              </Card>
            ))}
          </div>
        </Box>
      ) : (
        ''
      )}
    </>
  );
};

export default ImagesWrapperComponent;
