import { SubmissionState } from '@daisy/daisy-common';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import {
  useAddUsersToMissionMutation,
  useCreateMissionMutation,
} from '../../store/api/mission.api';
import { useCreateMissionConfigMutation } from '../../store/api/mission-config.api';
import {
  resetConfigurator,
  selectConfig,
  selectMissionDetails,
  selectMissionId,
  selectSubmitState,
  selectUsers,
  setMissionId,
  setSubmitState,
} from '../../store/features/mission-configurator';
import SpinnerComponent from '../common/spinner/spinner.component';

type Props = {
  activeStep: number;
  setBlockUnload: React.Dispatch<React.SetStateAction<boolean>>;
};

const MissionConfiguratorSubmissionComponent: React.FC<Props> = ({
  setBlockUnload,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitState = useSelector(selectSubmitState);
  const missionDetails = useSelector(selectMissionDetails);
  const entities = useSelector(selectConfig);
  const users = useSelector(selectUsers);
  const missionId = useSelector(selectMissionId);

  const [createMission] = useCreateMissionMutation();
  const [createEntities] = useCreateMissionConfigMutation();
  const [createUsers] = useAddUsersToMissionMutation();

  const [showRetryInfo, setShowRetryInfo] = useState(false);

  /* eslint-disable */
  const errorHandler = (error: any) => {
    /* eslint-enable */
    const message = Array.isArray(error.data.message)
      ? error.data.message.join(', ')
      : error.data.message;
    toast.error(message);
  };

  const submitMission = async () => {
    try {
      dispatch(
        setSubmitState({ ...submitState, details: SubmissionState.SUBMITTING }),
      );
      const mission = await createMission(missionDetails).unwrap();
      dispatch(setMissionId(mission.id));
      dispatch(
        setSubmitState({ ...submitState, details: SubmissionState.SUCCESS }),
      );
    } catch (error) {
      dispatch(
        setSubmitState({ ...submitState, details: SubmissionState.ERROR }),
      );
      errorHandler(error as FetchBaseQueryError | SerializedError);
    }
  };

  const submitEntities = async () => {
    try {
      dispatch(
        setSubmitState({
          ...submitState,
          entities: SubmissionState.SUBMITTING,
        }),
      );
      await createEntities({
        config: entities,
        missionId,
      }).unwrap();
      dispatch(
        setSubmitState({ ...submitState, entities: SubmissionState.SUCCESS }),
      );
    } catch (error) {
      dispatch(
        setSubmitState({ ...submitState, entities: SubmissionState.ERROR }),
      );
      errorHandler(error as FetchBaseQueryError | SerializedError);
    }
  };

  const submitUsers = async () => {
    try {
      dispatch(
        setSubmitState({
          ...submitState,
          users: SubmissionState.SUBMITTING,
        }),
      );
      await createUsers({
        users,
        missionId,
      }).unwrap();
      dispatch(
        setSubmitState({ ...submitState, users: SubmissionState.SUCCESS }),
      );
    } catch (error) {
      dispatch(
        setSubmitState({ ...submitState, users: SubmissionState.ERROR }),
      );
      errorHandler(error as FetchBaseQueryError | SerializedError);
    }
  };

  const restartSubmit = () => {
    dispatch(
      setSubmitState({
        details:
          submitState.details === SubmissionState.ERROR
            ? SubmissionState.WAITING
            : submitState.details,
        entities:
          submitState.entities === SubmissionState.ERROR
            ? SubmissionState.WAITING
            : submitState.entities,
        users:
          submitState.users === SubmissionState.ERROR
            ? SubmissionState.WAITING
            : submitState.users,
      }),
    );
    setShowRetryInfo(false);
  };

  useEffect(() => {
    if (
      missionDetails.name &&
      submitState.details === SubmissionState.WAITING
    ) {
      submitMission();
    }
    if (
      submitState.details === SubmissionState.SUCCESS &&
      submitState.entities === SubmissionState.WAITING
    ) {
      submitEntities();
    }
    if (
      submitState.details === SubmissionState.SUCCESS &&
      submitState.entities === SubmissionState.SUCCESS &&
      submitState.users === SubmissionState.WAITING
    ) {
      submitUsers();
    }
    if (
      submitState.details === SubmissionState.SUCCESS &&
      submitState.entities === SubmissionState.SUCCESS &&
      submitState.users === SubmissionState.SUCCESS
    ) {
      setBlockUnload(false);
      toast.success('Mission created, redirecting...');
      navigate(`/expeditions/${missionId}`);
    }

    if (
      submitState.details === SubmissionState.ERROR ||
      submitState.entities === SubmissionState.ERROR ||
      submitState.users === SubmissionState.ERROR
    ) {
      setShowRetryInfo(true);
    }
  }, [submitState]);
  useEffect(() => {
    if (submitState.details === SubmissionState.SUBMITTING) {
      dispatch(
        setSubmitState({ ...submitState, details: SubmissionState.ERROR }),
      );
    }
    if (submitState.entities === SubmissionState.SUBMITTING) {
      dispatch(
        setSubmitState({ ...submitState, entities: SubmissionState.ERROR }),
      );
    }
    if (submitState.users === SubmissionState.SUBMITTING) {
      dispatch(
        setSubmitState({ ...submitState, users: SubmissionState.ERROR }),
      );
    }
  }, []);
  return (
    <div className="width100 flex center">
      {submitState.details === SubmissionState.SUBMITTING && (
        <SpinnerComponent label="Saving expedition details..." />
      )}
      {submitState.entities === SubmissionState.SUBMITTING && (
        <SpinnerComponent label="Saving entities..." />
      )}
      {submitState.users === SubmissionState.SUBMITTING && (
        <SpinnerComponent label="Saving users..." />
      )}
      <Dialog open={showRetryInfo} onClose={() => setShowRetryInfo(false)}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Submitting process failed, do you want to retry or reset
            configurator?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="login-button add-btn"
            type="button"
            onClick={() => {
              dispatch(resetConfigurator());
            }}
          >
            reset
          </Button>
          <Button
            className="login-button add-btn"
            type="button"
            onClick={restartSubmit}
            autoFocus
          >
            restart
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MissionConfiguratorSubmissionComponent;
