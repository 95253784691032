import { Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { defaults as defaultControls, ScaleLine } from 'ol/control';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import { transform } from 'ol/proj';
import { BingMaps, Vector as VectorSource } from 'ol/source';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import View from 'ol/View';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  xField: string;
  yField: string;
}

type Coords = [number, number];

const MapComponent: React.FC<Props> = ({ xField, yField }) => {
  const formik = useFormikContext<Record<string, number>>();
  const mapRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (mapRef.current) {
      let lat: number, lon: number;
      if (formik.values[xField] && formik.values[yField]) {
        lat = Number(formik.values[xField]);
        lon = Number(formik.values[yField]);
      }
      const metersToDeg = (coords: Coords) =>
        transform(coords, 'EPSG:3857', 'EPSG:4326');
      const degreesToMeters = (coords: Coords) =>
        transform(coords, 'EPSG:4326', 'EPSG:3857');

      const source = new BingMaps({
        key: 'AiHNi7mayoLTslMt4YUgi_vNPcb8PPlitpd9-oAEWtlJel0ycNNNYQ-DBPhbKhJm',
        imagerySet: 'Aerial',
      });
      const map = new Map({
        controls: defaultControls().extend([
          new ScaleLine({
            units: 'metric',
            bar: false,
            text: true,
            minWidth: 140,
          }),
        ]),
        target: mapRef.current,
        layers: [new TileLayer({ source })],
        view: new View({
          center: [4185385.9208, 3921831.0473],
          zoom: 7,
        }),
      });
      const vectorSource = new VectorSource();
      const vectorLayer = new VectorLayer({
        source: vectorSource,
        style: new Style({
          image: new CircleStyle({
            radius: 7,
            fill: new Fill({ color: 'rgba(255, 0, 0, 1)' }),
            stroke: new Stroke({ color: 'rgb(0, 0, 0)', width: 1 }),
          }),
        }),
      });
      map.addLayer(vectorLayer);
      const addPointAndFocus = () => {
        if (lat && lon) {
          vectorSource
            .getFeatures()
            .forEach((feature) => vectorSource.removeFeature(feature));
          vectorSource.addFeature(
            new Feature({
              geometry: new Point(degreesToMeters([lat, lon])),
              name: 'Site',
            }),
          );
          formik.setFieldValue(xField, lat);
          formik.setFieldValue(yField, lon);
          map.setView(
            new View({
              center: degreesToMeters([lat, lon]),
              zoom:
                (map.getView().getZoom() || 0) > 8
                  ? map.getView().getZoom()
                  : 8,
            }),
          );
        }
      };
      map.on('singleclick', (evt) => {
        const [latValueBase, lonValueBase] = evt.coordinate;
        const [latValue, lonValue] = metersToDeg([latValueBase, lonValueBase]);
        lat = latValue;
        lon = lonValue;
        addPointAndFocus();
      });
      addPointAndFocus();
      return () => {
        map.dispose();
      };
    }
  }, [mapRef, formik.values[xField], formik.values[yField]]);
  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/ol@v7.1.0/ol.css"
          type="text/css"
        />
      </Helmet>
      <Typography variant="h5">Localization</Typography>
      <div ref={mapRef} style={{ height: '500px', width: '100%' }}></div>
    </>
  );
};

export default MapComponent;
