import { CheckboxField } from '@daisy/daisy-common';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import React from 'react';

import LabelNodeComponent from './label-node.component';

interface Props extends CheckboxField {
  error: boolean;
  helperText: string;
  value: boolean;
  onChange: (field: string, value: boolean) => void;
}

const CheckboxFieldComponent: React.FC<Props> = (props: Props) => {
  return (
    <FormControl variant="standard" sx={{ mb: 3, display: 'block' }}>
      <FormControlLabel
        control={
          <Checkbox
            id={props.name}
            name={props.name}
            checked={!!props.value}
            onChange={(_e, checked) => props.onChange(props.name, checked)}
          />
        }
        label={
          <LabelNodeComponent
            title={props.label}
            description={props.description}
          />
        }
      />
    </FormControl>
  );
};

export default CheckboxFieldComponent;
