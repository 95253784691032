import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { useApiError } from '../../hooks/useApiError';
import { useLogoutMutation } from '../../store/api/auth.api';
import { selectUser } from '../../store/features/user';

const LogoutPage: React.FC = () => {
  const [logout, { error }] = useLogoutMutation();
  const user = useSelector(selectUser);

  useEffect(() => {
    if (user) {
      logout(undefined);
    }
  }, []);

  useApiError(error);

  return (
    <div className="flex space-between login-main-container">
      <Helmet title="Logout" />
      <div className="login-container ">
        <div>
          <h2 className={'h2-login-title'}>bye</h2>
          <h2 className={'h2-login-info'}>logged out</h2>
        </div>
        <div className="btn-form-footer btn-footer-login">
          <Link to="/">
            <Button
              className="login-button add-btn"
              color="primary"
              type="submit"
            >
              <span className="add-btn-text">back to login</span>
            </Button>
          </Link>
        </div>
      </div>
      <div>
        <img
          style={{ maxWidth: '500px' }}
          src={'/pictures/logo_university_centrum.jpg'}
        />
      </div>
    </div>
  );
};

export default LogoutPage;
