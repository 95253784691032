import { Backdrop, Divider, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import SpinnerComponent from '../../components/common/spinner/spinner.component';
import EntitiesFormComponent from '../../components/entities-form/entities-form.component';
import MissionDetailsFormComponent from '../../components/mission-details-form/mission-details-form.component';
import MissionUsersFormComponent from '../../components/mission-users-form/mission-users-form.component';
import { useApiError } from '../../hooks/useApiError';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import {
  useAddUsersToMissionMutation,
  useGetMissionByIdQuery,
  useUpdateMissionDetailsMutation,
} from '../../store/api/mission.api';
import { useUpdateMissionConfigMutation } from '../../store/api/mission-config.api';
import {
  resetConfigurator,
  selectConfig,
  selectGisTables,
  setConfig,
  setMissionDetails,
} from '../../store/features/mission-configurator';

const MissionEditPage: React.FC = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const missionQueryResult = useGetMissionByIdQuery(params.id || '');
  const [updateMissionDetails, updateMissionDetailsResult] =
    useUpdateMissionDetailsMutation();
  const [updateMissionConfig, updateMissionConfigResult] =
    useUpdateMissionConfigMutation();
  const [updateUsers, updateUsersResult] = useAddUsersToMissionMutation();
  useApiError(updateMissionDetailsResult.error);
  useApiError(updateMissionConfigResult.error);
  useApiError(updateUsersResult.error);
  useBreadcrumb([
    { name: 'Expeditions', path: '/expeditions' },
    { name: missionQueryResult.data?.name || '', path: `/${params.id || ''}` },
    { name: 'Edit', path: '/edit' },
  ]);
  const config = useSelector(selectConfig);
  const gisTables = useSelector(selectGisTables);
  useEffect(() => {
    if (missionQueryResult.data) {
      dispatch(
        setMissionDetails({
          name: missionQueryResult.data.name,
          description: missionQueryResult.data.description,
        }),
      );
      dispatch(setConfig(missionQueryResult.data.missionConfig.config));
    }
  }, [missionQueryResult.data]);
  useEffect(() => {
    return () => {
      dispatch(resetConfigurator());
    };
  }, []);
  return (
    <div>
      {(updateMissionDetailsResult.isLoading ||
        updateMissionConfigResult.isLoading ||
        updateUsersResult.isLoading) && (
        <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <SpinnerComponent />
        </Backdrop>
      )}
      <Helmet title="Edit expeditions" />
      <div className="div-btn-header width100" style={{ marginBottom: '2%' }}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          Edit mission
        </Typography>
        <Divider sx={{ mb: 3 }} />
        {!missionQueryResult.isLoading && (
          <>
            <MissionDetailsFormComponent
              initialData={{
                name: missionQueryResult.currentData?.name ?? '',
                description: missionQueryResult.currentData?.description ?? '',
              }}
              handleSubmit={(values) =>
                updateMissionDetails({
                  missionId: params.id ?? '',
                  mission: values,
                })
              }
              isUpdate={true}
            />
            <Divider sx={{ mb: 3 }} />
            <EntitiesFormComponent
              activeStep={0}
              handleSubmit={() => {
                updateMissionConfig({
                  config,
                  gisTables,
                  missionId: params.id ?? '',
                  configId:
                    missionQueryResult.currentData?.missionConfig.id ?? '',
                })
                  .unwrap()
                  .then((data) => dispatch(setConfig(data.config)));
              }}
              isUpdate={true}
            />
            <Divider sx={{ mb: 3 }} />
            <MissionUsersFormComponent
              activeStep={0}
              initialData={{
                users: (missionQueryResult.currentData?.missionUsers ?? []).map(
                  (usr) => ({
                    userId: usr.user.id,
                    userRole: usr.role,
                  }),
                ),
              }}
              handleSubmit={(values) =>
                updateUsers({ ...values, missionId: params.id ?? '' })
              }
              isUpdate={true}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default MissionEditPage;
