import { NodeType } from '@daisy/daisy-common';
import { Dropdown, Menu } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  selectNodeConfig,
  selectOpenDataConfig,
} from '../../store/features/config';
import { selectUser } from '../../store/features/user';
import BreadcrumbsComponent from '../breadcrumbs/breadcrumbs.component';
import Logo from '../common/logo/logo.component';

const paths: { [key: string]: string } = {
  INVITE_USER: '/invite-user',
  MANAGE_USERS: '/manage-users',
  EXPEDITIONS: '/expeditions',
  ACCOUNT: '/account',
  SETTINGS: '/settings',
  LOGOUT: '/logout',
};

const HeaderComponent: React.FC = () => {
  const user = useSelector(selectUser);
  const openDataConfig = useSelector(selectOpenDataConfig);
  const { nodeType, emailEnabled } = useSelector(selectNodeConfig);
  const navigate = useNavigate();

  return (
    <div className="header width100 flex space-between">
      <div>
        <Logo />
      </div>

      <div className="header center header-center">
        {user ? (
          <div className="navigator flex flex-row space-between width100">
            <div className="breadcrumbs-header">
              <BreadcrumbsComponent />
            </div>
            <div className="user-section flex">
              <div className="user-name-login">{user.name}</div>
            </div>
          </div>
        ) : (
          openDataConfig && (
            <Dropdown
              overlay={
                <Menu onClick={({ key }) => navigate(key)}>
                  {openDataConfig.config.entities.map((entity) => (
                    <Menu.Item
                      key={`open/60a9dfdc-1c24-4688-b6ef-7478f79c0abc/${entity.id}`}
                    >
                      {entity.name}
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <a
                className="ant-dropdown-link"
                href="#"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <h2 className="h2-login-info">Old Dongola Open Data </h2>
                <ArrowDropDownIcon />
              </a>
            </Dropdown>
          )
        )}
      </div>

      <div className="settpanel flex">
        {user ? (
          <Dropdown
            overlay={
              <Menu onClick={({ key }) => navigate(paths[key])}>
                {user.admin && (
                  <>
                    {nodeType === NodeType.MASTER && emailEnabled && (
                      <Menu.Item key="INVITE_USER">invite user</Menu.Item>
                    )}
                    <Menu.Item key="MANAGE_USERS">manage users</Menu.Item>
                  </>
                )}
                <Menu.Item key="EXPEDITIONS">expeditions</Menu.Item>
                <Menu.Item key="ACCOUNT">account</Menu.Item>
                <Menu.Item key="LOGOUT">log out</Menu.Item>
              </Menu>
            }
          >
            <a className="ant-dropdown-link" href="#">
              <img className="header-image" src="/pictures/czlowiek_01.png" />
            </a>
          </Dropdown>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default HeaderComponent;
