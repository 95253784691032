import { NodeType } from '@daisy/daisy-common';
import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import App from './App';
import AccountPage from './pages/account/account.page';
import AddFilesPage from './pages/add-files/add-files.page';
import ConfirmEmailPage from './pages/confirm-email/confirm-email.page';
import EmailConfirmNeededPage from './pages/email-confirm-needed/email-confirm-needed.page';
import EntityCreatePage from './pages/entity-create/entity-create.page';
import EntityDetailsPage from './pages/entity-details/entity-details.page';
import EntityEditPage from './pages/entity-edit/entity-edit.page';
import EntityHomePage from './pages/entity-home/entity-home.page';
import ForgotPasswordPage from './pages/forgot-password/forgot-password.page';
import InviteUserPage from './pages/invite-user/invite-user.page';
import LoginPage from './pages/login/login.page';
import LogoutPage from './pages/logout/logout.page';
import ManageUsersDetailsPage from './pages/manage-users-details/manage-users-details.page';
import ManageUsersListPage from './pages/manage-users-list/manage-users-list.page';
import MissionConfiguratorPage from './pages/mission-configurator/mission-configurator.page';
import MissionDetailsPage from './pages/mission-details/mission-details.page';
import MissionEditPage from './pages/mission-edit/mission-edit.page';
import MissionFilesPage from './pages/mission-files/mission-files.page';
import MissionsPage from './pages/missions/missions.page';
import RegisterPage from './pages/register/register.page';
import ResendConfirmationEmailPage from './pages/resend-confirmation-email/resend-confirmation-email.page';
import ResetPasswordPage from './pages/reset-password/reset-password.page';
import { selectNodeConfig } from './store/features/config';
import { selectUser } from './store/features/user';

const Router: React.FC = () => {
  const user = useSelector(selectUser);
  const { nodeType, emailEnabled } = useSelector(selectNodeConfig);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          {user ? (
            <>
              <Route index element={<MissionsPage />} />
              {nodeType === NodeType.MASTER && emailEnabled && (
                <Route path="/invite-user" element={<InviteUserPage />} />
              )}
              <Route path="account" element={<AccountPage />} />
              <Route path="/manage-users">
                <Route index element={<ManageUsersListPage />} />
                <Route path=":id" element={<ManageUsersDetailsPage />} />
              </Route>
              <Route path="/login" element={<Navigate to="/" />} />
              <Route path="/expeditions">
                <Route index element={<MissionsPage />} />
                <Route path="create" element={<MissionConfiguratorPage />} />
                <Route path=":id">
                  <Route index element={<MissionDetailsPage />} />
                  <Route path="edit" element={<MissionEditPage />} />
                  <Route path="storage/create" element={<AddFilesPage />} />
                  <Route path="storage" element={<MissionFilesPage />}>
                    <Route path=":type" element={<MissionFilesPage />} />
                  </Route>
                  <Route path=":entity">
                    <Route index element={<EntityHomePage />} />
                    <Route path="create" element={<EntityCreatePage />} />
                    <Route path=":item">
                      <Route index element={<EntityDetailsPage />} />
                      <Route path="edit" element={<EntityEditPage />} />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </>
          ) : (
            <>
              <Route index element={<LoginPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route
                path="/email-confirm-needed"
                element={<EmailConfirmNeededPage />}
              />
              <Route path="/confirm-email" element={<ConfirmEmailPage />} />
              <Route
                path="/resend-confirmation-email"
                element={<ResendConfirmationEmailPage />}
              />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
              <Route path="/open">
                <Route path=":id">
                  <Route path=":entity">
                    <Route index element={<EntityHomePage />} />
                    <Route path=":item" element={<EntityDetailsPage />} />
                  </Route>
                </Route>
              </Route>
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          )}
          <Route path="/logout" element={<LogoutPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
