import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import SpinnerComponent from '../../components/common/spinner/spinner.component';
import { useAdmin } from '../../hooks/useAdmin';
import { useApiError } from '../../hooks/useApiError';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import {
  useGetUserByIdQuery,
  useUpdateUserMutation,
} from '../../store/api/user.api';

interface FormValues {
  id: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
  name?: string;
  admin: boolean;
}

const ManageUsersDetailsPage: React.FC = () => {
  useAdmin();
  const params = useParams();
  const { error, isLoading, data } = useGetUserByIdQuery(params.id || '');
  const [updateUser, updateResult] = useUpdateUserMutation();
  useBreadcrumb([
    { name: 'Manage users', path: '/manage-users' },
    { name: data?.name || '', path: `/${data?.id}` },
  ]);
  useApiError(error);
  useApiError(updateResult.error);
  useEffect(() => {
    if (data) {
      formik.setFieldValue('id', data?.id);
      formik.setFieldValue('email', data?.email);
      formik.setFieldValue('name', data?.name);
      formik.setFieldValue('admin', data?.admin);
    }
  }, [data]);
  useEffect(() => {
    if (updateResult.isSuccess) {
      toast.success('User updated');
    }
  }, [updateResult.isSuccess]);
  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      id: '',
      email: '',
      name: '',
      admin: false,
    },
    validationSchema: yup.object({
      email: yup.string().email(),
      name: yup.string().label('full name'),
      password: yup.string(),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords do not match!')
        .label('confirm password'),
      admin: yup.boolean(),
    }),
    onSubmit: (values) => {
      if (!values.password) {
        delete values.password;
      }
      return updateUser(values);
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Helmet title="User details" />
      <div className="flex space-between login-main-container">
        {isLoading || updateResult.isLoading ? (
          <SpinnerComponent />
        ) : (
          <div className="login-container ">
            <div>
              <h2 className={'h2-login-title'}>update account</h2>
            </div>

            <div className="login-inputs">
              <TextField
                id="email"
                className="width100"
                label="email"
                margin="normal"
                variant="standard"
                type="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                disabled={isLoading}
              />
            </div>
            <div className="login-inputs">
              <TextField
                id="name"
                className="width100"
                label="full name"
                margin="normal"
                variant="standard"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                disabled={isLoading}
              />
            </div>
            <div className="login-inputs">
              <TextField
                id="password"
                className="width100"
                label="password"
                type="password"
                margin="normal"
                variant="standard"
                onChange={formik.handleChange}
                value={formik.values.password}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                disabled={isLoading}
              />
            </div>
            <div className="login-inputs">
              <TextField
                id="confirmPassword"
                className="width100"
                label="confirm password"
                type="password"
                margin="normal"
                variant="standard"
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                disabled={isLoading}
              />
              <FormHelperText id="component-helper-text">
                use minimum 8 signs without spaces or special characters
              </FormHelperText>
            </div>

            <div className="login-inputs">
              <FormControlLabel
                label="Admin"
                control={
                  <Checkbox
                    id="admin"
                    name="admin"
                    checked={formik.values.admin}
                    onChange={(_e, checked) =>
                      formik.setFieldValue('admin', checked)
                    }
                  />
                }
              />
            </div>

            <div className="btn-form-footer btn-footer-login">
              <Button
                className="login-button add-btn"
                color="primary"
                type="submit"
                disabled={isLoading}
              >
                <span className="add-btn-text">enter</span>
              </Button>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default ManageUsersDetailsPage;
