import { ListResponse, Media, MediaType } from '@daisy/daisy-common';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const filesApi = createApi({
  reducerPath: 'filesApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/media-storage' }),
  refetchOnMountOrArgChange: true,
  tagTypes: ['Files'],
  endpoints: (builder) => ({
    getFilesList: builder.query<
      ListResponse<Media>,
      { missionId: string; page?: number; type: MediaType }
    >({
      query: ({ missionId, page, type }) => ({
        url: `/${missionId}/by-mission/${type}${page ? `?page=${page}` : ''}`,
      }),
      providesTags: ['Files'],
    }),
    uploadFiles: builder.mutation<
      void,
      {
        missionId: string;
        data: FormData;
      }
    >({
      query: ({ missionId, data }) => ({
        url: `/${missionId}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Files'],
    }),
  }),
});

export const { useGetFilesListQuery, useUploadFilesMutation } = filesApi;
