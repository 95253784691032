import { ImageField, ImagesTypes, MediaType } from '@daisy/daisy-common';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';

const ImageFieldFormComponent: React.FC = () => {
  const formik = useFormikContext<ImageField>();
  return (
    <>
      <FormControl
        variant="standard"
        sx={{ mb: 3, display: 'block', minWidth: '500px' }}
      >
        <InputLabel id="mediaType">Image type</InputLabel>
        <Select
          labelId="mediaType"
          value={formik.values.mediaType}
          onChange={formik.handleChange}
          name="mediaType"
          label="Image type"
          sx={{ pr: 3, minWidth: 1 / 1 }}
        >
          <MenuItem value="" hidden></MenuItem>
          <MenuItem value={MediaType.IMAGE}>
            {MediaType.IMAGE.toLocaleLowerCase()}
          </MenuItem>
          <MenuItem value={MediaType.RAW_IMAGE}>
            {MediaType.RAW_IMAGE.replace('_', ' ').toLocaleLowerCase()}
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl
        variant="standard"
        sx={{ mb: 3, display: 'block', maxWidth: 1 / 1 }}
      >
        <InputLabel id="allowedFormats">Image allowed formats</InputLabel>
        <Select
          labelId="allowedFormats"
          value={formik.values.allowedFormats || []}
          onChange={formik.handleChange}
          name="allowedFormats"
          label="Image allowed formats"
          multiple
          sx={{ pr: 3, width: 1 / 1 }}
        >
          {(formik.values.mediaType === MediaType.IMAGE
            ? Object.values(ImagesTypes).slice(0, 5)
            : Object.values(ImagesTypes).slice(5)
          ).map((current) => (
            <MenuItem value={current} key={`allowedFormats-${current}`}>
              {current}
            </MenuItem>
          ))}
        </Select>
        {formik.touched.allowedFormats && !!formik.errors.allowedFormats ? (
          <FormHelperText>
            {JSON.stringify(formik.errors.allowedFormats)}
          </FormHelperText>
        ) : (
          <FormHelperText>Remember to select type first</FormHelperText>
        )}
      </FormControl>
      <FormControl
        variant="standard"
        sx={{ mb: 3, display: 'block', minWidth: '500px' }}
      >
        <FormControlLabel
          control={
            <Checkbox
              id="multi"
              name="multi"
              checked={formik.values.multi}
              onChange={(_e, checked) => {
                formik.setFieldValue('multi', checked);
              }}
            />
          }
          label="Can this field have multiple values?"
        />
      </FormControl>
    </>
  );
};

export default ImageFieldFormComponent;
