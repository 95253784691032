import 'react-toastify/dist/ReactToastify.css';
import './styles/main.scss';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router';
import { ToastContainer } from 'react-toastify';

import SpinnerComponent from './components/common/spinner/spinner.component';
import Header from './components/header/header.component';
import { useApiError } from './hooks/useApiError';
import { useGetNodeConfigQuery } from './store/api/auth.api';
import { useGetMissionConfigQuery } from './store/api/mission-config.api';
import { useGetUserQuery } from './store/api/user.api';
import { setNodeConfig, setOpenDataConfig } from './store/features/config';
import { setUser } from './store/features/user';

const theme = createTheme({
  palette: {
    primary: { main: '#FF8C00' },
    secondary: { main: '#bc8e38' },
  },
});

function App(): React.ReactElement {
  const { error, isLoading, data } = useGetUserQuery();
  const nodeTypeQuery = useGetNodeConfigQuery();
  const openDataConfig = useGetMissionConfigQuery({
    missionId: '60a9dfdc-1c24-4688-b6ef-7478f79c0abc',
    configId: 'ae469cd0-10ee-4e20-b239-f4144ebdff4d',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (openDataConfig.data) {
      dispatch(setOpenDataConfig(openDataConfig.data));
    }
  }, [openDataConfig.data]);

  useEffect(() => {
    if (nodeTypeQuery.data) {
      dispatch(setNodeConfig(nodeTypeQuery.data));
    }
  }, [nodeTypeQuery.data]);

  useEffect(() => {
    console.log(
      'data------------------------------------------------------------------------------------------------------------------------------',
      data,
    );
    if (data) {
      dispatch(setUser(data));
    }
  }, [data]);

  useEffect(() => {
    console.log(
      'error------------------------------------------------------------------------------------------------------------------------------',
      error,
    );
    if (error) {
      dispatch(setUser(null));
    }
  }, [error]);

  useApiError(error);

  return (
    <ThemeProvider theme={theme}>
      <Helmet titleTemplate="%s | DAISY" />
      {isLoading ? (
        <div className="full-page-wrapper">
          <SpinnerComponent label="" />
        </div>
      ) : (
        <>
          <div className="main-app-class">
            <div className="main-app-header-class">
              <Header />
            </div>
            <div className="main-app-content-class">
              <Outlet />
            </div>
          </div>
          <ToastContainer />
        </>
      )}
    </ThemeProvider>
  );
}

export default App;
