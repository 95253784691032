import { RadioField } from '@daisy/daisy-common';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { FormikHandlers } from 'formik';
import React from 'react';

import LabelNodeComponent from './label-node.component';

interface Props extends RadioField {
  error: boolean;
  helperText: string;
  value: string;
  onChange: FormikHandlers['handleChange'];
}

const RadioFieldComponent: React.FC<Props> = (props) => {
  return (
    <FormControl variant="standard" sx={{ mb: 3, minWidth: 1 / 1 }}>
      <FormLabel id={props.name}>
        <LabelNodeComponent
          title={props.label}
          description={props.description}
        />
      </FormLabel>
      <RadioGroup
        name={props.name}
        value={props.value}
        onChange={props.onChange}
      >
        {props.values.map((value) => (
          <FormControlLabel
            key={value}
            value={value}
            control={<Radio />}
            label={value}
          />
        ))}
      </RadioGroup>
      {props.error && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
};

export default RadioFieldComponent;
