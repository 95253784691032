"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubmissionState = void 0;
var SubmissionState;
(function (SubmissionState) {
    SubmissionState["WAITING"] = "WAITING";
    SubmissionState["SUBMITTING"] = "SUBMITTING";
    SubmissionState["SUCCESS"] = "SUCCESS";
    SubmissionState["ERROR"] = "ERROR";
})(SubmissionState = exports.SubmissionState || (exports.SubmissionState = {}));
