import {
  Field,
  RelationField,
  ReversRelationStatus,
} from '@daisy/daisy-common';
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import update from 'immutability-helper';
import React, { useCallback, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import {
  addField,
  deleteField,
  selectEntity,
  selectFields,
  setFields,
  updateEntity,
  updateField,
} from '../../store/features/mission-configurator';
import EntityFormModalComponent from './entity-form-modal.component';
import FieldFormComponent from './field-form.component';
import FieldsListItemComponent from './fields-list-item.component';

interface Props {
  index?: number;
}

const FieldsFormComponent: React.FC<Props> = ({ index }) => {
  const dispatch = useDispatch();
  const fields = useSelector(selectFields(index ?? 0));
  const entity = useSelector(selectEntity(index ?? 0));
  const [open, setOpen] = useState(false);
  const [xCoordField, setXCoordField] = useState(entity?.xField ?? '');
  const [yCoordField, setYCoordField] = useState(entity?.xField ?? '');
  const [currentField, setCurrentField] = useState<Field | undefined>(
    undefined,
  );
  const [currentFieldId, setCurrentFieldId] = useState<number | undefined>(
    undefined,
  );
  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newFields = update(fields, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, fields[dragIndex] as Field],
        ],
      });
      dispatch(
        setFields({
          fields: newFields,
          entityId: index ?? 0,
        }),
      );
    },
    [fields],
  );
  return (
    <Box className="width100" style={{ marginBottom: '80px' }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Fields in {entity?.name ?? ''}
      </Typography>
      <Divider />
      <Box>
        <div
          className="width100 flex"
          style={{
            justifyContent: 'flex-end',
            background: '#fff',
            padding: '10px',
          }}
        >
          <Button
            className={'new-mission-btn'}
            type="button"
            onClick={() => {
              setOpen(true);
              setCurrentField(undefined);
              setCurrentFieldId(undefined);
            }}
          >
            <span className={'add-btn-text'}>add field</span>
          </Button>
          <EntityFormModalComponent
            open={open}
            onClose={() => setOpen(false)}
            title={currentField ? 'Update field' : 'Add new field'}
          >
            <FieldFormComponent
              isUpdate={!!currentField}
              field={currentField}
              onCancel={() => {
                setOpen(false);
              }}
              onSubmit={(field) => {
                if (index || index === 0) {
                  if (
                    currentField &&
                    (currentFieldId || currentFieldId === 0)
                  ) {
                    dispatch(
                      updateField({
                        field,
                        entityId: index,
                        id: currentFieldId,
                      }),
                    );
                  } else {
                    dispatch(addField({ field, entityId: index }));
                  }
                }
                setOpen(false);
                setCurrentField(undefined);
                setCurrentFieldId(undefined);
              }}
            />
          </EntityFormModalComponent>
        </div>
      </Box>
      <Box>
        <DndProvider backend={HTML5Backend}>
          {fields.map((field, i) => (
            <FieldsListItemComponent
              key={i}
              field={field}
              moveCard={moveCard}
              index={i}
              disabledDelete={
                (field as RelationField).reverse ===
                ReversRelationStatus.REVERSE
              }
              onEdit={() => {
                setOpen(true);
                setCurrentField(field);
                setCurrentFieldId(i);
              }}
              onDelete={() =>
                dispatch(deleteField({ fieldId: i, entityId: index ?? 0 }))
              }
            />
          ))}
        </DndProvider>
      </Box>
      <Divider sx={{ mb: 3 }} />
      {entity?.hasPoint && (
        <>
          <Typography variant="h6" sx={{ mb: 3 }}>
            Select fields with coordinates:
          </Typography>
          <Box
            sx={{
              width: 1 / 1,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <FormControl variant="standard" sx={{ mb: 3, width: '45%' }}>
              <InputLabel id="xField">X Coordinate Field</InputLabel>
              <Select
                labelId="xField"
                value={xCoordField}
                onChange={(e) => {
                  setXCoordField(e.target.value);
                  if (index || index === 0) {
                    dispatch(
                      updateEntity({
                        data: { ...entity, xField: e.target.value },
                        id: index,
                      }),
                    );
                  }
                }}
                name="xField"
                label="X Coordinate Field"
                placeholder="Select field"
                sx={{ pr: 3, width: 1 }}
              >
                <MenuItem value="" hidden></MenuItem>
                {fields.map((field) => (
                  <MenuItem value={field.name} key={`label-${field.name}`}>
                    {field.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="standard" sx={{ mb: 3, width: '45%' }}>
              <InputLabel id="yField">Y Coordinate Field</InputLabel>
              <Select
                labelId="yField"
                value={yCoordField}
                onChange={(e) => {
                  setYCoordField(e.target.value);
                  if (index || index === 0) {
                    dispatch(
                      updateEntity({
                        data: { ...entity, yField: e.target.value },
                        id: index,
                      }),
                    );
                  }
                }}
                name="yField"
                label="X Coordinate Field"
                placeholder="Select field"
                sx={{ pr: 3, width: 1 }}
              >
                <MenuItem value="" hidden></MenuItem>
                {entity.fields.map((field) => (
                  <MenuItem value={field.name} key={`label-${field.name}`}>
                    {field.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </>
      )}
    </Box>
  );
};

export default FieldsFormComponent;
