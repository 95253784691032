import {
  Field,
  FieldType,
  InventoryNumberField,
  MultiValue,
} from '@daisy/daisy-common';
import moment from 'moment';
import React from 'react';

import RelationDetailsComponent from '../relation-details/relation-details.component';

interface Props {
  value: string | string[] | number | boolean | MultiValue[];
  fieldType: FieldType;
  missionId: string;
  schemaId: string[];
  labelPath: Record<string, string>;
  config: Field;
}

/* eslint-disable */
const DetailsValuesMap: React.FC<Props> = ({
  value,
  fieldType,
  missionId,
  schemaId,
  labelPath,
  config,
}) => {
  switch (fieldType) {
    case FieldType.CHECKBOX_FIELD:
      return <>{value ? 'yes' : 'no'}</>;
    case FieldType.MULTI_SELECT_FIELD:
      return (
        <>
          {(value as string[])
            .map((str) => str.replaceAll('_', ' ').toLowerCase())
            .join(', ')}
        </>
      );
    case FieldType.RELATION_FIELD:
      return (
        <>
          <RelationDetailsComponent
            value={value as string | string[]}
            missionId={missionId}
            schemaId={schemaId}
            labelPath={labelPath}
          />
        </>
      );
    case FieldType.MULTI_SELECT_FIELD_WITH_VALUES:
      return (
        <>
          {(value as unknown as MultiValue[]).map(({ item, value }) => (
            <span key={`${item}-${value}`} style={{ display: 'block' }}>
              {item}: {value}
            </span>
          ))}
        </>
      );
    case FieldType.DATE_FIELD:
      return <>{moment(value as string).format('DD-MM-YYYY')}</>;
    case FieldType.INVENTORY_NUMBER_FIELD:
      return (
        <>
          {(config as InventoryNumberField).prefix}
          {value}
        </>
      );
    default:
      return <>{value}</>;
  }
};

export default DetailsValuesMap;
/* eslint-enable */
