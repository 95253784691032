import { MissionUserDto, User } from '@daisy/daisy-common';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Typography } from '@mui/material';
import { List } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import { setStep } from '../../store/features/mission-configurator';
import SpinnerComponent from '../common/spinner/spinner.component';

type Props = {
  isLoading: boolean;
  users: MissionUserDto[];
  data?: User[];
};

const UsersReviewComponent: React.FC<Props> = ({ isLoading, users, data }) => {
  const dispatch = useDispatch();
  return (
    <Box sx={{ position: 'relative' }}>
      <Button
        type="button"
        onClick={() => {
          dispatch(setStep(2));
        }}
        className="edit-icon"
      >
        <EditIcon />
      </Button>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Expedition users
      </Typography>
      {isLoading ? (
        <SpinnerComponent />
      ) : (
        <List
          className="width100"
          itemLayout="horizontal"
          dataSource={users}
          renderItem={(user) => (
            <List.Item className="flex space-between">
              {data?.find((item) => item.id === user.userId)?.name} -{' '}
              {user.userRole.replace('_', ' ').toLowerCase()}
            </List.Item>
          )}
        />
      )}
    </Box>
  );
};

export default UsersReviewComponent;
