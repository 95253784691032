import { Config } from '@daisy/daisy-common';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Divider, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';

import { setStep } from '../../store/features/mission-configurator';
import FieldsReviewComponent from './fields-review.component';

type Props = {
  entities: Config;
};

const EntitiesReviewComponent: React.FC<Props> = ({ entities }) => {
  const dispatch = useDispatch();
  return (
    <Box sx={{ position: 'relative' }}>
      <Button
        type="button"
        onClick={() => {
          dispatch(setStep(1));
        }}
        className="edit-icon"
      >
        <EditIcon />
      </Button>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Expedition entities
      </Typography>
      {entities.entities.map((entity) => (
        <Box key={entity.id}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {entity.name}
          </Typography>
          {entity.description ? (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Description:</Typography>
              <Typography variant="body1">{entity.description}</Typography>
            </Box>
          ) : (
            ''
          )}
          <FieldsReviewComponent entity={entity} entities={entities.entities} />
        </Box>
      ))}
      <Divider sx={{ mb: 3 }} />
    </Box>
  );
};

export default EntitiesReviewComponent;
