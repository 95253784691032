import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

export const useApiError = (error?: FetchBaseQueryError | SerializedError) => {
  const location = useLocation();
  useEffect(() => {
    const pathCondition = [
      '/register',
      '/confirm-email',
      '/resend-confirmation-email',
      '/login',
      '/forgot-password',
      '/reset-password',
    ].includes(location.pathname);
    if (error) {
      if ((error as FetchBaseQueryError).status !== 403 && pathCondition) {
        const message = (error as { data: { message: string } }).data.message;
        toast.error(message);
      }
    }
  }, [error]);
};
