import { Mission, MissionDto, MissionUserDto } from '@daisy/daisy-common';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const missionApi = createApi({
  reducerPath: 'missionApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/mission' }),
  tagTypes: ['Mission'],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getMissionById: builder.query<Mission, string>({
      query: (id) => `/${id}`,
      providesTags: ['Mission'],
    }),
    addUpdateMissionUser: builder.mutation<
      void,
      {
        missionId: string;
        missionUser: MissionUserDto;
      }
    >({
      query: (body) => ({
        url: `/${body.missionId}/add-update-user`,
        method: 'PATCH',
        credentials: 'include',
        body: body.missionUser,
      }),
      invalidatesTags: ['Mission'],
    }),
    createMission: builder.mutation<Mission, MissionDto>({
      query: (body) => ({
        url: '',
        method: 'POST',
        credentials: 'include',
        body,
      }),
    }),
    addUsersToMission: builder.mutation<
      void,
      { users: MissionUserDto[]; missionId: string }
    >({
      query: (body) => ({
        url: `/${body.missionId}/add-users`,
        method: 'PATCH',
        credentials: 'include',
        body: { users: body.users },
      }),
      invalidatesTags: ['Mission'],
    }),
    deleteMission: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
        credentials: 'include',
      }),
    }),
    updateMissionDetails: builder.mutation<
      Mission,
      { missionId: string; mission: MissionDto }
    >({
      query: ({ missionId, mission }) => ({
        url: `/${missionId}`,
        method: 'PUT',
        credentials: 'include',
        body: mission,
      }),
      invalidatesTags: ['Mission'],
    }),
  }),
});

export const {
  useGetMissionByIdQuery,
  useAddUpdateMissionUserMutation,
  useCreateMissionMutation,
  useAddUsersToMissionMutation,
  useDeleteMissionMutation,
  useUpdateMissionDetailsMutation,
} = missionApi;
