import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { Middleware } from 'redux';
import logger from 'redux-logger';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { authApi } from './api/auth.api';
import { filesApi } from './api/files.api';
import { gisLayersApi } from './api/gis-layers.api';
import { invitationApi } from './api/invitation.api';
import { itemApi } from './api/item.api';
import { missionApi } from './api/mission.api';
import { missionConfigApi } from './api/mission-config.api';
import { searchApi } from './api/search.api';
import { userApi } from './api/user.api';
import config from './features/config';
import listState from './features/list-state';
import missionConfigurator from './features/mission-configurator';
import user from './features/user';

const middlewares: Array<Middleware> = [];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  user,
  missionConfigurator,
  config,
  listState,
  [authApi.reducerPath]: authApi.reducer,
  [invitationApi.reducerPath]: invitationApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [missionApi.reducerPath]: missionApi.reducer,
  [missionConfigApi.reducerPath]: missionConfigApi.reducer,
  [searchApi.reducerPath]: searchApi.reducer,
  [itemApi.reducerPath]: itemApi.reducer,
  [filesApi.reducerPath]: filesApi.reducer,
  [gisLayersApi.reducerPath]: gisLayersApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .prepend(middlewares)
      .concat(authApi.middleware)
      .concat(invitationApi.middleware)
      .concat(userApi.middleware)
      .concat(missionApi.middleware)
      .concat(missionConfigApi.middleware)
      .concat(searchApi.middleware)
      .concat(itemApi.middleware)
      .concat(filesApi.middleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
