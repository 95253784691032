import { FieldType, ImagesTypes, MediaType } from '@daisy/daisy-common';
import { Button, Divider, FormControl } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import * as yup from 'yup';

import FileFieldComponent from '../../components/common/inputs/file-field.component';
import SelectFieldComponent from '../../components/common/inputs/select-field.component';
import { useApiError } from '../../hooks/useApiError';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { useUploadFilesMutation } from '../../store/api/files.api';
import { useGetMissionByIdQuery } from '../../store/api/mission.api';

const AddFilesPage: React.FC = () => {
  const params = useParams();
  const missionQuery = useGetMissionByIdQuery(params.id || '');
  const [uploadMutation, uploadMutationResult] = useUploadFilesMutation();
  useApiError(missionQuery.error);
  useApiError(uploadMutationResult.error);
  useBreadcrumb([
    { name: 'EXPEDITIONSs', path: '/expeditions' },
    {
      name: missionQuery.data?.name || '',
      path: `/${params.id || ''}`,
    },
    {
      name: 'Add files',
      path: '/storage/create',
    },
  ]);
  return (
    <>
      <div
        className="flex space-between div-btn-header"
        style={{ marginBottom: '2%' }}
      >
        <Helmet title={'' || ''} />
        <h2 className="h2-login-info">Add files</h2>
      </div>
      <Divider sx={{ mb: 3 }} />
      <Formik
        initialValues={{ mediaType: MediaType.IMAGE, files: '' }}
        validationSchema={yup.object({
          mediaType: yup.string().required(),
          files: yup.mixed().required(),
        })}
        validateOnBlur={true}
        validateOnChange={true}
        validateOnMount={true}
        onSubmit={(values, helpers) => {
          const fd = new FormData();
          const data: [string, string | File[]][] = [];
          Object.entries(values).forEach(([key, value]) => {
            if (value) {
              data.push([key, value]);
            }
          });
          for (const [key, value] of data) {
            if (Array.isArray(value)) {
              (value as any[]).forEach((v) => fd.append(key, v)); // eslint-disable-line
            } else {
              fd.append(key, value as string | Blob);
            }
          }
          uploadMutation({ data: fd, missionId: params.id || '' })
            .unwrap()
            .then(() => {
              toast.success('Uploaded');
              helpers.resetForm();
            })
            .catch((error) => {
              const message = (error as { data: { message: string } }).data
                .message;
              toast.error(message);
            });
        }}
      >
        {(formik) => (
          <Form style={{ width: '50%' }}>
            <SelectFieldComponent
              id={v4()}
              value={formik.values.mediaType}
              onChange={formik.handleChange}
              helperText={formik.errors.mediaType ?? ''}
              error={!!formik.touched.mediaType && !!formik.errors.mediaType}
              required={true}
              label="Type"
              name="mediaType"
              description=""
              indexed={false}
              sortable={false}
              values={Object.values(MediaType)}
              type={FieldType.SELECT_FIELD}
              width="100%"
            />
            <FileFieldComponent
              id={v4()}
              value={formik.values.files}
              onChange={formik.setFieldValue as any} // eslint-disable-line
              helperText={formik.errors.files ?? ''}
              error={!!formik.touched.files && !!formik.errors.files}
              mediaType={formik.values.mediaType as MediaType.FILE}
              required={true}
              label="Files"
              name="files"
              description=""
              indexed={false}
              sortable={false}
              width="100%"
              multi={true}
              allowedFormats={
                formik.values.mediaType === MediaType.FILE
                  ? new Set([])
                  : new Set(Object.values(ImagesTypes))
              }
              type={FieldType.FILE_FIELD}
            />
            <FormControl
              variant="standard"
              sx={{ mb: 3, minWidth: 320 }}
              fullWidth
            >
              <div
                className="width100 flex"
                style={{
                  justifyContent: 'flex-end',
                  borderTop: 'solid 1px rgba(0, 0, 0, 0.12)',
                  padding: '20px 10px',
                }}
              >
                <Button className={'new-mission-btn'} type="submit">
                  <span className={'add-btn-text'}>submit</span>
                </Button>
              </div>
            </FormControl>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddFilesPage;
