import { FieldType, MediaType } from '@daisy/daisy-common';
import { Box, Button, Divider, Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

import MapPreviewComponent from '../../components/common/map/map-preview.component';
import SpinnerComponent from '../../components/common/spinner/spinner.component';
import DetailsValuesMap from '../../components/details-values-map/details-values-map.component';
import FilesWrapperComponent from '../../components/files-wrapper/files-wrapper.component';
import ImagesWrapperComponent from '../../components/images-wrapper/images-wrapper.component';
import { useApiError } from '../../hooks/useApiError';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { useGetGisLayersQuery } from '../../store/api/gis-layers.api';
import {
  useDeleteItemMutation,
  useGetItemByIdQuery,
} from '../../store/api/item.api';
import { useGetMissionByIdQuery } from '../../store/api/mission.api';
import {
  useGetNextQuery,
  useGetPreviousQuery,
} from '../../store/api/search.api';
import {
  selectCurrentPath,
  selectSearchParams,
  setCurrentPath,
} from '../../store/features/list-state';
import { selectUser } from '../../store/features/user';

const EntityDetailsPage: React.FC = () => {
  const listPath = useSelector(selectCurrentPath);
  const user = useSelector(selectUser);
  const params = useParams();
  const navigate = useNavigate();
  const missionQuery = useGetMissionByIdQuery(params.id ?? '');
  const itemQuery = useGetItemByIdQuery({
    missionId: params.id ?? '',
    itemId: params.item ?? '',
  });
  const storedSearchParams = useSelector(selectSearchParams);
  const prevNextQueryData = {
    missionId: params.id ?? '',
    index: params.entity || '',
    itemId: params.item ?? '',
    searchParams: storedSearchParams ? storedSearchParams : [],
  };
  const nextQuery = useGetNextQuery(prevNextQueryData);
  const prevQuery = useGetPreviousQuery(prevNextQueryData);
  const gisLayers = useGetGisLayersQuery({
    layers: missionQuery.data?.missionConfig.gisTables ?? [],
    missionId: params.id ?? '',
  });
  const [deleteEntityMutation] = useDeleteItemMutation();
  const getEntity = useCallback(
    () =>
      missionQuery.data?.missionConfig.config.entities.find(
        (ent) => ent.id === params.entity,
      ),
    [missionQuery.data, params.entity],
  );
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(setCurrentPath(''));
    };
  }, []);
  useApiError(missionQuery.error);
  useApiError(itemQuery.error);
  useApiError(nextQuery.error);
  useApiError(prevQuery.error);
  useBreadcrumb([
    { name: 'Expeditions', path: '/expeditions' },
    {
      name: missionQuery.data?.name || '',
      path: `/${params.id || ''}`,
    },
    {
      name: getEntity()?.name || '',
      path: `/${params.entity || ''}`,
    },
    {
      name: itemQuery.data?.id || '',
      path: `/${params.item || ''}`,
    },
  ]);
  const getLabelPath = useCallback(
    (targets: string[]) =>
      Object.fromEntries(
        targets.map((target) => [
          target,
          missionQuery.data?.missionConfig.config.entities.find(
            (ent) => ent.id === target,
          )?.label ?? '',
        ]),
      ),
    [missionQuery.data],
  );
  return (
    <div style={{ marginBottom: '80px' }}>
      {itemQuery.isLoading || itemQuery.isFetching ? (
        <SpinnerComponent />
      ) : (
        <>
          <Helmet title={`Details of ${getEntity()?.name || ''}`} />
          <div
            className="flex space-between div-btn-header"
            style={{ marginBottom: '2%' }}
          >
            <Typography variant="h4" className="h2-login-info">
              {getEntity()?.name || ''} record
            </Typography>
            <div className="buttons-wrapper">
              {!missionQuery.data?.readOnly && user && (
                <>
                  <Button
                    className={'new-mission-btn'}
                    onClick={() => {
                      navigate(
                        `/expeditions/${params.id ?? ''}/${
                          params.entity ?? ''
                        }/${params.item ?? ''}/edit`,
                      );
                    }}
                  >
                    <span className={'add-btn-text'}>edit</span>
                  </Button>
                  <Button
                    className={'new-mission-btn'}
                    onClick={() => {
                      deleteEntityMutation({
                        missionId: params.id ?? '',
                        itemId: params.item ?? '',
                      })
                        .unwrap()
                        .then(() => navigate(-1))
                        .catch((error) => {
                          const message = (
                            error as { data: { message: string } }
                          ).data.message;
                          toast.error(message);
                        });
                    }}
                  >
                    <span className={'add-btn-text'}>delete</span>
                  </Button>
                </>
              )}
              {user && (
                <Button
                  className={'new-mission-btn'}
                  href={`/api/pdf?page=${window.location.pathname}`}
                  target="_blank"
                  onClick={() =>
                    toast.info('Starting download, it may take few seconds...')
                  }
                >
                  <span className={'add-btn-text'}>download</span>
                </Button>
              )}
              {listPath && (
                <Button className={'new-mission-btn'} href={listPath}>
                  <span className={'add-btn-text'}>back to list</span>
                </Button>
              )}
            </div>
          </div>
          <Divider sx={{ mb: 3 }} />
          <div
            className="flex print-hidden"
            style={{
              position: 'fixed',
              width: '100vw',
              left: 0,
            }}
          >
            {!prevQuery.isLoading && prevQuery.data && prevQuery.data.prev && (
              <div
                className="PrevNextArrow"
                onClick={() => {
                  navigate(
                    `/expeditions/${params.id ?? ''}/${params.entity ?? ''}/${
                      prevQuery.data?.prev
                    }`,
                  );
                }}
              ></div>
            )}
            {!nextQuery.isLoading && nextQuery.data && nextQuery.data.next && (
              <div
                className="PrevNextArrow next-arrow"
                onClick={() => {
                  navigate(
                    `/expeditions/${params.id ?? ''}/${params.entity ?? ''}/${
                      nextQuery.data?.next
                    }`,
                  );
                }}
              ></div>
            )}
          </div>
          <Box
            className="width100 flex"
            sx={{ mb: 3, flexWrap: 'wrap', justifyContent: 'start' }}
          >
            {getEntity()?.fields.map((field) => {
              const fileType =
                field.type === FieldType.IMAGE_FIELD ||
                field.type === FieldType.FILE_FIELD;
              return fileType ? (
                ''
              ) : (
                <Box
                  key={field.name}
                  sx={{
                    width: field.width ?? '50%',
                    pl: '5%',
                  }}
                >
                  <Typography variant="h6">{field.label}:</Typography>
                  <Typography
                    className="word-break"
                    variant="body1"
                    sx={{ minHeight: 24 }}
                  >
                    {itemQuery.data?.content[field.name] ? (
                      <DetailsValuesMap
                        value={itemQuery.data?.content[field.name]}
                        fieldType={field.type}
                        missionId={params.id || ''}
                        config={field}
                        schemaId={
                          field.type === FieldType.RELATION_FIELD
                            ? field.target
                            : []
                        }
                        labelPath={
                          field.type === FieldType.RELATION_FIELD
                            ? getLabelPath(field.target)
                            : {}
                        }
                      />
                    ) : (
                      '-'
                    )}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          {itemQuery.data?.location && (
            <MapPreviewComponent
              x={itemQuery.data?.location.coordinates[0]}
              y={itemQuery.data?.location.coordinates[1]}
              layers={gisLayers.data ?? []}
            />
          )}
          <Divider sx={{ mb: 3 }} />
          <ImagesWrapperComponent
            medias={
              itemQuery.data?.medias.filter(
                (media) => media.type !== MediaType.FILE,
              ) ?? []
            }
            missionId={params.id ?? ''}
          />
          <FilesWrapperComponent
            files={
              itemQuery.data?.medias.filter(
                (media) => media.type === MediaType.FILE,
              ) ?? []
            }
            missionId={params.id ?? ''}
          />
        </>
      )}
    </div>
  );
};

export default EntityDetailsPage;
