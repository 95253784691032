import { NumberField } from '@daisy/daisy-common';
import { FormControl } from '@mui/material';
import { FastField, useFormikContext } from 'formik';
import React from 'react';

import TextInputComponent from '../common/configurator-inputs/text-input.component';

const NumberFieldFormComponent: React.FC = () => {
  const formik = useFormikContext<NumberField>();
  return (
    <>
      <FormControl
        variant="standard"
        sx={{ mb: 3, display: 'block', minWidth: '500px' }}
      >
        <FastField
          component={TextInputComponent}
          id="min"
          name="min"
          label="Field min value"
          variant="standard"
          inputProps={{
            type: 'number',
            inputMode: 'numeric',
          }}
          sx={{ minWidth: 1 / 1 }}
          helperText={formik.errors.min ? formik.errors.min : ''}
        />
      </FormControl>
      <FormControl
        variant="standard"
        sx={{ mb: 3, display: 'block', minWidth: '500px' }}
      >
        <FastField
          component={TextInputComponent}
          id="max"
          name="max"
          label="Field max value"
          variant="standard"
          inputProps={{
            type: 'number',
            inputMode: 'numeric',
          }}
          sx={{ minWidth: 1 / 1 }}
          helperText={formik.errors.max ? formik.errors.max : ''}
        />
      </FormControl>
      <FormControl
        variant="standard"
        sx={{ mb: 3, display: 'block', minWidth: '500px' }}
      >
        <FastField
          component={TextInputComponent}
          id="step"
          name="step"
          label="Field step value"
          variant="standard"
          inputProps={{
            type: 'number',
            inputMode: 'numeric',
          }}
          sx={{ minWidth: 1 / 1 }}
          helperText={formik.errors.step ? formik.errors.step : ''}
        />
      </FormControl>
      <FormControl
        variant="standard"
        sx={{ mb: 3, display: 'block', minWidth: '500px' }}
      >
        <FastField
          component={TextInputComponent}
          id="default"
          name="default"
          label="Field default value"
          variant="standard"
          inputProps={{
            type: 'number',
            inputMode: 'numeric',
          }}
          sx={{ minWidth: 1 / 1 }}
          helperText={formik.errors.default ? formik.errors.default : ''}
        />
      </FormControl>
    </>
  );
};

export default NumberFieldFormComponent;
