import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

export interface ListState {
  currentPath: string;
  searchParams: [string, string][] | null;
}

const initialState: ListState = {
  currentPath: '',
  searchParams: null,
};

const listStateSlice = createSlice({
  name: 'listState',
  initialState,
  reducers: {
    setCurrentPath: (state, action: PayloadAction<string>) => {
      state.currentPath = action.payload;
    },
    setSearchParams: (
      state,
      action: PayloadAction<[string, string][] | null>,
    ) => {
      state.searchParams = action.payload;
    },
    resetSearchParams: (state) => {
      state.searchParams = initialState.searchParams;
    },
  },
});

export const { setCurrentPath, setSearchParams } = listStateSlice.actions;

export const selectCurrentPath = (state: RootState) =>
  state.listState.currentPath;
export const selectSearchParams = (state: RootState) =>
  state.listState.searchParams;

export default listStateSlice.reducer;
