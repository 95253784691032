import { Button, FormHelperText, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { useApiError } from '../../hooks/useApiError';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { useUpdateUserMutation } from '../../store/api/user.api';
import { selectUser, setUser } from '../../store/features/user';

interface FormValues {
  id: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
  name?: string;
}

const AccountPage: React.FC = () => {
  useBreadcrumb([{ name: 'User account', path: '/account' }]);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [updateUser, { data, error, isLoading, isSuccess }] =
    useUpdateUserMutation();
  const formik = useFormik<FormValues>({
    initialValues: { id: user?.id || '', email: user?.email, name: user?.name },
    validationSchema: yup.object({
      email: yup.string().email(),
      name: yup.string().label('full name'),
      password: yup.string(),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords do not match!')
        .label('confirm password'),
    }),
    onSubmit: (values) => updateUser(values),
  });

  useApiError(error);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setUser(data || null));
    }
  }, [isSuccess]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Helmet title="Account" />
      <div className="flex space-between login-main-container">
        <div className="login-container ">
          <div>
            <h2 className={'h2-login-title'}>update account</h2>
          </div>

          <div className="login-inputs">
            <TextField
              id="email"
              className="width100"
              label="email"
              margin="normal"
              variant="standard"
              type="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              disabled={isLoading}
            />
          </div>
          <div className="login-inputs">
            <TextField
              id="name"
              className="width100"
              label="full name"
              margin="normal"
              variant="standard"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              disabled={isLoading}
            />
          </div>
          <div className="login-inputs">
            <TextField
              id="password"
              className="width100"
              label="password"
              type="password"
              margin="normal"
              variant="standard"
              onChange={formik.handleChange}
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              disabled={isLoading}
            />
          </div>
          <div className="login-inputs">
            <TextField
              id="confirmPassword"
              className="width100"
              label="confirm password"
              type="password"
              margin="normal"
              variant="standard"
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              disabled={isLoading}
            />
            <FormHelperText id="component-helper-text">
              use minimum 8 signs without spaces or special characters
            </FormHelperText>
          </div>

          <div className="btn-form-footer btn-footer-login">
            <Button
              className="login-button add-btn"
              color="primary"
              type="submit"
              disabled={isLoading}
            >
              <span className="add-btn-text">enter</span>
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AccountPage;
