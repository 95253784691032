import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import RegisterFormComponent from '../../components/register-form/register-form.component';
import { useRegisterMutation } from '../../store/api/auth.api';
import { selectNodeConfig } from '../../store/features/config';

interface FormValues {
  email: string;
  password: string;
  confirmPassword: string;
  name: string;
}

const RegisterPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [register, { error, isLoading }] = useRegisterMutation();
  const navigate = useNavigate();
  const nodeConfig = useSelector(selectNodeConfig);
  useEffect(() => {
    if (searchParams.has('email')) {
      formik.setFieldValue('email', searchParams.get('email'));
    }
  }, []);
  useEffect(() => {
    if (error) {
      const message = (error as { data: { message: string } }).data.message;
      toast.error(
        message === 'Unauthorized'
          ? 'You need an invitation to register, contact your supervisor to get one.'
          : message,
      );
    }
  }, [error]);
  const formik = useFormik<FormValues>({
    initialValues: { email: '', password: '', confirmPassword: '', name: '' },
    validationSchema: yup.object({
      email: yup.string().email().required(),
      name: yup.string().required().label('full name'),
      password: yup.string().required(),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords do not match!')
        .required()
        .label('confirm password'),
    }),
    onSubmit: (values) =>
      register({ ...values, token: searchParams.get('token') ?? '' })
        .unwrap()
        .then(() => {
          toast.success('Registered successfully.');
          navigate(
            nodeConfig.emailEnabled ? '/email-confirm-needed' : '/login',
          );
        }),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Helmet title="Register" />
      <div className="flex space-between login-main-container">
        <div className="login-container ">
          <div>
            <h2 className={'h2-login-title'}>hello</h2>
            <h2 className={'h2-login-info'}>
              Register to view, add, edit <br />
              or delete records
            </h2>
          </div>

          <RegisterFormComponent formik={formik} isLoading={isLoading} />
        </div>
        <div>
          <img
            style={{ maxWidth: '500px' }}
            src={'/pictures/logo_university_centrum.jpg'}
          />
        </div>
      </div>
    </form>
  );
};

export default RegisterPage;
