import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

const EmailConfirmNeededPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    navigate(location.pathname, {
      replace: true,
      state: {
        breadcrumbs: [
          { name: 'Email confirmation needed', path: '/email-confirm-needed' },
        ],
      },
    });
  }, []);
  return (
    <div className="flex space-between login-main-container">
      <Helmet title="Confirm your email" />
      <div className="login-container ">
        <div>
          <h2 className={'h2-login-title'}>hello</h2>
          <h2 className={'h2-login-info'}>
            you are successfully <br />
            registered but in order <br />
            to continue you have to confirm your email
          </h2>
          <h2 className={'h2-login-info'}>
            check your inbox <br />
            or contact your <br />
            supervisor
          </h2>
          <h2 className={'h2-login-info'}>
            or <br />
            <Link to="/resend-confirmation-email">
              <span
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  fontSize: '1.7em',
                }}
              >
                send confirmation email again
              </span>
            </Link>
          </h2>
        </div>

        <div className="btn-form-footer btn-footer-login">
          <Link to="/">
            <Button
              className="login-button add-btn"
              color="primary"
              type="submit"
            >
              <span className="add-btn-text">back to login</span>
            </Button>
          </Link>
        </div>
      </div>
      <div>
        <img
          style={{ maxWidth: '500px' }}
          src={'/pictures/logo_university_centrum.jpg'}
        />
      </div>
    </div>
  );
};

export default EmailConfirmNeededPage;
