import { TextAreaField } from '@daisy/daisy-common';
import { FormControl } from '@mui/material';
import { FastField, useFormikContext } from 'formik';
import React from 'react';

import TextInputComponent from '../common/configurator-inputs/text-input.component';

const TextAreaFieldFormComponent: React.FC = () => {
  const formik = useFormikContext<TextAreaField>();
  return (
    <>
      <FormControl
        variant="standard"
        sx={{ mb: 3, display: 'block', minWidth: '500px' }}
      >
        <FastField
          component={TextInputComponent}
          id="placeholder"
          name="placeholder"
          label="Field placeholder"
          variant="standard"
          sx={{ minWidth: 1 / 1 }}
          helperText={
            formik.errors.placeholder ? formik.errors.placeholder : ''
          }
        />
      </FormControl>
      <FormControl
        variant="standard"
        sx={{ mb: 3, display: 'block', minWidth: '500px' }}
      >
        <FastField
          component={TextInputComponent}
          id="minLength"
          name="minLength"
          label="Field min length"
          variant="standard"
          inputProps={{
            type: 'number',
            inputMode: 'numeric',
            pattern: '[0-9]*',
          }}
          sx={{ minWidth: 1 / 1 }}
          helperText={formik.errors.minLength ? formik.errors.minLength : ''}
        />
      </FormControl>
      <FormControl
        variant="standard"
        sx={{ mb: 3, display: 'block', minWidth: '500px' }}
      >
        <FastField
          component={TextInputComponent}
          id="maxLength"
          name="maxLength"
          label="Field max length"
          variant="standard"
          inputProps={{
            type: 'number',
            inputMode: 'numeric',
            pattern: '[0-9]*',
          }}
          sx={{ minWidth: 1 / 1 }}
          helperText={formik.errors.maxLength ? formik.errors.maxLength : ''}
        />
      </FormControl>
      <FormControl
        variant="standard"
        sx={{ mb: 3, display: 'block', minWidth: '500px' }}
      >
        <FastField
          component={TextInputComponent}
          id="rows"
          name="rows"
          label="Field rows value"
          variant="standard"
          inputProps={{
            type: 'number',
            inputMode: 'numeric',
            pattern: '[0-9]*',
          }}
          sx={{ minWidth: 1 / 1 }}
          helperText={formik.errors.rows ? formik.errors.rows : ''}
        />
      </FormControl>
      <FormControl
        variant="standard"
        sx={{ mb: 3, display: 'block', minWidth: '500px' }}
      >
        <FastField
          component={TextInputComponent}
          id="default"
          name="default"
          label="Field default value"
          variant="standard"
          sx={{ minWidth: 1 / 1 }}
          helperText={formik.errors.default ? formik.errors.default : ''}
        />
      </FormControl>
    </>
  );
};

export default TextAreaFieldFormComponent;
