import { DataEntity, FieldType } from '@daisy/daisy-common';
import { Box, Typography } from '@mui/material';
import React from 'react';

type Props = {
  entity: DataEntity;
  entities: DataEntity[];
};

const FieldsReviewComponent: React.FC<Props> = ({ entity, entities }) => {
  return (
    <>
      <Typography variant="h6">Fields:</Typography>
      {entity.fields.map((field, i) => {
        const basicDefault =
          field.default &&
          field.type !== FieldType.CHECKBOX_FIELD &&
          !(
            field.type === FieldType.RADIO_FIELD ||
            field.type === FieldType.SELECT_FIELD ||
            field.type === FieldType.MULTI_SELECT_FIELD
          );
        return (
          <Box key={`${field.name}-${i}`}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Name:</Typography>
              <Typography variant="body1">{field.name}</Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Type:</Typography>
              <Typography variant="body1">
                {field.type.replace('_', ' ').toLowerCase()}
              </Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Description:</Typography>
              <Typography variant="body1">{field.description}</Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Label:</Typography>
              <Typography variant="body1">{field.label}</Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Required:</Typography>
              <Typography variant="body1">
                {field.required ? 'yes' : 'no'}
              </Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Indexed:</Typography>
              <Typography variant="body1">
                {field.indexed ? 'yes' : 'no'}
              </Typography>
            </Box>
            {field.type === FieldType.TEXT_FIELD && (
              <>
                {field.placeholder && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle1">Placeholder:</Typography>
                    <Typography variant="body1">{field.placeholder}</Typography>
                  </Box>
                )}
                {field.minLength && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle1">Minimum length:</Typography>
                    <Typography variant="body1">{field.minLength}</Typography>
                  </Box>
                )}
                {field.maxLength && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle1">Maximum length:</Typography>
                    <Typography variant="body1">{field.maxLength}</Typography>
                  </Box>
                )}
              </>
            )}
            {field.type === FieldType.NUMBER_FIELD && (
              <>
                {field.min && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle1">Minimum:</Typography>
                    <Typography variant="body1">{field.min}</Typography>
                  </Box>
                )}
                {field.max && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle1">Maximum:</Typography>
                    <Typography variant="body1">{field.max}</Typography>
                  </Box>
                )}
                {field.step && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle1">Step:</Typography>
                    <Typography variant="body1">{field.step}</Typography>
                  </Box>
                )}
              </>
            )}
            {field.type === FieldType.TEXT_AREA_FIELD && (
              <>
                {field.placeholder && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle1">Placeholder:</Typography>
                    <Typography variant="body1">{field.placeholder}</Typography>
                  </Box>
                )}
                {field.minLength && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle1">Minimum length:</Typography>
                    <Typography variant="body1">{field.minLength}</Typography>
                  </Box>
                )}
                {field.maxLength && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle1">Maximum length:</Typography>
                    <Typography variant="body1">{field.maxLength}</Typography>
                  </Box>
                )}
                {field.rows && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle1">Rows:</Typography>
                    <Typography variant="body1">{field.rows}</Typography>
                  </Box>
                )}
              </>
            )}
            {field.type === FieldType.CHECKBOX_FIELD && (
              <>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle1">
                    Is this field checked by default?
                  </Typography>
                  <Typography variant="body1">
                    {field.default ? 'yes' : 'no'}
                  </Typography>
                </Box>
              </>
            )}
            {(field.type === FieldType.RADIO_FIELD ||
              field.type === FieldType.SELECT_FIELD ||
              field.type === FieldType.MULTI_SELECT_FIELD) && (
              <>
                <Typography variant="subtitle1">Values</Typography>
                <Box sx={{ display: 'flex' }}>
                  {field.values.map((value, i) => (
                    <Box
                      key={`${value}-${i}`}
                      sx={{ display: 'flex', border: 1, mr: 2, mb: 2 }}
                    >
                      <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle1">Value:</Typography>
                        <Typography variant="body1">{value}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
                {field.default && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle1">Default value:</Typography>
                    <Typography variant="body1">
                      {Array.isArray(field.default)
                        ? field.default.join(', ')
                        : field.default}
                    </Typography>
                  </Box>
                )}
              </>
            )}
            {field.type === FieldType.RELATION_FIELD && (
              <>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle1">Target:</Typography>
                  <Typography variant="body1">
                    {field.target
                      .map(
                        (trg) => entities.find((ent) => ent.id === trg)?.name,
                      )
                      .join(', ')}
                  </Typography>
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle1">
                    Can this field have multiple values?
                  </Typography>
                  <Typography variant="body1">
                    {field.multi ? 'yes' : 'no'}
                  </Typography>
                </Box>
              </>
            )}
            {(field.type === FieldType.IMAGE_FIELD ||
              field.type === FieldType.FILE_FIELD) && (
              <>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle1">Allowed formats:</Typography>
                  <Typography variant="body1">
                    {[...field.allowedFormats].join(', ')}
                  </Typography>
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle1">
                    Can this field have multiple values?
                  </Typography>
                  <Typography variant="body1">
                    {field.multi ? 'yes' : 'no'}
                  </Typography>
                </Box>
              </>
            )}
            {basicDefault && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1">Default value:</Typography>
                <Typography variant="body1">{field.default}</Typography>
              </Box>
            )}
          </Box>
        );
      })}
    </>
  );
};

export default FieldsReviewComponent;
