import { Facet } from '@daisy/daisy-common';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ParamKeyValuePair, useSearchParams } from 'react-router-dom';

import styles from './facets.module.scss';

type Props = {
  facets: Facet;
  initialValues: Record<string, string[]>;
  baseFacets: Facet;
};

const FacetsComponent: React.FC<Props> = ({
  facets,
  initialValues,
  baseFacets,
}) => {
  const [open, setOpen] = useState<{ [key: string]: boolean }>(
    Object.fromEntries(Object.keys(facets).map((key) => [key, true])),
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [searched, setSearched] = useState(false);

  useEffect(() => {
    setSearched(
      !![...searchParams.entries()].filter(([key]) =>
        Object.keys(initialValues).includes(key),
      ).length,
    );
  }, [searchParams]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values) => {
        const mappedEntries: ParamKeyValuePair[] = [];
        for (const [key, arr] of Object.entries(values)) {
          arr.forEach((item) => mappedEntries.push([key, item]));
        }
        const filteredParams: ParamKeyValuePair[] = [
          ...searchParams.entries(),
        ].filter(([key]) => !Object.keys(initialValues).includes(key));
        const merged = [...mappedEntries, ...filteredParams];
        setSearchParams(merged);
      }}
      onReset={(values) => {
        setSearchParams(
          Object.fromEntries(
            [...searchParams.entries()].filter(
              ([key]) => !Object.keys(values).includes(key),
            ),
          ),
        );
      }}
    >
      {(formik) => (
        <Form>
          {(formik.dirty || searched) && (
            <Button
              className={styles.btn}
              type="button"
              variant="contained"
              onClick={() => formik.resetForm()}
            >
              Reset filters
            </Button>
          )}
          {Object.keys(baseFacets).map((facet, i) => {
            const currentFacets = baseFacets[facet];
            return (
              <div className={styles.facetWrapper} key={`${facet}-${i}`}>
                <span
                  className={styles.facetGroupName}
                  onClick={() => {
                    const current = { ...open };
                    current[facet] = !current[facet];
                    setOpen(current);
                  }}
                >
                  <span>
                    {facet.replace(/([a-z0-9])([A-Z])/g, '$1 $2').toLowerCase()}
                  </span>
                  {open && open[facet] ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </span>
                <ul
                  style={{
                    height:
                      open && open[facet]
                        ? 24 * Object.keys(currentFacets).length + 24
                        : 0,
                    transition: 'all 0.5s',
                    overflowY: 'auto',
                  }}
                >
                  {Object.keys(currentFacets).map((currentFacet, j) => {
                    return (
                      <li key={`${facet}-${i}-${j}`}>
                        <label className={styles.facet}>
                          <Field
                            type="checkbox"
                            name={facet}
                            value={currentFacet}
                          />
                          <span className={styles.facetLabel}>
                            {currentFacet.replace('_', '')}
                          </span>
                          <span className={styles.facetCount}>
                            {facets[facet][currentFacet] || 0}
                          </span>
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
          {(formik.dirty || searched) && (
            <div className={styles.buttonWrapper}>
              <Button className={styles.btn} type="submit" variant="contained">
                Search
              </Button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default FacetsComponent;
