import { Media } from '@daisy/daisy-common';
import { Button, Card, List, ListItem, Typography } from '@mui/material';
import React from 'react';

interface Props {
  files: Media[];
  missionId: string;
  onDelete?: (mediaId: string) => void;
}

const FilesWrapperComponent: React.FC<Props> = ({
  files,
  missionId,
  onDelete,
}) => {
  return (
    <>
      {files.length ? (
        <>
          <Typography variant="h4" sx={{ mb: 3 }}>
            Files
          </Typography>
          <div className="width100">
            <List>
              <ListItem sx={{ pl: 0 }}>
                {files.map((file) => (
                  <Card
                    key={file.id}
                    sx={{
                      p: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant="body1" style={{ lineHeight: '37px' }}>
                      {file.fileName}
                    </Typography>
                    <div className="buttons-wrapper">
                      <Button
                        href={`/api/media-storage/${missionId}/download/${file.fileName}`}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                        className="new-mission-btn"
                      >
                        <span className="add-btn-text">download</span>
                      </Button>
                      {onDelete && (
                        <Button
                          className="new-mission-btn"
                          onClick={() => onDelete(file.id)}
                        >
                          <span className="add-btn-text">delete</span>
                        </Button>
                      )}
                    </div>
                  </Card>
                ))}
              </ListItem>
            </List>
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default FilesWrapperComponent;
