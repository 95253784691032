import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { BreadcrumbInterface } from '../types/breadcrumb.interface';

export const useBreadcrumb = (breadcrumbs: BreadcrumbInterface[]) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    navigate(
      { pathname: location.pathname, search: searchParams.toString() },
      {
        replace: true,
        state: { breadcrumbs: [{ name: 'Home', path: '' }, ...breadcrumbs] },
      },
    );
  }, []);
};
