import { Config, MissionConfigInterface } from '@daisy/daisy-common';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const missionConfigApi = createApi({
  reducerPath: 'missionConfigApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/mission-config' }),
  tagTypes: ['Mission', 'GisTables', 'OpenMissionConfig'],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    createMissionConfig: builder.mutation<
      MissionConfigInterface,
      { config: Config; missionId: string }
    >({
      query: ({ config, missionId }) => ({
        url: `/${missionId}`,
        method: 'POST',
        credentials: 'include',
        body: { config },
      }),
    }),
    updateMissionConfig: builder.mutation<
      MissionConfigInterface,
      {
        gisTables: string[];
        config: Config;
        missionId: string;
        configId: string;
      }
    >({
      query: ({ config, gisTables, missionId, configId }) => ({
        url: `/${missionId}/${configId}`,
        method: 'PATCH',
        credentials: 'include',
        body: { config, gisTables },
      }),
      invalidatesTags: ['Mission'],
    }),
    getGisTables: builder.query<string[], string>({
      query: (missionName) => ({
        url: `/gis-tables?name=${missionName}`,
        credentials: 'include',
      }),
      providesTags: ['GisTables'],
    }),
    getMissionConfig: builder.query<
      MissionConfigInterface,
      { missionId: string; configId: string }
    >({
      query: ({ missionId, configId }) => ({
        url: `/${missionId}/${configId}`,
      }),
      providesTags: ['OpenMissionConfig'],
    }),
  }),
});

export const {
  useCreateMissionConfigMutation,
  useUpdateMissionConfigMutation,
  useGetGisTablesQuery,
  useGetMissionConfigQuery,
} = missionConfigApi;
