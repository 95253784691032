import { MissionConfigInterface, NodeType } from '@daisy/daisy-common';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '..';

export interface NodeConfigState {
  nodeType: NodeType;
  emailEnabled: boolean;
  openDataConfig: MissionConfigInterface | null;
}

const initialState: NodeConfigState = {
  nodeType: NodeType.MASTER,
  emailEnabled: false,
  openDataConfig: null,
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setNodeConfig: (state, action: PayloadAction<NodeConfigState>) => {
      state.emailEnabled = action.payload.emailEnabled;
      state.nodeType = action.payload.nodeType;
    },
    setOpenDataConfig: (
      state,
      action: PayloadAction<MissionConfigInterface>,
    ) => {
      state.openDataConfig = action.payload;
    },
  },
});

export const { setNodeConfig, setOpenDataConfig } = configSlice.actions;

export const selectNodeConfig = (state: RootState) => state.config;
export const selectOpenDataConfig = (state: RootState) =>
  state.config.openDataConfig;

export default configSlice.reducer;
