import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { ReactNode } from 'react';

type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
};

const EntityFormModalComponent: React.FC<Props> = ({
  open,
  onClose,
  title,
  children,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ p: 3 }}>{children}</DialogContent>
    </Dialog>
  );
};

export default EntityFormModalComponent;
