import {
  FieldType,
  ItemData,
  MultiValue,
  ValuesType,
  isBoolean,
} from '@daisy/daisy-common';
import { Button, Divider, FormControl } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { ComponentsMap } from '../../components/common/inputs/components-map';
import MapComponent from '../../components/common/map/map.component';
import SpinnerComponent from '../../components/common/spinner/spinner.component';
import { useApiError } from '../../hooks/useApiError';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { useReadOnlyMission } from '../../hooks/useReadOnlyMission';
import { useCreateItemMutation } from '../../store/api/item.api';
import { useGetMissionByIdQuery } from '../../store/api/mission.api';

const EntityCreatePage: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const missionQuery = useGetMissionByIdQuery(params.id || '');
  const [createMutation, createMutationResult] = useCreateItemMutation();
  useApiError(missionQuery.error);
  useApiError(createMutationResult.error);
  useReadOnlyMission(missionQuery.data);
  const getEntity = useCallback(
    () =>
      missionQuery.data?.missionConfig.config.entities.find(
        (ent) => ent.id === params.entity,
      ),
    [missionQuery.data],
  );
  useBreadcrumb([
    { name: 'Expeditions', path: '/expeditions' },
    {
      name: missionQuery.data?.name || '',
      path: `/${params.id || ''}`,
    },
    {
      name: getEntity()?.name || '',
      path: `/${params.entity || ''}`,
    },
    {
      name: 'Create',
      path: '/create',
    },
  ]);

  const generateDefault = () => {
    const init: Record<string, string[] | boolean | string | MultiValue[]> = {};
    (getEntity()?.fields ?? []).map((field) => {
      if (field.type === FieldType.FILE_FIELD && field.multi) {
        init[field.name] = [];
      } else if (field.type === FieldType.CHECKBOX_FIELD) {
        init[field.name] = field.default ? field.default : false;
      } else if (
        field.type === FieldType.MULTI_SELECT_FIELD ||
        field.type === FieldType.MULTI_SELECT_FIELD_WITH_VALUES
      ) {
        init[field.name] = field.default ? field.default : [];
      } else if (field.type === FieldType.RELATION_FIELD && field.multi) {
        init[field.name] = [];
      } else {
        init[field.name] = '';
      }
    });
    return init;
  };

  const generateValidation = () => {
    const validation: Record<string, yup.AnySchema> = {};
    (getEntity()?.fields ?? []).map((field) => {
      if (
        field.type === FieldType.FILE_FIELD ||
        field.type === FieldType.IMAGE_FIELD
      ) {
        validation[field.name] = field.required
          ? yup.mixed().required()
          : yup.mixed();
      } else if (field.type === FieldType.CHECKBOX_FIELD) {
        validation[field.name] = field.required
          ? yup.boolean().required()
          : yup.boolean();
      } else if (field.type === FieldType.MULTI_SELECT_FIELD) {
        validation[field.name] = field.required
          ? yup.array().of(yup.string()).min(1)
          : yup.array().of(yup.string());
      } else if (field.type === FieldType.MULTI_SELECT_FIELD_WITH_VALUES) {
        const item = yup.object().shape({
          item: yup.string().required('Required!'),
          value:
            field.valueTypes === ValuesType.TEXT
              ? yup.string().required('Required!')
              : yup.number().required('Required!'),
        });
        validation[field.name] = field.required
          ? yup.array().of(item).min(1)
          : yup.array().of(item);
      } else if (field.type === FieldType.RELATION_FIELD) {
        const multi = field.required
          ? yup.array().of(yup.string()).min(1)
          : yup.array().of(yup.string());
        const single = field.required ? yup.string().required() : yup.string();
        validation[field.name] = field.multi ? multi : single;
      } else if (field.type === FieldType.NUMBER_FIELD) {
        validation[field.name] = field.required
          ? yup.number().required()
          : yup.number();
      } else if (field.type === FieldType.INVENTORY_NUMBER_FIELD) {
        if (field.valueTypes === ValuesType.NUMBER) {
          validation[field.name] = field.required
            ? yup.number().required()
            : yup.number();
        } else if (field.required) {
          validation[field.name] = yup.string().required();
        }
      } else {
        validation[field.name] = field.required
          ? yup.string().required()
          : yup.string();
      }
    });
    return validation;
  };

  const onSubmit = (values: ItemData) => {
    const fd = new FormData();
    const data: [
      string,
      string | string[] | boolean | File | File[] | MultiValue[],
    ][] = [];
    Object.entries(values).forEach(([key, value]) => {
      if (value || value === false) {
        data.push([key, value]);
      }
    });
    for (const [key, value] of data) {
      if (Array.isArray(value)) {
        (value as string[]).forEach((v) =>
          (v as any).item // eslint-disable-line
            ? fd.append(key, JSON.stringify(v))
            : fd.append(key, v),
        );
      } else if (isBoolean(value)) {
        fd.append(key, `${value}`);
      } else {
        fd.append(key, value as string | Blob);
      }
    }

    createMutation({
      data: fd,
      missionId: params.id || '',
      schemaId: params.entity || '',
    })
      .unwrap()
      .then((item) =>
        navigate(
          `/expeditions/${params.id || ''}/${params.entity || ''}/${item.id}`,
        ),
      )
      .catch((error) => {
        const message = (error as { data: { message: string } }).data.message;
        toast.error(message);
      });
  };

  return (
    <>
      {missionQuery.isLoading ? (
        <SpinnerComponent />
      ) : (
        <>
          <Helmet title={`Create ${getEntity()?.name || ''}`} />
          <div
            className="flex space-between div-btn-header"
            style={{ marginBottom: '2%' }}
          >
            <h2 className="h2-login-info">Create {getEntity()?.name || ''}</h2>
          </div>
          <Divider sx={{ mb: 3 }} />
          <div className="width100" style={{ marginBottom: '80px' }}>
            <Formik
              initialValues={generateDefault()}
              validationSchema={yup.object(generateValidation())}
              validateOnBlur={false}
              validateOnChange={false}
              validateOnMount={false}
              onSubmit={onSubmit}
            >
              {(formik) => (
                <Form>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'start',
                    }}
                  >
                    {getEntity()?.fields.map((field, index) => (
                      <div
                        key={index}
                        style={{
                          width: field.width ?? '50%',
                          paddingLeft: '5px',
                        }}
                      >
                        <ComponentsMap
                          error={
                            formik.touched[field.name] &&
                            !!formik.errors[field.name]
                          }
                          helperText={formik.errors[field.name]}
                          value={formik.values[field.name]}
                          onChange={
                            field.type === FieldType.IMAGE_FIELD ||
                            field.type === FieldType.FILE_FIELD ||
                            field.type === FieldType.MULTI_SELECT_FIELD ||
                            field.type === FieldType.RELATION_FIELD ||
                            field.type === FieldType.CHECKBOX_FIELD ||
                            field.type === FieldType.DATE_FIELD
                              ? formik.setFieldValue
                              : formik.handleChange
                          }
                          missionId={params.id}
                          itemLabel={(field.type === FieldType.RELATION_FIELD
                            ? field.target
                            : []
                          ).map(
                            (tgr) =>
                              missionQuery.data?.missionConfig.config.entities.find(
                                (ent) => ent.id === tgr,
                              )?.label,
                          )}
                          {...field}
                        />
                      </div>
                    ))}
                  </div>
                  {getEntity()?.xField && getEntity()?.yField && (
                    <MapComponent
                      xField={getEntity()?.xField ?? ''}
                      yField={getEntity()?.yField ?? ''}
                    />
                  )}
                  <FormControl
                    variant="standard"
                    sx={{ mb: 3, minWidth: 320 }}
                    fullWidth
                  >
                    <div
                      className="width100 flex"
                      style={{
                        justifyContent: 'flex-end',
                        borderTop: 'solid 1px rgba(0, 0, 0, 0.12)',
                        padding: '20px 10px',
                      }}
                    >
                      <Button className={'new-mission-btn'} type="submit">
                        <span className={'add-btn-text'}>submit</span>
                      </Button>
                    </div>
                  </FormControl>
                </Form>
              )}
            </Formik>
          </div>
        </>
      )}
    </>
  );
};

export default EntityCreatePage;
