import { Breadcrumb } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { BreadcrumbInterface } from '../../types/breadcrumb.interface';

const BreadcrumbsComponent: React.FC = () => {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbInterface[]>([]);
  const location = useLocation();
  useEffect(() => {
    if (
      location.state &&
      (location.state as { breadcrumbs: BreadcrumbInterface[] }).breadcrumbs
    ) {
      setBreadcrumbs(
        (
          location.state as { breadcrumbs: BreadcrumbInterface[] }
        ).breadcrumbs.reduce<BreadcrumbInterface[]>((prev, current) => {
          if (prev.length == 0) {
            return [current];
          } else {
            const tmp = {
              name: current.name,
              path: `${prev[prev.length - 1].path || ''}${current.path}`,
            };
            prev.push(tmp);
            return prev;
          }
        }, []),
      );
    }
  }, [location]);
  return (
    <div className="breadcrumbs-wrapper">
      <Breadcrumb>
        {breadcrumbs.map((breadcrumb, i) => (
          <Breadcrumb.Item key={`breadcrumb-${i}`}>
            <Link to={breadcrumb.path} state={{ breadcrumbs }}>
              {breadcrumb.name}
            </Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );
};

export default BreadcrumbsComponent;
