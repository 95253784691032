import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { selectUser } from '../../../store/features/user';

type Props = {
  children: ReactNode;
};

const AdminWrapperComponent: React.FC<Props> = ({ children }) => {
  const user = useSelector(selectUser);
  return user?.admin ? <>{children}</> : <></>;
};

export default AdminWrapperComponent;
