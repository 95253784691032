import { RelationField, ReversRelationStatus } from '@daisy/daisy-common';
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { FastField, useFormikContext } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectEntities } from '../../store/features/mission-configurator';
import TextInputComponent from '../common/configurator-inputs/text-input.component';

const RelationFieldFormComponent: React.FC = () => {
  const formik = useFormikContext<RelationField>();
  const entities = useSelector(selectEntities);
  return (
    <>
      <FormControl
        variant="standard"
        sx={{ mb: 3, display: 'block', minWidth: '500px' }}
        error={formik.touched.target && !!formik.errors.target}
      >
        <InputLabel id="target">Relation target</InputLabel>
        <Select
          labelId="target"
          value={formik.values.target}
          onChange={formik.handleChange}
          name="target"
          label="Field type"
          placeholder="Select field type"
          error={formik.touched.target && !!formik.errors.target}
          multiple
          readOnly={formik.values.reverse === ReversRelationStatus.REVERSE}
          sx={{ pr: 3, minWidth: 1 / 1 }}
        >
          {entities.map((current) => (
            <MenuItem value={current.id} key={`target-${current.name}`}>
              {current.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error={formik.touched.target && !!formik.errors.target}>
          {formik.touched.target && !!formik.errors.target
            ? formik.errors.target
            : ''}
        </FormHelperText>
      </FormControl>
      <FormControl
        variant="standard"
        sx={{ mb: 3, display: 'block', minWidth: '500px' }}
      >
        <FormControlLabel
          control={
            <Checkbox
              id="multi"
              name="multi"
              checked={formik.values.multi}
              onChange={(_e, checked) => {
                formik.setFieldValue('multi', checked);
              }}
            />
          }
          label="Can this field have multiple values?"
        />
      </FormControl>
      {formik.values.reverse !== ReversRelationStatus.REVERSE && (
        <FormControl
          variant="standard"
          sx={{ mb: 3, display: 'block', minWidth: '500px' }}
          error={formik.touched.reverse && !!formik.errors.reverse}
        >
          <InputLabel id="reverse">Reverse relation</InputLabel>
          <Select
            labelId="reverse"
            value={formik.values.reverse}
            onChange={formik.handleChange}
            name="reverse"
            label="Field type"
            placeholder="Select field type"
            error={formik.touched.reverse && !!formik.errors.reverse}
            sx={{ pr: 3, minWidth: 1 / 1 }}
          >
            <MenuItem value={ReversRelationStatus.SKIPPED}>
              {ReversRelationStatus.SKIPPED}
            </MenuItem>
            <MenuItem value={ReversRelationStatus.PRESENT}>
              {ReversRelationStatus.PRESENT}
            </MenuItem>
          </Select>
          <FormHelperText
            error={formik.touched.target && !!formik.errors.target}
          >
            {formik.touched.target && !!formik.errors.target
              ? formik.errors.target
              : ''}
          </FormHelperText>
        </FormControl>
      )}
      {formik.values.reverse === ReversRelationStatus.PRESENT && (
        <>
          <Alert severity="info">
            Remember reverse relation won&apos;t be visible on the other side
            until all changes are saved.
          </Alert>
          <FormControl
            variant="standard"
            sx={{ mb: 3, display: 'block', minWidth: '500px' }}
          >
            <FastField
              component={TextInputComponent}
              id="reverseName"
              name="reverseName"
              label="Reverse relation field name"
              variant="standard"
              sx={{ minWidth: 1 / 1 }}
              helperText={
                formik.errors.reverseName ? formik.errors.reverseName : ''
              }
            />
          </FormControl>
          <FormControl
            variant="standard"
            sx={{ mb: 3, display: 'block', minWidth: '500px' }}
            error={formik.touched.target && !!formik.errors.target}
          >
            <InputLabel id="reverseTarget">Relation reverse target</InputLabel>
            <Select
              labelId="reverseTarget"
              value={formik.values.reverseTarget}
              onChange={formik.handleChange}
              name="reverseTarget"
              label="Field type"
              placeholder="Select field type"
              error={
                formik.touched.reverseTarget && !!formik.errors.reverseTarget
              }
              multiple
              sx={{ pr: 3, minWidth: 1 / 1 }}
            >
              {entities.map((current) => (
                <MenuItem
                  value={current.id}
                  key={`reverseTarget-${current.name}`}
                >
                  {current.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText
              error={
                formik.touched.reverseTarget && !!formik.errors.reverseTarget
              }
            >
              {formik.touched.reverseTarget && !!formik.errors.reverseTarget
                ? formik.errors.reverseTarget
                : ''}
            </FormHelperText>
          </FormControl>
        </>
      )}
    </>
  );
};

export default RelationFieldFormComponent;
