import { NodeType, UserRole } from '@daisy/daisy-common';
import { Button, Chip } from '@mui/material';
import { List } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import AdminWrapperComponent from '../../components/common/admin-wrapper/admin-wrapper.component';
import CustomCardComponent from '../../components/custom-card/custom-card.component';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { selectNodeConfig } from '../../store/features/config';
import { selectUser } from '../../store/features/user';

const MissionsPage: React.FC = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const { nodeType } = useSelector(selectNodeConfig);
  useBreadcrumb([{ name: 'Expeditions', path: '/expeditions' }]);
  return (
    <div>
      <div className="flex space-between div-btn-header">
        {nodeType !== NodeType.CHILD && (
          <AdminWrapperComponent>
            <Button
              className={'add-btn'}
              onClick={() => navigate('/expeditions/create')}
            >
              <span className={'add-btn-text add-button'}>add expeditions</span>
            </Button>
          </AdminWrapperComponent>
        )}
      </div>

      <div className="flex width100 center">
        <List
          className="width100"
          grid={{
            gutter: 16,
            xs: 1,
            sm: 1,
            md: 1,
            lg: 3,
            xl: 3,
            xxl: 5,
          }}
          itemLayout="horizontal"
          dataSource={user?.missions || []}
          renderItem={(item) => (
            <List.Item>
              <CustomCardComponent
                actions={[
                  {
                    actionName: 'details',
                    actionHandler: () =>
                      navigate(`/expeditions/${item.mission.id}`),
                    securityRules: [],
                  },
                  {
                    actionName: 'edit',
                    actionHandler: () =>
                      navigate(`/expeditions/${item.mission.id}/edit`),
                    securityRules: item.mission.archived
                      ? [UserRole.ADMIN]
                      : [UserRole.ADMIN, UserRole.EXPEDITION_MANAGER],
                  },
                  // {
                  //   actionName: 'archive',
                  //   actionHandler: () => console.log('archive'),
                  //   securityRules: [UserRole.ADMIN],
                  //   actionConfirmationText: 'Are you sure?',
                  // },
                ]}
                userRole={item.role}
                body={
                  <div className={'mission-card'} title={item.mission.name}>
                    {item.mission.name}{' '}
                    {item.mission.archived && <Chip label="ARCHIVED" />}
                  </div>
                }
                bodyOnClick={() => navigate(`/expeditions/${item.mission.id}`)}
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

export default MissionsPage;
