import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const invitationApi = createApi({
  reducerPath: 'invitationApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/invitation' }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    createInvitation: builder.mutation<null, { email: string }>({
      query: (body) => ({
        url: '',
        method: 'POST',
        credentials: 'include',
        body,
      }),
    }),
  }),
});

export const { useCreateInvitationMutation } = invitationApi;
