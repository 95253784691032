import { RelationField } from '@daisy/daisy-common';
import {
  Autocomplete,
  Chip,
  createFilterOptions,
  FormControl,
  FormHelperText,
  MenuItem,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useApiError } from '../../../hooks/useApiError';
import { useGetLabelsQuery } from '../../../store/api/search.api';
import LabelNodeComponent from './label-node.component';

interface RelationFieldProps extends RelationField {
  error: boolean;
  helperText: string;
  value: string | string[];
  onChange: (field: string, value: string | string[]) => void;
  missionId: string;
  itemLabel: string[];
}

interface Option {
  name: string;
  label: string;
  id: string;
}

const filter = createFilterOptions<any>(); // eslint-disable-line

const RelationFieldComponent: React.FC<RelationFieldProps> = (props) => {
  const { data, error } = useGetLabelsQuery({
    missionId: props.missionId,
    indexes: props.target,
  });
  const setSelectedOptions = (data: [string, [string, string][]][]) => {
    const items = data
      .map(([name, labels]) =>
        labels.map(([id, label]) => ({ name, label, id })),
      )
      .flat();
    return Array.isArray(props.value)
      ? items.filter((item) => props.value.includes(item.id))
      : items.find((item) => item.id === props.value);
  };
  const [value, setValue] = useState<any>( // eslint-disable-line
    props.multi
      ? setSelectedOptions(
          data && data.length ? Object.entries(data ?? {}) : [], //eslint-disable-line
        ) //eslint-disable-line
      : undefined,
  );
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (data) {
      const values = setSelectedOptions(Object.entries(data));
      setValue(values);
      const mappedValues = values
        ? (Array.isArray(values) ? values : [values]).map((v) => v.id)
        : [];
      setOptions(
        Object.entries(data)
          .map(([name, labels]) =>
            labels.map(([id, label]) => ({ name, label, id })),
          )
          .flat()
          .filter((o) => !mappedValues.includes(o.id)),
      );
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const values = setSelectedOptions(Object.entries(data));
      const mappedValues = values
        ? (Array.isArray(values) ? values : [values]).map((v) => v.id)
        : [];
      setOptions(
        Object.entries(data)
          .map(([name, labels]) =>
            labels.map(([id, label]) => ({ name, label, id })),
          )
          .flat()
          .filter((o) => !mappedValues.includes(o.id)),
      );
    }
  }, [value]);

  useApiError(error);
  return (
    <FormControl
      variant="standard"
      sx={{ mb: 3, width: 1 / 1, display: 'block' }}
      error={props.error}
    >
      <Autocomplete
        value={
          value
            ? props.multi && !Array.isArray(value)
              ? [value]
              : value
            : undefined
        }
        onChange={(_event, newValue) => {
          setValue(newValue);
          if (Array.isArray(newValue)) {
            newValue = newValue.map((val) => val.id);
          } else {
            if (props.multi) {
              newValue = [newValue.id];
            } else {
              newValue = newValue.id;
            }
          }
          props.onChange(props.name, newValue);
        }}
        filterSelectedOptions={true}
        blurOnSelect={false}
        multiple={props.multi}
        id={props.name}
        renderOption={(props, { name, label, id }) => (
          <MenuItem {...props} value={id} key={id}>
            {name} - {label}
          </MenuItem>
        )}
        options={options}
        fullWidth
        disablePortal
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some(
            (option) => inputValue === option.title,
          );
          if (inputValue !== '' && !isExisting) {
            filtered.push(inputValue);
          }
          return filtered;
        }}
        renderTags={(value, getTagProps) =>
          value.map(({ name, label }, index) => (
            /* eslint-disable */
            <Chip
              {...getTagProps({ index })}
              variant="outlined"
              label={`${name} - ${label}`}
            />
            /* eslint-enable */
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={
              <LabelNodeComponent
                title={props.label}
                description={props.description}
              />
            }
            placeholder="Search"
          />
        )}
      />
      {props.error && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
};

export default RelationFieldComponent;
