import { FileField } from '@daisy/daisy-common';
import {
  Autocomplete,
  Checkbox,
  Chip,
  createFilterOptions,
  FormControl,
  FormControlLabel,
} from '@mui/material';
import { FastField, useFormikContext } from 'formik';
import React, { useState } from 'react';

import TextInputComponent from '../common/configurator-inputs/text-input.component';

const filter = createFilterOptions<any>(); // eslint-disable-line

const FileFieldFormComponent: React.FC = () => {
  const formik = useFormikContext<FileField>();
  const [value, setValue] = useState<any>(formik.values.allowedFormats ?? []); // eslint-disable-line
  return (
    <>
      <FormControl
        variant="standard"
        sx={{ mb: 3, display: 'block', minWidth: '500px' }}
      >
        <Autocomplete
          value={value}
          onChange={(_event, newValue) => {
            setValue(newValue);
            formik.setFieldValue('allowedFormats', newValue);
          }}
          multiple
          id="allowedFormats"
          options={[]}
          fullWidth
          freeSolo
          disablePortal
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            const isExisting = options.some(
              (option) => inputValue === option.title,
            );
            if (inputValue !== '' && !isExisting) {
              filtered.push(inputValue);
            }
            return filtered;
          }}
          renderTags={(value: string[], getTagProps) =>
            value.map((option: string, index: number) => (
              /* eslint-disable */
              <Chip
                {...getTagProps({ index })}
                variant="outlined"
                label={option}
              />
              /* eslint-enable */
            ))
          }
          renderInput={(params) => (
            <FastField
              component={TextInputComponent}
              {...params}
              variant="standard"
              label="Allowed formats"
              placeholder="Search"
            />
          )}
        />
      </FormControl>
      <FormControl
        variant="standard"
        sx={{ mb: 3, display: 'block', minWidth: '500px' }}
      >
        <FormControlLabel
          control={
            <Checkbox
              id="multi"
              name="multi"
              checked={formik.values.multi}
              onChange={(_e, checked) => formik.setFieldValue('multi', checked)}
            />
          }
          label="Can this field have multiple values?"
        />
      </FormControl>
    </>
  );
};

export default FileFieldFormComponent;
