"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImagesTypes = exports.ReversRelationStatus = exports.ValuesType = exports.FieldType = void 0;
/**
 * Enum listing types of available fields
 * @author Maciej Krawczyk
 * @export FieldType
 * @enum {number}
 */
var FieldType;
(function (FieldType) {
    FieldType["TEXT_FIELD"] = "TEXT_FIELD";
    FieldType["NUMBER_FIELD"] = "NUMBER_FIELD";
    FieldType["TEXT_AREA_FIELD"] = "TEXT_AREA_FIELD";
    FieldType["CHECKBOX_FIELD"] = "CHECKBOX_FIELD";
    FieldType["RADIO_FIELD"] = "RADIO_FIELD";
    FieldType["SELECT_FIELD"] = "SELECT_FIELD";
    FieldType["MULTI_SELECT_FIELD"] = "MULTI_SELECT_FIELD";
    FieldType["MULTI_SELECT_FIELD_WITH_VALUES"] = "MULTI_SELECT_FIELD_WITH_VALUES";
    FieldType["RELATION_FIELD"] = "RELATION_FIELD";
    FieldType["IMAGE_FIELD"] = "IMAGE_FIELD";
    FieldType["FILE_FIELD"] = "FILE_FIELD";
    FieldType["DATE_FIELD"] = "DATE_FIELD";
    FieldType["INVENTORY_NUMBER_FIELD"] = "INVENTORY_NUMBER_FIELD";
})(FieldType = exports.FieldType || (exports.FieldType = {}));
/**
 * Enum listing value field types for MULTI_SELECT_FIELD_WITH_VALUES field
 * @author Maciej Krawczyk
 * @export
 * @enum {number}
 */
var ValuesType;
(function (ValuesType) {
    ValuesType["TEXT"] = "text";
    ValuesType["NUMBER"] = "number";
})(ValuesType = exports.ValuesType || (exports.ValuesType = {}));
var ReversRelationStatus;
(function (ReversRelationStatus) {
    ReversRelationStatus["PRESENT"] = "present";
    ReversRelationStatus["SKIPPED"] = "skipped";
    ReversRelationStatus["REVERSE"] = "reverse";
})(ReversRelationStatus = exports.ReversRelationStatus || (exports.ReversRelationStatus = {}));
/**
 * List of allowed images formats
 * @export
 * @enum {number}
 */
var ImagesTypes;
(function (ImagesTypes) {
    ImagesTypes["jpg"] = ".jpg";
    ImagesTypes["jpeg"] = ".jpeg";
    ImagesTypes["png"] = ".png";
    ImagesTypes["tiff"] = ".tiff";
    ImagesTypes["tif"] = ".tif";
    ImagesTypes["raw"] = ".raw";
    ImagesTypes["cr2"] = ".cr2";
    ImagesTypes["nef"] = ".nef";
    ImagesTypes["orf"] = ".orf";
    ImagesTypes["sr2"] = ".sr2";
})(ImagesTypes = exports.ImagesTypes || (exports.ImagesTypes = {}));
