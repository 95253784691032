import { User } from '@daisy/daisy-common';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { NodeConfigState } from '../features/config';
import { setUser } from '../features/user';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/auth' }),
  tagTypes: ['Users'],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    login: builder.mutation<User, { email: string; password: string }>({
      query: ({ email, password }) => ({
        url: '/log-in',
        method: 'POST',
        body: { email, password },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(setUser(data));
      },
    }),
    register: builder.mutation<
      null,
      { email: string; password: string; name: string; token?: string }
    >({
      query: (body) => ({
        url: '/register',
        method: 'POST',
        body,
        headers: { Authorization: `bearer ${body.token}` },
      }),
      invalidatesTags: ['Users'],
    }),
    registerUser: builder.mutation<
      null,
      { email: string; password: string; name: string }
    >({
      query: (body) => ({
        url: '/register-user',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Users'],
    }),
    confirmEmail: builder.mutation<null, { token: string; email: string }>({
      query: (body) => ({
        url: '/confirm-email',
        method: 'POST',
        body,
        headers: { Authorization: `bearer ${body.token}` },
      }),
    }),
    resendConfirmationEmail: builder.mutation<null, { email: string }>({
      query: (body) => ({
        url: '/resend-email-confirm',
        method: 'POST',
        body,
      }),
    }),
    forgotPassword: builder.mutation<null, { email: string }>({
      query: (body) => ({
        url: '/forget-password',
        method: 'POST',
        body,
      }),
    }),
    resetPassword: builder.mutation<
      null,
      { email: string; password: string; token: string }
    >({
      query: (body) => ({
        url: '/reset-password',
        method: 'POST',
        body,
        headers: { Authorization: `bearer ${body.token}` },
      }),
    }),
    logout: builder.mutation<null, void>({
      query: () => ({
        url: '/log-out',
        method: 'POST',
        credentials: 'include',
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(setUser(null));
      },
    }),
    getNodeConfig: builder.query<NodeConfigState, void>({
      query: () => '/node-config',
    }),
    // open data config
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useConfirmEmailMutation,
  useResendConfirmationEmailMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useLogoutMutation,
  useRegisterUserMutation,
  useGetNodeConfigQuery,
} = authApi;
