import { UserRole } from '@daisy/daisy-common';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import { useApiError } from '../../hooks/useApiError';
import { useAddUpdateMissionUserMutation } from '../../store/api/mission.api';
import { useGetUsersQuery } from '../../store/api/user.api';

type Props = {
  missionId: string;
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
};

interface FormData {
  userId: string;
  userRole: UserRole;
}

const AddUserToMissionDialogComponent: React.FC<Props> = ({
  missionId,
  open,
  setOpen,
}) => {
  const getUser = useGetUsersQuery();
  const [addUpdateMissionUser, { error, isSuccess }] =
    useAddUpdateMissionUserMutation();
  const formik = useFormik<FormData>({
    initialValues: {
      userId: '',
      userRole: UserRole.EXPEDITION_READER,
    },
    onSubmit: (values) => {
      addUpdateMissionUser({ missionId, missionUser: values });
      setOpen(false);
    },
  });
  useEffect(() => {
    if (isSuccess) {
      toast.success('User role updated');
    }
  }, [isSuccess]);
  useApiError(error);
  useApiError(getUser.error);
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="alert-dialog-title">Update role</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormControl variant="standard" sx={{ m: 1, minWidth: 320 }}>
              <InputLabel id="user">User</InputLabel>
              <Select
                labelId="user"
                value={formik.values.userId}
                onChange={formik.handleChange}
                name="userId"
                label="User"
                sx={{ pr: 3 }}
              >
                {(getUser?.data || []).map((user) => (
                  <MenuItem value={user.id} key={user.id}>
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 320 }}>
              <InputLabel id="userRole">Role</InputLabel>
              <Select
                labelId="userRole"
                value={formik.values.userRole}
                onChange={formik.handleChange}
                name="userRole"
                label="Role"
                sx={{ pr: 3 }}
              >
                {Object.values(UserRole).map((role) => (
                  <MenuItem value={role} key={role}>
                    {role.replace('_', ' ')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="login-button add-btn"
            type="button"
            onClick={() => setOpen(false)}
          >
            cancel
          </Button>
          <Button className="login-button add-btn" type="submit" autoFocus>
            save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddUserToMissionDialogComponent;
