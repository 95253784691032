import { Field, FieldType } from '@daisy/daisy-common';
import React from 'react';

interface Props {
  labelConfig: Field;
  value: string;
}

const ListLabel: React.FC<Props> = ({ labelConfig, value }) => {
  return (
    <div className={'card-title'}>
      {labelConfig.type === FieldType.INVENTORY_NUMBER_FIELD
        ? `${labelConfig.prefix}${value}`
        : value}{' '}
    </div>
  );
};

export default ListLabel;
