import React from 'react';
import ReactDOM from 'react-dom/client';

import Mian from './main';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(<Mian />);

serviceWorkerRegistration.register();
