import {
  FieldType,
  MultiSelectWithValuesField,
  MultiValue,
  ValuesType,
} from '@daisy/daisy-common';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { FieldArray, FormikHandlers, getIn, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { v4 } from 'uuid';

import LabelNodeComponent from './label-node.component';
import NumberFieldComponent from './number-field.component';
import TextFieldComponent from './text-field.component';

interface Props extends MultiSelectWithValuesField {
  error: boolean | boolean[];
  helperText: string;
  value: MultiValue[];
  onChange: FormikHandlers['handleChange'];
}

const MultiSelectWithValueFieldComponent: React.FC<Props> = (props) => {
  const [selectValue, setSelectValue] = useState('');
  const [otherOption, setOtherOption] = useState('');
  const [otherValueError, setOtherValueError] = useState(false);
  const formik = useFormikContext();
  return (
    <FieldArray name={props.name}>
      {(arrayHelpers) => (
        <Box>
          <InputLabel id={props.name}>
            <LabelNodeComponent
              title={props.label}
              description={props.description}
            />
          </InputLabel>
          <FormControl
            variant="standard"
            sx={{ mb: 3, minWidth: 1 / 1, display: 'block' }}
          >
            <Select
              sx={{ width: 2 / 3 }}
              onChange={(e) => setSelectValue(e.target.value)}
              value={selectValue}
              error={
                (props.error as boolean) && !Array.isArray(props.helperText)
              }
            >
              <MenuItem value="" hidden disabled></MenuItem>
              {(
                props.values.filter(
                  (item) =>
                    !(props.value ?? []).find((val) => val.item === item),
                ) ?? []
              ).map((value) => (
                <MenuItem value={value} key={value}>
                  {value}
                </MenuItem>
              ))}
              {props.extendable && (
                <MenuItem value="other" key="other">
                  Other
                </MenuItem>
              )}
            </Select>
            <Button
              className={'new-mission-btn'}
              type="button"
              onClick={() => {
                if (selectValue) {
                  if (selectValue === 'other') {
                    if (otherOption) {
                      arrayHelpers.push({
                        item: otherOption,
                        value: '',
                      });
                      setOtherOption('');
                      setSelectValue('');
                    } else {
                      setOtherValueError(true);
                    }
                  } else {
                    arrayHelpers.push({
                      item: selectValue,
                      value: '',
                    });
                    setSelectValue('');
                  }
                }
              }}
            >
              <span className={'add-btn-text'}>add value</span>
            </Button>
            {props.error && !Array.isArray(props.helperText) && (
              <FormHelperText error={true}>
                <p style={{ textAlign: 'left' }}>{props.helperText}</p>
              </FormHelperText>
            )}
            {selectValue === 'other' && (
              <TextField
                label="Other option"
                variant="standard"
                onChange={(e) => {
                  setOtherValueError(false);
                  setOtherOption(e.target.value);
                }}
                error={otherValueError}
                helperText={otherValueError ? 'Required!' : ''}
              />
            )}
          </FormControl>
          <Box>
            {(props.value ?? []).map((val, i) => (
              <FormControl
                key={`${props.name}-${i}`}
                variant="standard"
                sx={{
                  mb: 3,
                  ml: 6,
                  width: 'calc(100% - 48px)',
                  display: 'flex',
                }}
              >
                <IconButton
                  onClick={() => arrayHelpers.remove(i)}
                  type="button"
                  sx={{ position: 'absolute', left: '-48px', top: '10px' }}
                >
                  <DeleteIcon />
                </IconButton>
                {props.valueTypes === ValuesType.TEXT ? (
                  <TextFieldComponent
                    id={v4()}
                    name={`${props.name}[${i}].value`}
                    value={val.value as string}
                    onChange={props.onChange}
                    width="100%"
                    error={
                      getIn(formik.touched, `${props.name}[${i}].value`) &&
                      !!getIn(formik.errors, `${props.name}[${i}].value`)
                    }
                    helperText={getIn(
                      formik.errors,
                      `${props.name}[${i}].value`,
                    )}
                    label={val.item}
                    required={true}
                    indexed={false}
                    sortable={false}
                    description=""
                    type={FieldType.TEXT_FIELD}
                  />
                ) : (
                  <NumberFieldComponent
                    id={v4()}
                    name={`${props.name}[${i}].value`}
                    value={val.value as string}
                    onChange={props.onChange}
                    width="100%"
                    error={
                      getIn(formik.touched, `${props.name}[${i}].value`) &&
                      !!getIn(formik.errors, `${props.name}[${i}].value`)
                    }
                    helperText={getIn(
                      formik.errors,
                      `${props.name}[${i}].value`,
                    )}
                    label={val.item}
                    required={true}
                    indexed={false}
                    sortable={false}
                    description=""
                    type={FieldType.NUMBER_FIELD}
                  />
                )}
              </FormControl>
            ))}
          </Box>
        </Box>
      )}
    </FieldArray>
  );
};

export default MultiSelectWithValueFieldComponent;
