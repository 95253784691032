import { UserRole } from '@daisy/daisy-common';
import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  roles: UserRole[];
  userRole: UserRole;
};

const SecureComponent: React.FC<Props> = ({ children, roles, userRole }) => {
  if (roles.length === 0) {
    return <>{children}</>;
  } else {
    return roles.includes(userRole) ? <>{children}</> : <></>;
  }
};

export default SecureComponent;
