import { Typography } from '@mui/material';
import { GeoJSON } from 'geojson';
import { defaults as defaultControls, ScaleLine } from 'ol/control';
import Feature, { FeatureLike } from 'ol/Feature';
import OLGeoJSON from 'ol/format/GeoJSON';
import Point from 'ol/geom/Point';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import { transform } from 'ol/proj';
import { BingMaps, Vector as VectorSource } from 'ol/source';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import View from 'ol/View';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  x: number;
  y: number;
  layers: GeoJSON[];
}

type Coords = [number, number];

const image = new CircleStyle({
  radius: 5,
  stroke: new Stroke({ color: 'red', width: 1 }),
});

const styles = {
  Point: new Style({
    image: image,
  }),
  LineString: new Style({
    stroke: new Stroke({
      color: 'green',
      width: 1,
    }),
  }),
  LinearRing: new Style({
    stroke: new Stroke({
      color: 'green',
      width: 1,
    }),
  }),
  MultiLineString: new Style({
    stroke: new Stroke({
      color: 'green',
      width: 1,
    }),
  }),
  MultiPoint: new Style({
    image: image,
  }),
  MultiPolygon: new Style({
    stroke: new Stroke({
      color: 'yellow',
      width: 1,
    }),
    fill: new Fill({
      color: 'rgba(255, 255, 0, 0.1)',
    }),
  }),
  Polygon: new Style({
    stroke: new Stroke({
      color: 'blue',
      lineDash: [4],
      width: 3,
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 255, 0.1)',
    }),
  }),
  GeometryCollection: new Style({
    stroke: new Stroke({
      color: 'magenta',
      width: 2,
    }),
    fill: new Fill({
      color: 'magenta',
    }),
    image: new CircleStyle({
      radius: 10,
      stroke: new Stroke({
        color: 'magenta',
      }),
    }),
  }),
  Circle: new Style({
    stroke: new Stroke({
      color: 'red',
      width: 2,
    }),
    fill: new Fill({
      color: 'rgba(255,0,0,0.2)',
    }),
  }),
};

const styleFunction = (feature: FeatureLike): Style =>
  styles[feature.getGeometry()?.getType() as keyof typeof styles];

const MapPreviewComponent: React.FC<Props> = ({ x, y, layers }) => {
  const mapRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (mapRef.current) {
      let lat: number, lon: number;
      if (x && y) {
        lat = Number(x);
        lon = Number(y);
      }
      const degreesToMeters = (coords: Coords) =>
        transform(coords, 'EPSG:4326', 'EPSG:3857');

      const source = new BingMaps({
        key: 'AiHNi7mayoLTslMt4YUgi_vNPcb8PPlitpd9-oAEWtlJel0ycNNNYQ-DBPhbKhJm',
        imagerySet: 'Aerial',
      });
      const map = new Map({
        controls: defaultControls().extend([
          new ScaleLine({
            units: 'metric',
            bar: false,
            text: true,
            minWidth: 140,
          }),
        ]),
        target: mapRef.current,
        layers: [new TileLayer({ source })],
        view: new View({
          center: [4185385.9208, 3921831.0473],
          zoom: 7,
        }),
      });
      const vectorSource = new VectorSource();
      const vectorLayer = new VectorLayer({
        source: vectorSource,
        style: new Style({
          image: new CircleStyle({
            radius: 7,
            fill: new Fill({ color: 'rgba(255, 0, 0, 1)' }),
            stroke: new Stroke({ color: 'rgb(0, 0, 0)', width: 1 }),
          }),
        }),
      });
      map.addLayer(vectorLayer);
      const addPointAndFocus = () => {
        if (lat && lon) {
          vectorSource
            .getFeatures()
            .forEach((feature) => vectorSource.removeFeature(feature));
          vectorSource.addFeature(
            new Feature({
              geometry: new Point(degreesToMeters([lat, lon])),
              name: 'Site',
            }),
          );
          map.setView(
            new View({
              center: degreesToMeters([lat, lon]),
              zoom:
                (map.getView().getZoom() || 0) > 8
                  ? map.getView().getZoom()
                  : 8,
            }),
          );
        }
      };

      addPointAndFocus();
      console.log(layers);
      for (const layer of layers) {
        console.log(layer);
        const vectorSource = new VectorSource({
          features: new OLGeoJSON().readFeatures(layer),
        });

        const vectorLayer = new VectorLayer({
          source: vectorSource,
          style: styleFunction,
        });

        map.addLayer(vectorLayer);
        const extent = vectorLayer.getSource()?.getExtent();
        if (extent) {
          map.getView().fit(extent);
        }
      }

      return () => {
        map.dispose();
      };
    }
  }, [mapRef, x, y, layers]);
  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/ol@v7.1.0/ol.css"
          type="text/css"
        />
      </Helmet>
      <Typography variant="h5">Localization</Typography>
      <div ref={mapRef} style={{ height: '500px', width: '100%' }}></div>
    </>
  );
};

export default MapPreviewComponent;
