import { Button, FormHelperText, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { useApiError } from '../../hooks/useApiError';
import { useResetPasswordMutation } from '../../store/api/auth.api';

interface FormValues {
  email: string;
  password: string;
  confirmPassword: string;
}

const ResetPasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [register, { error, isLoading, isSuccess }] =
    useResetPasswordMutation();
  useEffect(() => {
    if (searchParams.has('email')) {
      formik.setFieldValue('email', searchParams.get('email'));
    }
  }, []);
  useApiError(error);
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isSuccess) {
      toast.success('Password changed, now you can login.');
      timeout = setTimeout(() => {
        navigate('/');
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isSuccess]);
  const formik = useFormik<FormValues>({
    initialValues: { email: '', password: '', confirmPassword: '' },
    validationSchema: yup.object({
      email: yup.string().email().required(),
      password: yup.string().required(),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords do not match!')
        .required()
        .label('confirm password'),
    }),
    onSubmit: (values) =>
      register({ ...values, token: searchParams.get('token') ?? '' }),
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Helmet title="Reset password" />
      <div className="flex space-between login-main-container">
        <div className="login-container ">
          <div>
            <h2 className={'h2-login-title'}>hello</h2>
            <h2 className={'h2-login-info'}>reset password</h2>
            <h2 className={'h2-login-info'}>
              make sure to use <br />
              exact link from email
            </h2>
          </div>
          <div className="login-inputs">
            <TextField
              id="password"
              className="width100"
              label="password"
              type="password"
              margin="normal"
              variant="standard"
              onChange={formik.handleChange}
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              disabled={isLoading}
            />
          </div>
          <div className="login-inputs">
            <TextField
              id="confirmPassword"
              className="width100"
              label="confirm password"
              type="password"
              margin="normal"
              variant="standard"
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              disabled={isLoading}
            />
            <FormHelperText id="component-helper-text">
              use minimum 8 signs without spaces or special characters
            </FormHelperText>
          </div>

          <div className="btn-form-footer btn-footer-login">
            <Button
              className="login-button add-btn"
              color="primary"
              type="submit"
              disabled={isLoading}
            >
              <span className="add-btn-text">enter</span>
            </Button>
          </div>
        </div>
        <div>
          <img
            style={{ maxWidth: '500px' }}
            src={'/pictures/logo_university_centrum.jpg'}
          />
        </div>
      </div>
    </form>
  );
};

export default ResetPasswordPage;
