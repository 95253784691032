import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { useAdmin } from '../../hooks/useAdmin';
import { useApiError } from '../../hooks/useApiError';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { useCreateInvitationMutation } from '../../store/api/invitation.api';

interface FormValues {
  email: string;
}

const InviteUserPage: React.FC = () => {
  useAdmin();
  useBreadcrumb([{ path: '/invite-user', name: 'Invite new user' }]);
  const [createInvitation, { isLoading, error, isSuccess }] =
    useCreateInvitationMutation();
  const formik = useFormik<FormValues>({
    initialValues: { email: '' },
    validationSchema: yup.object({
      email: yup.string().email().required(),
    }),
    onSubmit: (values) => createInvitation(values),
  });

  useApiError(error);
  useEffect(() => {
    if (isSuccess) {
      toast.success('Invitation created');
      formik.resetForm();
    }
  }, [isSuccess]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <Helmet title="Invite new user" />
      <div>
        <div className="flex space-between div-btn-header"></div>

        <div className="width25">
          <div>
            <h2 className={'h2-login-title'}>send invitation to new user</h2>
          </div>
          <div className="login-inputs">
            <TextField
              id="email"
              className="width100"
              label="email"
              margin="normal"
              type="email"
              variant="standard"
              onChange={formik.handleChange}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              disabled={isLoading}
            />
          </div>

          <div className="btn-form-footer btn-footer-login">
            <Button
              className="login-button add-btn"
              color="primary"
              type="submit"
              disabled={isLoading}
            >
              <span className="add-btn-text">enter</span>
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default InviteUserPage;
