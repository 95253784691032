import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import React, { ReactNode, useState } from 'react';

import styles from './help-popup.module.scss';

type Props = {
  children: ReactNode;
};

const HelpPopupComponent: React.FC<Props> = ({ children }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={styles.wrapper}>
      <Button
        className={styles.helpBtn}
        onClick={() => setOpen(true)}
        variant="contained"
        startIcon={<QuestionMarkIcon className={styles.btnIcon} />}
      >
        <Typography color="action">Help</Typography>
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle id="alert-dialog-title">Help</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button
            className="login-button add-btn"
            type="button"
            onClick={() => setOpen(false)}
          >
            cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HelpPopupComponent;
