import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { Helmet } from 'react-helmet';
import * as yup from 'yup';

import { useApiError } from '../../hooks/useApiError';
import { useForgotPasswordMutation } from '../../store/api/auth.api';

interface FormValues {
  email: string;
}

const ForgotPasswordPage: React.FC = () => {
  const [forgotPassword, { isLoading, error, isSuccess }] =
    useForgotPasswordMutation();
  const formik = useFormik<FormValues>({
    initialValues: { email: '' },
    validationSchema: yup.object({
      email: yup.string().email().required(),
    }),
    onSubmit: (values) => forgotPassword(values),
  });
  useApiError(error);
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex space-between login-main-container">
        <Helmet title="Forgot password" />
        <div className="login-container ">
          {isSuccess ? (
            <div>
              <h2 className={'h2-login-title'}>hello</h2>
              <h2 className={'h2-login-info'}>
                message sent, <br />
                check your inbox
              </h2>
            </div>
          ) : (
            <>
              <div>
                <h2 className={'h2-login-title'}>hello</h2>
                <h2 className={'h2-login-info'}>
                  send reset password <br />
                  link
                </h2>
              </div>

              <div className="login-inputs">
                <TextField
                  id="email"
                  className="width100"
                  label="email"
                  margin="normal"
                  type="email"
                  variant="standard"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  disabled={isLoading}
                />
              </div>

              <div className="btn-form-footer btn-footer-login">
                <Button
                  className="login-button add-btn"
                  color="primary"
                  type="submit"
                  disabled={isLoading}
                >
                  <span className="add-btn-text">enter</span>
                </Button>
              </div>
            </>
          )}
        </div>
        <div>
          <img
            style={{ maxWidth: '500px' }}
            src={'/pictures/logo_university_centrum.jpg'}
          />
        </div>
      </div>
    </form>
  );
};

export default ForgotPasswordPage;
