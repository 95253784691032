import { MultiSelectField } from '@daisy/daisy-common';
import {
  Autocomplete,
  Chip,
  createFilterOptions,
  FormControl,
  FormHelperText,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';

import LabelNodeComponent from './label-node.component';

interface Props extends MultiSelectField {
  error: boolean;
  helperText: string;
  value: string;
  onChange: (field: string, value: string | string[]) => void;
}

const filter = createFilterOptions<any>(); // eslint-disable-line

const MultiSelectFieldComponent: React.FC<Props> = (props) => {
  const [value, setValue] = useState<any>(props.value ?? []); // eslint-disable-line

  return (
    <FormControl
      variant="standard"
      sx={{ mb: 3, minWidth: 1 / 1, display: 'block' }}
      error={props.error}
    >
      <Autocomplete
        value={value}
        onChange={(_event, newValue) => {
          setValue(newValue);
          props.onChange(props.name, newValue);
        }}
        multiple
        id={props.name}
        options={props.values}
        fullWidth
        freeSolo={props.extendable}
        disablePortal
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some(
            (option) => inputValue === option.title,
          );
          if (inputValue !== '' && !isExisting) {
            filtered.push(inputValue);
          }
          return filtered;
        }}
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            /* eslint-disable */
            <Chip
              {...getTagProps({ index })}
              variant="outlined"
              label={option}
            />
            /* eslint-enable */
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={
              <LabelNodeComponent
                title={props.label}
                description={props.description}
              />
            }
            placeholder="Search"
          />
        )}
      />
      {props.error && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
};

export default MultiSelectFieldComponent;
