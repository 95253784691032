import { FieldType } from '@daisy/daisy-common';
import React from 'react';

import CheckboxFieldComponent from './checkbox-field.component';
import DateFieldComponent from './date-field.component';
import FileFieldComponent from './file-field.component';
import InventoryNumberFieldComponent from './inventory-number-field.component';
import MultiSelectFieldComponent from './multi-select-field.component';
import MultiSelectWithValueFieldComponent from './multi-select-field-with-value.component';
import NumberFieldComponent from './number-field.component';
import RadioFieldComponent from './radio-field.component';
import RelationFieldComponent from './relation-field.component';
import SelectFieldComponent from './select-field.component';
import TextAreaFieldComponent from './text-area-field.component';
import TextFieldComponent from './text-field.component';

/* eslint-disable */
export const ComponentsMap = (props: any) => {
  switch (props.type) {
    case FieldType.TEXT_FIELD:
      return <TextFieldComponent {...props} />;
    case FieldType.NUMBER_FIELD:
      return <NumberFieldComponent {...props} />;
    case FieldType.TEXT_AREA_FIELD:
      return <TextAreaFieldComponent {...props} />;
    case FieldType.CHECKBOX_FIELD:
      return <CheckboxFieldComponent {...props} />;
    case FieldType.RADIO_FIELD:
      return <RadioFieldComponent {...props} />;
    case FieldType.SELECT_FIELD:
      return <SelectFieldComponent {...props} />;
    case FieldType.MULTI_SELECT_FIELD:
      return <MultiSelectFieldComponent {...props} />;
    case FieldType.MULTI_SELECT_FIELD_WITH_VALUES:
      return <MultiSelectWithValueFieldComponent {...props} />;
    case FieldType.RELATION_FIELD:
      return <RelationFieldComponent {...props} />;
    case FieldType.IMAGE_FIELD:
      return <FileFieldComponent {...props} />;
    case FieldType.FILE_FIELD:
      return <FileFieldComponent {...props} />;
    case FieldType.DATE_FIELD:
      return <DateFieldComponent {...props} />;
    case FieldType.INVENTORY_NUMBER_FIELD:
      return <InventoryNumberFieldComponent {...props} />;
    default:
      return <></>;
  }
};
/* eslint-enable */
