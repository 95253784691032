import { Mission } from '@daisy/daisy-common';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

export const useReadOnlyMission = (mission?: Mission) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (mission && mission.readOnly) {
      navigate(-1);
      toast.error('This expedition is readonly');
    }
  }, [mission]);
};
