import { FieldType, MultiSelectField, SelectField } from '@daisy/daisy-common';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { FormikHandlers } from 'formik';
import React from 'react';

import LabelNodeComponent from './label-node.component';

interface SelectFieldProps extends SelectField {
  error: boolean;
  helperText: string;
  value: string;
  onChange: FormikHandlers['handleChange'];
}

interface MultiSelectFieldProps extends MultiSelectField {
  error: boolean;
  helperText: string;
  value: string;
  onChange: FormikHandlers['handleChange'];
}

const SelectFieldComponent: React.FC<
  SelectFieldProps | MultiSelectFieldProps
> = (props) => {
  return (
    <FormControl
      variant="standard"
      sx={{ mb: 3, minWidth: 1 / 1, display: 'block' }}
      error={props.error}
    >
      <InputLabel id={props.name}>
        <LabelNodeComponent
          title={props.label}
          description={props.description}
        />
      </InputLabel>
      <Select
        labelId={props.name}
        value={props.value}
        onChange={props.onChange}
        name={props.name}
        multiple={props.type === FieldType.MULTI_SELECT_FIELD}
        label={
          <LabelNodeComponent
            title={props.label}
            description={props.description}
          />
        }
        sx={{ pr: 3, width: 1 / 1 }}
      >
        {/* <MenuItem value="" hidden disabled></MenuItem> */}
        {props.values.map((value) => (
          <MenuItem value={value} key={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
      {props.error && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectFieldComponent;
