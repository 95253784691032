import {
  Field,
  FieldType,
  MultiSelectField,
  MultiSelectWithValuesField,
  RadioField,
  ValuesType,
} from '@daisy/daisy-common';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { FastField, FieldArray, getIn, useFormikContext } from 'formik';
import React, { ChangeEvent } from 'react';

import TextInputComponent from '../common/configurator-inputs/text-input.component';

const RadioOrSelectFieldFormComponent: React.FC = () => {
  const formik = useFormikContext<Field>();
  return (
    <FieldArray name="values">
      {(arrayHelpers) => (
        <Box sx={{ position: 'relative' }}>
          <Typography variant="h6">
            Options
            <FormHelperText
              sx={{
                left: 0,
                right: 'unset',
                position: 'static !important',
              }}
              style={{
                textAlign: 'left',
              }}
              error={!(formik.values as any).values?.length} // eslint-disable-line
            >
              Add at least one option
            </FormHelperText>
          </Typography>
          <div
            className="width100 flex"
            style={{
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              background: '#fff',
              marginTop: '-62px',
              paddingBottom: '30px',
            }}
          >
            <Button
              className={'new-mission-btn'}
              type="button"
              onClick={() =>
                arrayHelpers.push({
                  label: '',
                  value: '',
                })
              }
            >
              <span className={'add-btn-text'}>add value</span>
            </Button>
          </div>
          {((formik.values as RadioField).values || []).map(
            (value, currentIndex) => {
              const touchedLabel = getIn(
                formik.touched,
                `values[${currentIndex}]`,
              );
              const errorLabel = getIn(
                formik.errors,
                `values[${currentIndex}]`,
              );
              return (
                <Box key={`values[${currentIndex}]`}>
                  <FormControl
                    variant="standard"
                    sx={{ mb: 3, minWidth: 2 / 3 }}
                  >
                    <FastField
                      component={TextInputComponent}
                      name={`values[${currentIndex}]`}
                      label="Option"
                      variant="standard"
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        formik.handleChange(e);
                        formik.setFieldValue(
                          `values[${currentIndex}].value`,
                          e.target.value.replace(' ', '_').toUpperCase(),
                        );
                      }}
                      style={{ width: 'calc(100% - 32px)' }}
                      error={touchedLabel && !!errorLabel}
                      helperText={
                        touchedLabel && !!errorLabel ? errorLabel : ''
                      }
                    />
                  </FormControl>
                  <Button
                    type="button"
                    onClick={() => arrayHelpers.remove(currentIndex)}
                    className="trash-btn"
                    style={{ marginTop: '18px' }}
                  >
                    <DeleteIcon />
                  </Button>
                </Box>
              );
            },
          )}
          <Divider sx={{ mb: 3 }} />
          {(formik.values.type === FieldType.MULTI_SELECT_FIELD ||
            formik.values.type ===
              FieldType.MULTI_SELECT_FIELD_WITH_VALUES) && (
            <Box>
              <FormControl
                variant="standard"
                sx={{ mb: 3, display: 'block', minWidth: '500px' }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      id="extendable"
                      name="extendable"
                      checked={!!(formik.values as MultiSelectField).extendable}
                      onChange={(_e, checked) =>
                        formik.setFieldValue('extendable', checked)
                      }
                    />
                  }
                  label="Is this field extendable?"
                />
              </FormControl>
            </Box>
          )}
          {formik.values.type === FieldType.MULTI_SELECT_FIELD_WITH_VALUES && (
            <Box>
              <FormControl
                variant="standard"
                sx={{ mb: 3, display: 'block', minWidth: '500px' }}
                error={
                  (formik.touched as any).valueTypes && // eslint-disable-line
                  !(!formik.errors as any).valueTypes // eslint-disable-line
                }
              >
                <InputLabel id="valueTypes">Value type</InputLabel>
                <Select
                  labelId="valueTypes"
                  name="valueTypes"
                  sx={{ minWidth: 1 / 1 }}
                  value={
                    (formik.values as MultiSelectWithValuesField).valueTypes
                  }
                  onChange={formik.handleChange}
                >
                  <MenuItem value=""></MenuItem>
                  {Object.values(ValuesType).map((val) => (
                    <MenuItem value={val} key={val}>
                      {val}
                    </MenuItem>
                  ))}
                </Select>
                {/* eslint-disable */}
                <FormHelperText
                  error={
                    (formik.touched as any).valueTypes &&
                    !(!formik.errors as any).valueTypes
                  }
                >
                  {(formik.touched as any).valueTypes &&
                  !(!formik.errors as any).valueTypes
                    ? (formik.errors as any).valueTypes
                    : ''}
                </FormHelperText>
                {/* eslint-enable */}
              </FormControl>
            </Box>
          )}
          <FormControl
            variant="standard"
            sx={{ mb: 3, display: 'block', minWidth: '500px' }}
          >
            <InputLabel id="default">Default option</InputLabel>
            <Select
              labelId="default"
              value={
                (formik.values.type === FieldType.MULTI_SELECT_FIELD ||
                  formik.values.type ===
                    FieldType.MULTI_SELECT_FIELD_WITH_VALUES) &&
                !Array.isArray(formik.values.default)
                  ? [formik.values.default]
                  : formik.values.default
              }
              onChange={formik.handleChange}
              name="default"
              label="Default option"
              placeholder="Select default option"
              multiple={formik.values.type === FieldType.MULTI_SELECT_FIELD}
              sx={{ pr: 3, minWidth: 1 / 1 }}
            >
              <MenuItem value="" hidden disabled></MenuItem>
              {((formik.values as RadioField).values || []).map((current) => (
                <MenuItem value={current} key={`default-${current}`}>
                  {current}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
    </FieldArray>
  );
};

export default RadioOrSelectFieldFormComponent;
