import { MissionUsers, UserRole } from '@daisy/daisy-common';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import { useApiError } from '../../hooks/useApiError';
import { useAddUpdateMissionUserMutation } from '../../store/api/mission.api';

type Props = {
  setModalVisible: (
    value: React.SetStateAction<Record<string, boolean>>,
  ) => void;
  modalVisible: Record<string, boolean>;
  user: MissionUsers;
  missionId: string;
};

interface FormData {
  userId: string;
  userRole: UserRole;
}

const UpdateRoleDialogComponent: React.FC<Props> = ({
  setModalVisible,
  modalVisible,
  user,
  missionId,
}) => {
  const [addUpdateMissionUser, { error, isSuccess }] =
    useAddUpdateMissionUserMutation();
  const formik = useFormik<FormData>({
    initialValues: {
      userId: user.user.id,
      userRole: user.role,
    },
    onSubmit: (values) => {
      addUpdateMissionUser({ missionId, missionUser: values });
      setModalVisible({
        ...modalVisible,
        [user.id]: false,
      });
    },
  });
  useEffect(() => {
    if (isSuccess) {
      toast.success('User role updated');
    }
  }, [isSuccess]);
  useApiError(error);
  return (
    <Dialog
      open={modalVisible[user.id] || false}
      onClose={() =>
        setModalVisible({
          ...modalVisible,
          [user.id]: false,
        })
      }
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="alert-dialog-title">Update role</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormControl variant="standard" sx={{ m: 1, minWidth: 320 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Role
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                value={formik.values.userRole}
                onChange={formik.handleChange}
                name="userRole"
                label="Role"
                sx={{ pr: 3 }}
              >
                {Object.values(UserRole).map((role) => (
                  <MenuItem value={role} key={role}>
                    {role.replace('_', ' ')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="login-button add-btn"
            type="button"
            onClick={() =>
              setModalVisible({
                ...modalVisible,
                [user.id]: false,
              })
            }
          >
            cancel
          </Button>
          <Button className="login-button add-btn" type="submit" autoFocus>
            save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateRoleDialogComponent;
