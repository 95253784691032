import {
  SearchParams,
  SearchResponseInterface,
  urlGenerator,
} from '@daisy/daisy-common';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const searchApi = createApi({
  reducerPath: 'searchApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/search' }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    searchAll: builder.query<
      SearchResponseInterface<Record<string, string | string[]>>,
      SearchParams
    >({
      query: ({ missionId, index, params }) => ({
        url: urlGenerator(
          `/${missionId}/${index}`,
          `${window.location.protocol}//${window.location.host}`,
          params,
        ),
        credentials: 'include',
      }),
    }),
    getLabels: builder.query<
      Record<string, [string, string][]>,
      { missionId: string; indexes: string[] }
    >({
      query: ({ missionId, indexes }) => ({
        url: urlGenerator(
          `/labels/${missionId}`,
          `${window.location.protocol}//${window.location.host}`,
          indexes.map((idx) => ['i', idx]),
        ),
        credentials: 'include',
      }),
    }),
    getPrevious: builder.query<
      { prev: string },
      {
        missionId: string;
        index: string;
        itemId: string;
        searchParams: [string, string][];
      }
    >({
      query: ({ missionId, index, itemId, searchParams }) => ({
        url: urlGenerator(
          `/${missionId}/${index}/${itemId}/prev`,
          `${window.location.protocol}//${window.location.host}`,
          searchParams,
        ),
        credentials: 'include',
      }),
    }),
    getNext: builder.query<
      { next: string },
      {
        missionId: string;
        index: string;
        itemId: string;
        searchParams: [string, string][];
      }
    >({
      query: ({ missionId, index, itemId, searchParams }) => ({
        url: urlGenerator(
          `/${missionId}/${index}/${itemId}/next`,
          `${window.location.protocol}//${window.location.host}`,
          searchParams,
        ),
        credentials: 'include',
      }),
    }),
  }),
});

export const {
  useSearchAllQuery,
  useGetLabelsQuery,
  useGetPreviousQuery,
  useGetNextQuery,
} = searchApi;
