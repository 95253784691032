import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { useApiError } from '../../hooks/useApiError';
import { useGetGisTablesQuery } from '../../store/api/mission-config.api';
import {
  selectGisTables,
  selectMissionDetails,
  setGisTables,
} from '../../store/features/mission-configurator';
import SpinnerComponent from '../common/spinner/spinner.component';

const GisLayers: React.FC = () => {
  const value = useSelector(selectGisTables);
  const dispatch = useDispatch();
  const setValue = (value: string[]) => {
    dispatch(setGisTables(value));
  };
  const missionDetails = useSelector(selectMissionDetails);
  const { error, isLoading, data } = useGetGisTablesQuery(missionDetails.name);
  useApiError(error);
  return isLoading ? (
    <div className="width100 flex center" style={{ marginTop: '2%' }}>
      <SpinnerComponent />
    </div>
  ) : (
    <FormControl
      variant="standard"
      sx={{ mb: 3, display: 'block', minWidth: '500px' }}
    >
      <InputLabel id="gis-tables-label">GIS Layers</InputLabel>
      <Select
        labelId="gis-tables-label"
        id="gis-tables"
        multiple
        variant="standard"
        value={value}
        onChange={(e) =>
          setValue(
            Array.isArray(e.target.value) ? e.target.value : [e.target.value],
          )
        }
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        sx={{ width: 1 / 1 }}
      >
        {data?.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default GisLayers;
