import './login.css';

import { NodeType } from '@daisy/daisy-common';
import { Button, FormHelperText, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import { useLoginMutation } from '../../store/api/auth.api';
import { selectNodeConfig } from '../../store/features/config';

interface FormValues {
  email: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const [login, { error, isLoading }] = useLoginMutation();
  const { nodeType, emailEnabled } = useSelector(selectNodeConfig);
  const formik = useFormik<FormValues>({
    initialValues: { email: '', password: '' },
    validationSchema: yup.object({
      email: yup.string().email().required(),
      password: yup.string().required(),
    }),
    onSubmit: (values) => login(values),
  });
  useEffect(() => {
    if (error) {
      const message = (error as { data: { message: string } }).data.message;
      if (message === 'Confirm your email first') {
        navigate('/email-confirm-needed');
      }
    }
  }, [error]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <Helmet title="Login" />
      <div className="flex space-between login-main-container">
        <div className="login-container ">
          <div>
            <h2 className={'h2-login-title'}>hello</h2>
            <h2 className={'h2-login-info'}>
              log in to view, add, edit <br />
              or delete records
            </h2>
          </div>

          <div style={{ width: '300px', color: 'red' }}>
            {error ? 'Invalid login or password' : undefined}
          </div>

          <div className="login-inputs">
            <TextField
              id="email"
              className="width100"
              label="login"
              margin="normal"
              type="email"
              variant="standard"
              onChange={formik.handleChange}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              disabled={isLoading}
            />
          </div>
          <div className="login-inputs">
            <TextField
              id="password"
              className="width100"
              label="password"
              type="password"
              margin="normal"
              variant="standard"
              onChange={formik.handleChange}
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              disabled={isLoading}
            />
            <FormHelperText id="component-helper-text">
              use minimum 8 signs without spaces or special characters
            </FormHelperText>
            {nodeType === NodeType.MASTER && emailEnabled && (
              <FormHelperText id="component-helper-text">
                <Link to="/forgot-password">forgot password</Link>
              </FormHelperText>
            )}
            {nodeType !== NodeType.MASTER && (
              <FormHelperText id="component-helper-text">
                <Link to="/register">register</Link>
              </FormHelperText>
            )}
          </div>

          <div className="btn-form-footer btn-footer-login">
            <Button
              className="login-button add-btn"
              color="primary"
              type="submit"
              disabled={isLoading}
            >
              <span className="add-btn-text">enter</span>
            </Button>
          </div>
        </div>
        <div>
          <img
            style={{ maxWidth: '500px' }}
            src={'/pictures/logo_university_centrum.jpg'}
          />
        </div>
      </div>
    </form>
  );
};

export default LoginPage;
