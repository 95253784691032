import { TextField as TextFieldInterface } from '@daisy/daisy-common';
import { FormControl, TextField } from '@mui/material';
import { FormikHandlers } from 'formik';
import React from 'react';

import LabelNodeComponent from './label-node.component';

interface Props extends TextFieldInterface {
  error: boolean;
  helperText: string;
  value: string;
  onChange: FormikHandlers['handleChange'];
}

const TextFieldComponent: React.FC<Props> = (props: Props) => {
  const inputProps: Record<string, string | number> = {};
  if (props.minLength) {
    inputProps.minLength = props.minLength;
  }
  if (props.maxLength) {
    inputProps.maxLength = props.maxLength;
  }
  return (
    <FormControl variant="standard" sx={{ mb: 3, display: 'block' }}>
      <TextField
        id={props.name.replaceAll(' ', '_')}
        name={props.name}
        label={
          <LabelNodeComponent
            title={props.label}
            description={props.description}
          />
        }
        sx={{ minWidth: 1 / 1 }}
        error={props.error}
        value={props.value}
        variant="standard"
        onChange={props.onChange}
        placeholder={props.placeholder}
        inputProps={inputProps}
        helperText={props.helperText}
      />
    </FormControl>
  );
};

export default TextFieldComponent;
