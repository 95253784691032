import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { List } from 'antd';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import SpinnerComponent from '../../components/common/spinner/spinner.component';
import RegisterFormComponent from '../../components/register-form/register-form.component';
import { useAdmin } from '../../hooks/useAdmin';
import { useApiError } from '../../hooks/useApiError';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { useRegisterUserMutation } from '../../store/api/auth.api';
import { useGetUsersQuery, userApi } from '../../store/api/user.api';

interface FormValues {
  email: string;
  password: string;
  confirmPassword: string;
  name: string;
}

const ManageUsersListPage: React.FC = () => {
  useAdmin();
  useBreadcrumb([{ name: 'Manage users', path: '/manage-users' }]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error, isLoading, data } = useGetUsersQuery();
  const [open, setOpen] = useState(false);
  useApiError(error);
  const [register, registerResult] = useRegisterUserMutation();
  useApiError(registerResult.error);
  const formik = useFormik<FormValues>({
    initialValues: { email: '', password: '', confirmPassword: '', name: '' },
    validationSchema: yup.object({
      email: yup.string().email().required(),
      name: yup.string().required().label('full name'),
      password: yup.string().required(),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords do not match!')
        .required()
        .label('confirm password'),
    }),
    onSubmit: (values) =>
      register(values)
        .unwrap()
        .then(() => {
          dispatch(userApi.util.invalidateTags(['Users']));
          setOpen(false);
        })
        .catch((err) => toast.error(err)),
  });
  return (
    <div>
      <Helmet title="Manage users" />
      <div className="flex space-between div-btn-header">
        <h2 className="h2-login-info">Manage users</h2>
        <Button className={'new-mission-btn'} onClick={() => setOpen(true)}>
          <span className={'add-btn-text'}>add new user</span>
        </Button>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle id="alert-dialog-title">Add new user</DialogTitle>
            <DialogContent>
              <RegisterFormComponent
                formik={formik}
                isLoading={isLoading}
                closeCallback={() => setOpen(false)}
              />
            </DialogContent>
          </form>
        </Dialog>
      </div>

      <div className="flex width100">
        <div className="flex space-around width100">
          {isLoading ? (
            <SpinnerComponent />
          ) : (
            <List
              className="width100"
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(user) => (
                <List.Item className="flex space-between">
                  {user.name}
                  <Button
                    onClick={() => navigate(`/manage-users/${user.id}`)}
                    className="login-button add-btn"
                  >
                    Edit
                  </Button>
                </List.Item>
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageUsersListPage;
