import {
  FieldType,
  ItemData,
  MediaType,
  MultiValue,
  ValuesType,
  isBoolean,
} from '@daisy/daisy-common';
import { Button, Divider, FormControl } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { ComponentsMap } from '../../components/common/inputs/components-map';
import MapComponent from '../../components/common/map/map.component';
import SpinnerComponent from '../../components/common/spinner/spinner.component';
import FilesWrapperComponent from '../../components/files-wrapper/files-wrapper.component';
import ImagesWrapperComponent from '../../components/images-wrapper/images-wrapper.component';
import { useApiError } from '../../hooks/useApiError';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { useReadOnlyMission } from '../../hooks/useReadOnlyMission';
import {
  useDeleteItemMediaMutation,
  useDeleteItemMutation,
  useGetItemByIdQuery,
  useUpdateItemMutation,
} from '../../store/api/item.api';
import { useGetMissionByIdQuery } from '../../store/api/mission.api';

const EntityEditPage: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const missionQuery = useGetMissionByIdQuery(params.id ?? '');
  const itemQuery = useGetItemByIdQuery({
    missionId: params.id ?? '',
    itemId: params.item ?? '',
  });
  const [deleteEntityMutation] = useDeleteItemMutation();
  const [updateItem] = useUpdateItemMutation();
  const [deleteItemMedia, deleteItemMediaResult] = useDeleteItemMediaMutation();
  const getEntity = useCallback(
    () =>
      missionQuery.data?.missionConfig.config.entities.find(
        (ent) => ent.id === params.entity,
      ),
    [missionQuery.data],
  );
  useReadOnlyMission(missionQuery.data);
  useApiError(missionQuery.error);
  useApiError(itemQuery.error);
  useApiError(deleteItemMediaResult.error);
  useBreadcrumb([
    { name: 'Expeditions', path: '/expeditions' },
    {
      name: missionQuery.data?.name || '',
      path: `/${params.id || ''}`,
    },
    {
      name: getEntity()?.name || '',
      path: `/${params.entity || ''}`,
    },
    {
      name: itemQuery.data?.id || '',
      path: `/${params.item || ''}`,
    },
    {
      name: 'Edit',
      path: '/edit',
    },
  ]);
  const generateValidation = () => {
    const validation: Record<string, yup.AnySchema> = {};
    (getEntity()?.fields ?? []).map((field) => {
      if (
        field.type === FieldType.FILE_FIELD ||
        field.type === FieldType.IMAGE_FIELD
      ) {
        validation[field.name] = field.required
          ? yup.mixed().required()
          : yup.mixed();
      } else if (field.type === FieldType.CHECKBOX_FIELD) {
        validation[field.name] = field.required
          ? yup.boolean().required()
          : yup.boolean();
      } else if (field.type === FieldType.MULTI_SELECT_FIELD) {
        validation[field.name] = field.required
          ? yup.array().of(yup.string()).min(1)
          : yup.array().of(yup.string());
      } else if (field.type === FieldType.MULTI_SELECT_FIELD_WITH_VALUES) {
        const item = yup.object().shape({
          item: yup.string().required('Required!'),
          value:
            field.valueTypes === ValuesType.TEXT
              ? yup.string().required('Required!')
              : yup.number().required('Required!'),
        });
        validation[field.name] = field.required
          ? yup.array().of(item).min(1)
          : yup.array().of(item);
      } else if (field.type === FieldType.RELATION_FIELD) {
        const multi = field.required
          ? yup.array().of(yup.string()).min(1)
          : yup.array().of(yup.string());
        const single = field.required ? yup.string().required() : yup.string();
        validation[field.name] = field.multi ? multi : single;
      } else if (field.type === FieldType.NUMBER_FIELD) {
        validation[field.name] = field.required
          ? yup.number().required()
          : yup.number();
      } else if (field.type === FieldType.INVENTORY_NUMBER_FIELD) {
        if (field.valueTypes === ValuesType.NUMBER) {
          validation[field.name] = field.required
            ? yup.number().required()
            : yup.number();
        } else if (field.required) {
          validation[field.name] = yup.string().required();
        }
      } else {
        validation[field.name] = field.required
          ? yup.string().required()
          : yup.string();
      }
    });
    return validation;
  };
  const onDeleteItemMedia = (mediaId: string) => {
    deleteItemMedia({
      mediaId,
      missionId: params.id ?? '',
      itemId: params.item ?? '',
    });
  };
  return (
    <div style={{ marginBottom: '80px' }}>
      {missionQuery.isLoading && itemQuery.isLoading && !itemQuery.data ? (
        <SpinnerComponent />
      ) : (
        <>
          <Helmet title={`Update ${getEntity()?.name || ''}`} />
          <div
            className="flex space-between div-btn-header"
            style={{ marginBottom: '2%' }}
          >
            <h2 className="h2-login-info">Edit {getEntity()?.name || ''}</h2>
            <Button
              className={'new-mission-btn'}
              onClick={() => {
                deleteEntityMutation({
                  missionId: params.id ?? '',
                  itemId: params.item ?? '',
                })
                  .unwrap()
                  .then((data) =>
                    navigate(
                      `/expeditions/${params.id || ''}/${params.entity || ''}/${
                        params.entity || ''
                      }/${data}`,
                    ),
                  )
                  .catch((error) => {
                    const message = (error as { data: { message: string } })
                      .data.message;
                    toast.error(message);
                  });
              }}
            >
              <span className={'add-btn-text'}>delete</span>
            </Button>
          </div>
          <Divider sx={{ mb: 3 }} />
          <div className="width100">
            <Formik
              initialValues={itemQuery.data?.content ?? ({} as ItemData)}
              enableReinitialize={true}
              validationSchema={yup.object(generateValidation())}
              validateOnBlur={false}
              validateOnChange={false}
              validateOnMount={false}
              onSubmit={(values) => {
                const fd = new FormData();
                const data: [
                  string,
                  string | string[] | boolean | File | File[] | MultiValue[],
                ][] = [];
                Object.entries(values).forEach(([key, value]) => {
                  if (value || value === false) {
                    data.push([key, value]);
                  }
                });
                for (const [key, value] of data) {
                  if (Array.isArray(value)) {
                    (value as string[]).forEach((v) =>
                      (v as any).item // eslint-disable-line
                        ? fd.append(key, JSON.stringify(v))
                        : fd.append(key, v),
                    );
                  } else if (isBoolean(value)) {
                    fd.append(key, `${value}`);
                  } else {
                    fd.append(key, value as string | Blob);
                  }
                }
                updateItem({
                  missionId: params.id || '',
                  itemId: params.item ?? '',
                  data: fd,
                })
                  .unwrap()
                  .then(() =>
                    navigate(
                      `/expeditions/${params.id || ''}/${params.entity || ''}/${
                        params.item || ''
                      }`,
                    ),
                  )
                  .catch((error) => {
                    const message = (error as { data: { message: string } })
                      .data.message;
                    toast.error(message);
                  });
              }}
            >
              {(formik) => (
                <Form>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'start',
                    }}
                  >
                    {getEntity()?.fields.map((field, index) => (
                      <div
                        key={index}
                        style={{
                          width: field.width ?? '50%',
                          paddingLeft: '5%',
                        }}
                      >
                        <ComponentsMap
                          key={index}
                          error={
                            formik.touched[field.name] &&
                            !!formik.errors[field.name]
                          }
                          helperText={formik.errors[field.name]}
                          value={formik.values[field.name]}
                          onChange={
                            field.type === FieldType.IMAGE_FIELD ||
                            field.type === FieldType.FILE_FIELD ||
                            field.type === FieldType.MULTI_SELECT_FIELD ||
                            field.type === FieldType.RELATION_FIELD ||
                            field.type === FieldType.CHECKBOX_FIELD ||
                            field.type === FieldType.DATE_FIELD
                              ? formik.setFieldValue
                              : formik.handleChange
                          }
                          missionId={params.id}
                          itemLabel={(field.type === FieldType.RELATION_FIELD
                            ? field.target
                            : []
                          ).map(
                            (tgr) =>
                              missionQuery.data?.missionConfig.config.entities.find(
                                (ent) => ent.id === tgr,
                              )?.label,
                          )}
                          {...field}
                        />
                      </div>
                    ))}
                  </div>
                  {getEntity()?.xField && getEntity()?.yField && (
                    <MapComponent
                      xField={getEntity()?.xField ?? ''}
                      yField={getEntity()?.yField ?? ''}
                    />
                  )}
                  <FormControl
                    variant="standard"
                    sx={{ minWidth: 320 }}
                    fullWidth
                  >
                    <div
                      className="width100 flex"
                      style={{
                        justifyContent: 'flex-end',
                        borderTop: 'solid 1px rgba(0, 0, 0, 0.12)',
                        padding: '20px 10px',
                        zIndex: 1,
                      }}
                    >
                      <Button className={'new-mission-btn'} type="submit">
                        <span className={'add-btn-text'}>submit</span>
                      </Button>
                    </div>
                  </FormControl>
                </Form>
              )}
            </Formik>
          </div>
          <Divider sx={{ mb: 3 }} />
          <ImagesWrapperComponent
            onDelete={onDeleteItemMedia}
            medias={
              itemQuery.data?.medias.filter(
                (media) => media.type !== MediaType.FILE,
              ) ?? []
            }
            missionId={params.id ?? ''}
          />
          <Divider sx={{ mb: 3 }} />
          <FilesWrapperComponent
            onDelete={onDeleteItemMedia}
            files={
              itemQuery.data?.medias.filter(
                (media) => media.type === MediaType.FILE,
              ) ?? []
            }
            missionId={params.id ?? ''}
          />
        </>
      )}
    </div>
  );
};

export default EntityEditPage;
