import { DataEntity } from '@daisy/daisy-common';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { v4 as uuid } from 'uuid';

import TextInputComponent from '../common/configurator-inputs/text-input.component';

interface Props {
  initialValues?: DataEntity;
  onSubmit: (values: DataEntity) => void;
  onCancel: () => void;
}

const EntityFormComponent: React.FC<Props> = ({
  initialValues,
  onSubmit,
  onCancel,
}) => {
  return (
    <Formik
      initialValues={
        initialValues ?? {
          id: uuid(),
          name: '',
          description: '',
          label: '',
          hasPoint: false,
          fields: [],
        }
      }
      onSubmit={(values, formikHelpers) => {
        onSubmit(values);
        formikHelpers.setSubmitting(false);
        formikHelpers.resetForm();
      }}
    >
      {({ errors, values, setFieldValue }) => (
        <Form style={{ paddingTop: '24px' }}>
          <FormControl
            variant="standard"
            sx={{ mb: 3, display: 'block', minWidth: '500px' }}
          >
            <Field
              component={TextInputComponent}
              id="name"
              name="name"
              label="Entity name"
              variant="standard"
              sx={{ width: 1 / 1 }}
              helperText={errors.name}
            />
          </FormControl>
          <FormControl
            variant="standard"
            sx={{ mb: 3, display: 'block', minWidth: '500px' }}
          >
            <Field
              component={TextInputComponent}
              id="description"
              name="description"
              label="Entity description"
              variant="standard"
              sx={{ width: 1 / 1 }}
              helperText={errors.description}
            />
          </FormControl>
          <FormControl variant="standard" sx={{ mb: 3, display: 'block' }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    id="multi"
                    name="hasPoint"
                    checked={values.hasPoint}
                    onChange={(_e, checked) =>
                      setFieldValue('hasPoint', checked)
                    }
                  />
                }
                label="Does this entity have coordinates fields?"
              />
            </FormGroup>
          </FormControl>
          <FormControl
            variant="standard"
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'row',
            }}
          >
            <Button
              className={'new-mission-btn'}
              onClick={onCancel}
              type="button"
            >
              <span className={'add-btn-text'}>cancel</span>
            </Button>
            <Button className={'new-mission-btn'} type="submit">
              <span className={'add-btn-text'}>
                {initialValues ? 'update' : 'create'}
              </span>
            </Button>
          </FormControl>
        </Form>
      )}
    </Formik>
  );
};

export default EntityFormComponent;
