import { MediaType } from '@daisy/daisy-common';
import {
  Button,
  Divider,
  Pagination,
  Skeleton,
  Typography,
} from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import FilesWrapperComponent from '../../components/files-wrapper/files-wrapper.component';
import ImagesWrapperComponent from '../../components/images-wrapper/images-wrapper.component';
import { useApiError } from '../../hooks/useApiError';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { useGetFilesListQuery } from '../../store/api/files.api';
import { useGetMissionByIdQuery } from '../../store/api/mission.api';

const MissionFilesPage: React.FC = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const missionQuery = useGetMissionByIdQuery(params.id || '');
  const itemsQuery = useGetFilesListQuery({
    missionId: params.id || '',
    type: (params.type as MediaType.IMAGE) || MediaType.IMAGE,
    ...Object.fromEntries(searchParams.entries()),
  });
  useApiError(missionQuery.error);
  const navigate = useNavigate();
  useBreadcrumb([
    { name: 'Expeditions', path: '/expeditions' },
    {
      name: missionQuery.data?.name || '',
      path: `/${params.id || ''}`,
    },
    {
      name: params.type || '',
      path: '/files',
    },
  ]);

  return (
    <>
      <div
        className="flex div-btn-header"
        style={{ marginBottom: '2%', justifyContent: 'flex-end' }}
      >
        <Helmet title={'' || ''} />
        {missionQuery.isLoading ? (
          <Skeleton height="50px" variant="rounded" />
        ) : (
          <>
            {!missionQuery.data?.readOnly && (
              <Button
                className={'new-mission-btn'}
                onClick={() =>
                  navigate(`/expeditions/${params.id || ''}/storage/create`)
                }
              >
                <span className={'add-btn-text'}>add</span>
              </Button>
            )}
          </>
        )}
      </div>
      <Divider sx={{ mb: 3 }} />
      <div className="width100">
        {itemsQuery.isLoading ? (
          <Skeleton height="400px" variant="rounded" />
        ) : (
          <>
            {params.type === MediaType.FILE ? (
              <FilesWrapperComponent
                files={itemsQuery.data?.data ?? []}
                missionId={params.id ?? ''}
              />
            ) : (
              <ImagesWrapperComponent
                medias={itemsQuery.data?.data ?? []}
                missionId={params.id ?? ''}
              />
            )}
            {!itemsQuery.data?.count ? (
              <div className="width100 flex center">
                <Typography variant="h4">No records found</Typography>
              </div>
            ) : (
              ''
            )}
            <div className="width100">
              {(itemsQuery.data?.count ?? 0) > 30 && (
                <Pagination
                  count={Math.floor((itemsQuery.data?.count ?? 0) / 30)}
                  color="primary"
                  onChange={(e, page) =>
                    navigate(
                      `/expeditions/${params.id || ''}/${
                        params.entity || ''
                      }?page=${page}`,
                    )
                  }
                />
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MissionFilesPage;
