import { Field, UserRole } from '@daisy/daisy-common';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { List } from 'antd';
import dateformat from 'dateformat';
import React, { useCallback, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ListLabel from '../../components/common/list-label/list-label.component';
import CustomCardComponent from '../../components/custom-card/custom-card.component';
import FacetsComponent from '../../components/facets/facets.component';
import { useApiError } from '../../hooks/useApiError';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { useDeleteItemMutation } from '../../store/api/item.api';
import { useGetMissionByIdQuery } from '../../store/api/mission.api';
import { useSearchAllQuery } from '../../store/api/search.api';
import {
  setCurrentPath,
  setSearchParams as setStoredSearchParams,
} from '../../store/features/list-state';
import { selectUser } from '../../store/features/user';

const EntityHomePage: React.FC = () => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const missionQuery = useGetMissionByIdQuery(params.id || '');
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setStoredSearchParams(null));
    return () => {
      dispatch(setCurrentPath(`${location.pathname}${location.search}`));
      dispatch(setStoredSearchParams([...searchParams.entries()]));
    };
  }, [location]);
  const itemsQuery = useSearchAllQuery({
    missionId: params.id || '',
    index: params.entity || '',
    params: [...searchParams.entries()],
  });
  const [deleteEntityMutation, deleteEntityMutationResult] =
    useDeleteItemMutation();
  useApiError(missionQuery.error);
  useApiError(itemsQuery.error);
  useApiError(deleteEntityMutationResult.error);
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const getEntity = useCallback(
    () =>
      missionQuery.data?.missionConfig.config.entities.find(
        (ent) => ent.id === params.entity,
      ),
    [missionQuery.data],
  );
  useBreadcrumb([
    { name: 'Expeditions', path: '/expeditions' },
    {
      name: missionQuery.data?.name || '',
      path: `/${params.id || ''}`,
    },
    {
      name: getEntity()?.name || '',
      path: `/${params.entity || ''}`,
    },
  ]);
  const handleSearch = () => {
    setSearchParams([
      ...[...searchParams.entries()].filter(([key]) => key !== 'q'),
      ['q', search],
    ]);
  };
  const handleSort = (sort: string) => {
    setSearchParams([
      ...[...searchParams.entries()].filter(([key]) => key !== 'sort'),
      ['sort', sort],
    ]);
  };
  const [search, setSearch] = useState('');
  return (
    <>
      <div
        className="flex space-between div-btn-header"
        style={{ marginBottom: '2%' }}
      >
        <Helmet title={getEntity()?.name || ''} />
        {missionQuery.isLoading ? (
          <Skeleton height="50px" variant="rounded" />
        ) : (
          <>
            <h2 className="h2-login-info">
              {missionQuery.data?.name} - {getEntity()?.name || ''}
            </h2>
            <Box>
              {!missionQuery.data?.readOnly && user && (
                <Button
                  className={'new-mission-btn'}
                  onClick={() =>
                    navigate(
                      `/expeditions/${params.id || ''}/${
                        params.entity || ''
                      }/create`,
                    )
                  }
                >
                  <span className={'add-btn-text'}>add</span>
                </Button>
              )}
              <FormControl variant="standard" sx={{ ml: 3 }}>
                <TextField
                  variant="standard"
                  name="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  label="search"
                  style={{ marginTop: '-16px' }}
                />
              </FormControl>
              <Button
                className={'new-mission-btn'}
                onClick={() => (search ? handleSearch() : null)}
              >
                <span className={'add-btn-text'}>
                  <SearchIcon />
                </span>
              </Button>
              {getEntity()?.xField && getEntity()?.yField && user && (
                <CopyToClipboard
                  text={`${window.location.protocol}//${
                    window.location.hostname
                  }${
                    window.location.port ? `:${window.location.port}` : ''
                  }/api/geojson/${params.entity || ''}`}
                  onCopy={() => toast.success('Copied!')}
                >
                  <Button className={'new-mission-btn'}>
                    <span className={'add-btn-text'}>Get GeoJSON url</span>
                  </Button>
                </CopyToClipboard>
              )}
            </Box>
          </>
        )}
      </div>
      <Divider sx={{ mb: 3 }} />
      <div className="flex width100">
        <div className="width25" style={{ paddingRight: '10px' }}>
          {itemsQuery.isLoading || itemsQuery.isFetching ? (
            <Skeleton height="400px" variant="rounded" />
          ) : (
            <>
              {itemsQuery.data && (
                <Box>
                  <FormControl
                    variant="standard"
                    sx={{ mb: 3, display: 'block', width: 'calc(100% - 10px)' }}
                  >
                    <InputLabel id="sort">Sort</InputLabel>
                    <Select
                      labelId="sort"
                      value={searchParams.get('sort') ?? ''}
                      name="sort"
                      sx={{ pr: 3, minWidth: 1 / 1 }}
                      onChange={(e) => handleSort(e.target.value)}
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value="createdAt:asc">
                        Record created at asc
                      </MenuItem>
                      <MenuItem value="createdAt:desc">
                        Record created at desc
                      </MenuItem>
                      <MenuItem value="updatedAt:asc">
                        Record last updated at at asc
                      </MenuItem>
                      <MenuItem value="updatedAt:desc">
                        Record last updated at at desc
                      </MenuItem>
                      <MenuItem value="lastModifiedBy:asc">
                        Record last modified by asc
                      </MenuItem>
                      <MenuItem value="lastModifiedBy:desc">
                        Record last modified by desc
                      </MenuItem>
                      {getEntity()
                        ?.fields.filter((field) => field.sortable)
                        .map((field) =>
                          ['asc', 'desc'].map((direction) => (
                            <MenuItem
                              value={`${field.name}:${direction}`}
                              key={`${field.name}:${direction}`}
                            >
                              {field.label} {direction}
                            </MenuItem>
                          )),
                        )}
                    </Select>
                  </FormControl>
                </Box>
              )}
              <FacetsComponent
                facets={itemsQuery.data?.facetDistribution ?? {}}
                baseFacets={itemsQuery.data?.baseFacets ?? {}}
                initialValues={Object.fromEntries(
                  Object.keys(itemsQuery.data?.baseFacets ?? {}).map((key) => [
                    key,
                    [...searchParams.entries()]
                      .filter(([paramKey]) => paramKey === key)
                      .map((param) => param[1]),
                  ]),
                )}
              />
            </>
          )}
        </div>
        <div className="width75">
          {itemsQuery.isLoading || itemsQuery.isFetching ? (
            <Skeleton height="400px" variant="rounded" />
          ) : (
            <>
              <div className="width100">
                <List
                  className="width100"
                  itemLayout="horizontal"
                  grid={{
                    gutter: 3,
                    xs: 1,
                    sm: 1,
                    md: 1,
                    lg: 3,
                    xl: 3,
                    xxl: 3,
                  }}
                  dataSource={itemsQuery.data?.hits}
                  renderItem={(record) => (
                    <List.Item>
                      <CustomCardComponent
                        userRole={
                          user?.missions.find(
                            (mis) => mis.mission.id === params.id,
                          )?.role
                        }
                        actions={[
                          {
                            actionName: 'preview',
                            actionHandler: () =>
                              navigate(
                                `/${user ? 'expeditions' : 'open'}/${
                                  params.id || ''
                                }/${params.entity || ''}/${record.id}`,
                              ),
                          },
                          {
                            actionName: 'edit',
                            securityRules: [
                              UserRole.ADMIN,
                              UserRole.EXPEDITION_MANAGER,
                              UserRole.EXPEDITION_PARTICIPANT,
                            ],
                            actionHandler: () =>
                              navigate(
                                `/expeditions/${params.id || ''}/${
                                  params.entity || ''
                                }/${record.id}/edit`,
                              ),
                          },
                          {
                            actionName: 'delete',
                            securityRules: [
                              UserRole.ADMIN,
                              UserRole.EXPEDITION_MANAGER,
                            ],
                            actionHandler: () => {
                              deleteEntityMutation({
                                missionId: params.id ?? '',
                                itemId: record.id as string,
                              })
                                .unwrap()
                                .then(() => itemsQuery.refetch());
                            },
                            actionConfirmationText:
                              'Do you want to delete this record?',
                          },
                        ]}
                        header={
                          <ListLabel
                            labelConfig={
                              getEntity()?.fields.find(
                                (field) =>
                                  field.name === getEntity()?.label ?? '',
                              ) ?? ({} as Field)
                            }
                            value={record[getEntity()?.label ?? ''] as string}
                          />
                        }
                        body={
                          <div className="flex flex-column record-card">
                            <div>
                              last edited by:{' '}
                              <span className={'record-card-description'}>
                                {record.lastModifiedBy}
                              </span>
                            </div>
                            <div>
                              created:{' '}
                              <span className={'record-card-description'}>
                                {dateformat(
                                  record.createdAt as unknown as Date,
                                  'fullDate',
                                )}
                              </span>
                            </div>
                            <div>
                              last modification:{' '}
                              <span className={'record-card-description'}>
                                {dateformat(
                                  record.updatedAt as unknown as Date,
                                  'fullDate',
                                )}
                              </span>
                            </div>
                          </div>
                        }
                        bodyDivContainerClass={'card-missions'}
                        headerOnClick={() =>
                          navigate(
                            `/${user ? 'expeditions' : 'open'}/${
                              params.id || ''
                            }/${params.entity || ''}/${record.id}`,
                          )
                        }
                      />
                    </List.Item>
                  )}
                />
              </div>
              {!itemsQuery.data?.hits.length ? (
                <div className="width100 flex center">
                  <Typography variant="h4">No records found</Typography>
                </div>
              ) : (
                ''
              )}
              <div className="width100">
                {(itemsQuery.data?.estimatedTotalHits ?? 0) > 30 && (
                  <Pagination
                    count={Math.ceil(
                      (itemsQuery.data?.estimatedTotalHits ?? 0) / 30,
                    )}
                    color="primary"
                    page={Number(searchParams.get('page')) ?? 1}
                    onChange={(e, page) =>
                      setSearchParams([
                        ...[...searchParams.entries()].filter(
                          ([key]) => key !== 'page',
                        ),
                        ['page', page.toString()],
                      ])
                    }
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default EntityHomePage;
