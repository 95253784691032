import './spinner.css';

import { Spin } from 'antd';
import React from 'react';

type Props = {
  label?: string;
};

const SpinnerComponent: React.FC<Props> = ({ label }) => {
  return (
    <div>
      <Spin tip={label ?? 'loading...'} size="large" />
    </div>
  );
};

export default SpinnerComponent;
