import { TextField } from '@mui/material';
import React from 'react';

/* eslint-disable */
const TextInputComponent: React.FC<any> = ({ field, ...props }) => {
  /* eslint-enable */
  return <TextField {...props} {...field} />;
};

export default TextInputComponent;
