import { Typography } from '@mui/material';
import React from 'react';
import { FallbackProps } from 'react-error-boundary';

import Logo from '../logo/logo.component';

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <div className="main-app-class">
      <div className="main-app-header-class">
        <div className="header width100 flex space-between">
          <div>
            <Logo />
          </div>
        </div>
      </div>
      <div className="main-app-content-class">
        <div
          className="width100 flex"
          style={{
            height: 'calc(100vh - 96px)',
            margin: '0 20%',
            alignItems: 'center',
          }}
        >
          <div role="alert">
            <Typography variant="h4">Something went wrong:</Typography>
            <Typography variant="body1">
              <pre>{JSON.stringify(error.message)}</pre>
            </Typography>
            <button onClick={resetErrorBoundary}>Try again</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorFallback;
