import {
  Config,
  DataEntity,
  Field,
  MissionDto,
  MissionUserDto,
  SubmissionState,
} from '@daisy/daisy-common';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

interface SubmittedState {
  details: SubmissionState;
  entities: SubmissionState;
  users: SubmissionState;
}

interface MissionCreatorState {
  missionId: string;
  mission: MissionDto;
  config: Config;
  gisTables: string[];
  users: MissionUserDto[];
  step: number;
  submitState: SubmittedState;
}

const initialState: MissionCreatorState = {
  missionId: '',
  mission: {
    name: '',
    description: '',
  },
  config: { entities: [] },
  gisTables: [],
  users: [],
  step: 0,
  submitState: {
    details: SubmissionState.WAITING,
    entities: SubmissionState.WAITING,
    users: SubmissionState.WAITING,
  },
};

export const missionConfiguratorSlice = createSlice({
  name: 'missionCreator',
  initialState,
  reducers: {
    setMissionDetails: (state, action: PayloadAction<MissionDto>) => {
      state.mission = action.payload;
    },
    setConfig: (state, action: PayloadAction<Config>) => {
      state.config = action.payload;
    },
    addEntity: (state, action: PayloadAction<DataEntity>) => {
      state.config.entities = [...state.config.entities, action.payload];
    },
    setGisTables: (state, action: PayloadAction<string[]>) => {
      state.gisTables = action.payload;
    },
    updateEntity: (
      state,
      action: PayloadAction<{ data: DataEntity; id: number }>,
    ) => {
      const list = [...state.config.entities];
      list[action.payload.id] = action.payload.data;
      state.config.entities = list;
    },
    deleteEntity: (state, action: PayloadAction<number>) => {
      const list = [...state.config.entities];
      list.splice(action.payload, 1);
      state.config.entities = list;
    },
    addField: (
      state,
      action: PayloadAction<{ field: Field; entityId: number }>,
    ) => {
      const list = [...state.config.entities];
      list[action.payload.entityId].fields.push(action.payload.field);
      state.config.entities = list;
    },
    setFields: (
      state,
      action: PayloadAction<{ fields: Field[]; entityId: number }>,
    ) => {
      state.config.entities[action.payload.entityId].fields =
        action.payload.fields;
    },
    updateField: (
      state,
      action: PayloadAction<{ field: Field; entityId: number; id: number }>,
    ) => {
      state.config.entities[action.payload.entityId].fields[action.payload.id] =
        action.payload.field;
    },
    deleteField: (
      state,
      action: PayloadAction<{ fieldId: number; entityId: number }>,
    ) => {
      const fields = [...state.config.entities[action.payload.entityId].fields];
      fields.splice(action.payload.fieldId, 1);
      state.config.entities[action.payload.entityId].fields = fields;
    },
    setUsers: (state, action: PayloadAction<MissionUserDto[]>) => {
      state.users = action.payload;
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setSubmitState: (state, action: PayloadAction<SubmittedState>) => {
      state.submitState = action.payload;
    },
    setMissionId: (state, action: PayloadAction<string>) => {
      state.missionId = action.payload;
    },
    resetConfigurator: () => {
      return initialState;
    },
  },
});

export const {
  setMissionDetails,
  setConfig,
  setUsers,
  setStep,
  setGisTables,
  setSubmitState,
  setMissionId,
  resetConfigurator,
  addEntity,
  updateEntity,
  deleteEntity,
  addField,
  setFields,
  updateField,
  deleteField,
} = missionConfiguratorSlice.actions;

export const selectMissionDetails = (state: RootState) =>
  state.missionConfigurator.mission;
export const selectConfig = (state: RootState) =>
  state.missionConfigurator.config;
export const selectGisTables = (state: RootState) =>
  state.missionConfigurator.gisTables;
export const selectEntities = (state: RootState) =>
  state.missionConfigurator.config.entities;
export const selectEntity = (entityId: number) => (state: RootState) =>
  state.missionConfigurator.config.entities[entityId];
export const selectUsers = (state: RootState) =>
  state.missionConfigurator.users;
export const selectFields = (entityId: number) => (state: RootState) =>
  state.missionConfigurator.config.entities[entityId].fields;
export const selectStep = (state: RootState) => state.missionConfigurator.step;
export const selectSubmitState = (state: RootState) =>
  state.missionConfigurator.submitState;
export const selectMissionId = (state: RootState) =>
  state.missionConfigurator.missionId;

export default missionConfiguratorSlice.reducer;
