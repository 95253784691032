import { MissionDto } from '@daisy/daisy-common';
import { Button, FormControl, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import HelpPopupComponent from '../common/help-popup/help-popup.component';

type Props = {
  initialData: MissionDto;
  handleSubmit: (values: MissionDto) => void;
  isUpdate?: boolean;
};

const MissionDetailsFormComponent: React.FC<Props> = ({
  initialData,
  handleSubmit,
  isUpdate,
}) => {
  const formik = useFormik<MissionDto>({
    initialValues: initialData,
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: yup.object({
      name: yup.string().required(),
      description: yup.string(),
    }),
    onSubmit: handleSubmit,
  });
  return (
    <div className="width100">
      <Typography variant="h4" sx={{ mb: 3 }}>
        Expedition Details
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <FormControl variant="standard" sx={{ mb: 3, minWidth: 320 }}>
          <TextField
            id="name"
            name="name"
            label="Expedition name"
            variant="standard"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && !!formik.errors.name}
            helperText={formik.errors.name}
          />
        </FormControl>
        <FormControl variant="standard" sx={{ mb: 3, minWidth: 320 }} fullWidth>
          <TextField
            id="description"
            name="description"
            label="Description"
            variant="standard"
            value={formik.values.description}
            multiline
            rows={5}
            onChange={formik.handleChange}
            error={formik.touched.description && !!formik.errors.description}
            helperText={formik.errors.description}
          />
        </FormControl>
        <FormControl variant="standard" sx={{ mb: 3, minWidth: 320 }} fullWidth>
          <div
            className="width100 flex"
            style={{
              justifyContent: 'space-between',
              position: isUpdate ? 'static' : 'fixed',
              bottom: '0',
              left: '0',
              right: '0',
              background: '#fff',
              borderTop: 'solid 1px rgba(0, 0, 0, 0.12)',
              padding: '20px 10px',
            }}
          >
            <HelpPopupComponent>
              <Typography variant="h4">Expedition details</Typography>
              <Typography variant="body1">
                This section of configurator is responsible for basic expedition
                details.
              </Typography>
              <Typography variant="h6">Name</Typography>
              <Typography variant="body1">
                This field contains expedition name, it&apos;s required.
              </Typography>
              <Typography variant="h6">Description</Typography>
              <Typography variant="body1">
                This field contains expedition description, it&apos;s not
                required, but it&apos;s nice to add brief and introductory
                information here.
              </Typography>
            </HelpPopupComponent>
            <Button className={'new-mission-btn'} type="submit">
              <span className={'add-btn-text'}>
                {isUpdate ? 'update details' : 'next'}
              </span>
            </Button>
          </div>
        </FormControl>
      </form>
    </div>
  );
};

export default MissionDetailsFormComponent;
