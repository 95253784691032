import { User } from '@daisy/daisy-common';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/user' }),
  tagTypes: ['User', 'ManagedUsers', 'Users'],
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    updateUser: builder.mutation<
      User,
      {
        id: string;
        email?: string;
        password?: string;
        name?: string;
        admin?: boolean;
      }
    >({
      query: (body) => ({
        url: `/${body.id}`,
        method: 'PUT',
        credentials: 'include',
        body,
      }),
      invalidatesTags: ['ManagedUsers', 'User'],
    }),
    getUsers: builder.query<User[], void>({
      query: () => '/list',
      providesTags: ['Users'],
    }),
    getUser: builder.query<User, void>({
      query: () => '',
      providesTags: ['User'],
    }),
    getUserById: builder.query<User, string>({
      query: (id) => `/${id}`,
      providesTags: ['ManagedUsers', 'User'],
    }),
  }),
});

export const {
  useUpdateUserMutation,
  useGetUsersQuery,
  useGetUserByIdQuery,
  useGetUserQuery,
} = userApi;
