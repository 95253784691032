import { UserRole } from '@daisy/daisy-common';
import { Button, Chip } from '@mui/material';
import { List } from 'antd';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import AddUserToMissionDialogComponent from '../../components/add-user-to-mission-dialog/add-user-to-mission-dialog.component';
import AdminWrapperComponent from '../../components/common/admin-wrapper/admin-wrapper.component';
import SecureComponent from '../../components/common/secure-component/secure-component.component';
import SpinnerComponent from '../../components/common/spinner/spinner.component';
import CustomCardComponent from '../../components/custom-card/custom-card.component';
import UpdateRoleDialogComponent from '../../components/update-role-dialog/update-role-dialog.component';
import { useApiError } from '../../hooks/useApiError';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { useGetMissionByIdQuery } from '../../store/api/mission.api';
import { selectUser } from '../../store/features/user';

const MissionDetailsPage: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { error, isLoading, data } = useGetMissionByIdQuery(params.id || '');
  const [modalVisible, setModalVisible] = useState<Record<string, boolean>>({});
  const [addUserOpen, setAddUserOpen] = useState(false);
  useBreadcrumb([
    { name: 'Expeditions', path: '/expeditions' },
    { name: data?.name || '', path: `/${params.id || ''}` },
  ]);
  useApiError(error);
  const userData = useSelector(selectUser);
  return (
    <div>
      <Helmet title={data?.name || ''} />
      {isLoading ? (
        <div className="width100 flex center" style={{ marginTop: '2%' }}>
          <SpinnerComponent />
        </div>
      ) : (
        <>
          <div
            className="flex space-between div-btn-header"
            style={{ marginBottom: '2%' }}
          >
            <h2 className="h2-login-info">
              {data?.name} {data?.archived && <Chip label="ARCHIVED" />}
              {data?.readOnly && <Chip label="READ ONLY" />}
            </h2>
            {!data?.readOnly && (
              <AdminWrapperComponent>
                <div>
                  <Button
                    className={'new-mission-btn'}
                    onClick={() => setAddUserOpen(true)}
                  >
                    <span className={'add-btn-text'}>add user</span>
                  </Button>
                  <AddUserToMissionDialogComponent
                    missionId={params.id || ''}
                    open={addUserOpen}
                    setOpen={setAddUserOpen}
                  />
                  <Button
                    className={'new-mission-btn'}
                    onClick={() =>
                      navigate(`/expeditions/${params.id || ''}/edit`)
                    }
                  >
                    <span className={'add-btn-text'}>edit</span>
                  </Button>
                  <Button
                    className={'new-mission-btn'}
                    onClick={() =>
                      navigate(`/expeditions/${params.id || ''}/storage/FILE`)
                    }
                  >
                    <span className={'add-btn-text'}>files</span>
                  </Button>
                  <Button
                    className={'new-mission-btn'}
                    onClick={() =>
                      navigate(`/expeditions/${params.id || ''}/storage/IMAGE`)
                    }
                  >
                    <span className={'add-btn-text'}>images</span>
                  </Button>
                </div>
              </AdminWrapperComponent>
            )}
          </div>
          <div className="width100" style={{ marginBottom: '2%' }}>
            {data?.description && <p>{data?.description}</p>}
          </div>

          <div className="flex width100">
            <div className="flex space-around width100">
              <List
                grid={{
                  gutter: 16,
                  column: 6,
                }}
                className="width100"
                itemLayout="horizontal"
                dataSource={
                  data?.missionConfig ? data?.missionConfig.config.entities : []
                }
                renderItem={(type) => (
                  <CustomCardComponent
                    body={
                      <div className={'card-custom-subtitle records-subtitle'}>
                        {type.name}
                      </div>
                    }
                    bodyDivContainerClass={'records-custom-card'}
                    bodyOnClick={() => {
                      navigate(`/expeditions/${data?.id || ''}/${type.id}`);
                    }}
                  />
                )}
              />
            </div>
          </div>
          <AdminWrapperComponent>
            <h2 className="h2-login-info" style={{ marginTop: '2%' }}>
              Users
            </h2>
            <div className="flex width100">
              <div className="flex space-around width100">
                <List
                  className="width100"
                  itemLayout="horizontal"
                  dataSource={data?.missionUsers || []}
                  renderItem={(user) => (
                    <List.Item className="flex space-between">
                      <span>{user.user.name}</span>
                      <span>Role: {user.role.replace('_', ' ')}</span>
                      {!data?.readOnly && (
                        <SecureComponent
                          roles={[UserRole.ADMIN, UserRole.EXPEDITION_MANAGER]}
                          userRole={
                            data?.missionUsers.find(
                              (item) => item.user.id === userData?.id,
                            )?.role || UserRole.BLOCKED
                          }
                        >
                          <Button
                            onClick={() =>
                              setModalVisible({
                                ...modalVisible,
                                [user.id]: true,
                              })
                            }
                            className="login-button add-btn"
                          >
                            Edit role
                          </Button>
                          <UpdateRoleDialogComponent
                            setModalVisible={setModalVisible}
                            modalVisible={modalVisible}
                            user={user}
                            missionId={data?.id || ''}
                          />
                        </SecureComponent>
                      )}
                    </List.Item>
                  )}
                />
              </div>
            </div>
          </AdminWrapperComponent>
        </>
      )}
    </div>
  );
};

export default MissionDetailsPage;
