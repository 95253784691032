import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import ErrorFallback from './components/common/error-fallback/error-fallback';
import Router from './router';
import { persistor, store } from './store';

const Main: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => window.location.reload()}
        >
          <Router />
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  );
};

export default Main;
