import {
  Field,
  FieldType,
  RelationField,
  ReversRelationStatus,
  ValuesType,
} from '@daisy/daisy-common';
import HelpIcon from '@mui/icons-material/Help';
import {
  Alert,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material';
import { FastField, Form, Formik, getIn } from 'formik';
import React from 'react';
import { v4 } from 'uuid';
import * as yup from 'yup';

import TextInputComponent from '../common/configurator-inputs/text-input.component';
import CheckboxFieldFormComponent from './checkbox-field-form.component';
import FileFieldFormComponent from './file-field-form.component';
import ImageFieldFormComponent from './image-field-form.component';
import InventoryNumberFieldFormComponent from './inventory-number-field-form.component';
import NumberFieldFormComponent from './number-field-form.component';
import RadioOrSelectFieldFormComponent from './radio-or-select-field-form.component';
import RelationFieldFormComponent from './relation-field-form.component';
import TextAreaFieldFormComponent from './text-area-field-form.component';
import TextFieldFormComponent from './text-field-form.component';

interface Props {
  field?: Field;
  isUpdate?: boolean;
  onCancel: () => void;
  onSubmit: (values: Field) => void;
}

const FieldFormComponent: React.FC<Props> = ({
  isUpdate,
  field,
  onCancel,
  onSubmit,
}) => {
  return (
    <Formik
      initialValues={
        field ?? {
          id: v4(),
          type: FieldType.TEXT_FIELD,
          name: '',
          label: '',
          description: '',
          required: false,
          indexed: false,
          sortable: false,
          target: [],
          values: [],
          allowedFormats: [],
          multi: false,
          valueTypes: '',
          prefix: '',
          width: '50%',
          reverse: ReversRelationStatus.SKIPPED,
          reverseTarget: [],
        }
      }
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnMount={true}
      validateOnChange={true}
      validationSchema={yup.object({
        id: yup.string().required('Required!'),
        type: yup.string().required('Required!'),
        name: yup.string().required('Required!'),
        label: yup.string().required('Required!'),
        description: yup.string().required('Required!'),
        required: yup.boolean().required('Required!'),
        indexed: yup.boolean().required('Required!'),
        sortable: yup.boolean().required('Required!'),
        width: yup
          .string()
          .oneOf(['25%', '33%', '50%', '66%', '75%', '100%'])
          .required('Required!'),
        values: yup
          .array()
          .of(yup.string())
          .when('type', {
            is:
              FieldType.MULTI_SELECT_FIELD ||
              FieldType.MULTI_SELECT_FIELD_WITH_VALUES ||
              FieldType.RADIO_FIELD ||
              FieldType.SELECT_FIELD,
            then: (schema) => schema.min(1),
            otherwise: (schema) => schema.optional(),
          }),
        prefix: yup.string().when('type', {
          is: FieldType.INVENTORY_NUMBER_FIELD,
          then: (schema) => schema.required('Required!'),
          otherwise: (schema) => schema.optional(),
        }),
        valueTypes: yup
          .string()
          .optional()
          .when('type', {
            is: FieldType.INVENTORY_NUMBER_FIELD,
            then: (schema) =>
              schema.oneOf(Object.values(ValuesType)).required('Required!'),
          })
          .when('type', {
            is: FieldType.MULTI_SELECT_FIELD_WITH_VALUES,
            then: (schema) =>
              schema.oneOf(Object.values(ValuesType)).required('Required!'),
          }),
        target: yup
          .array()
          .of(yup.string())
          .when('type', {
            is: FieldType.RELATION_FIELD,
            then: (schema) => schema.min(1),
            otherwise: (schema) => schema.optional(),
          }),
        multi: yup.boolean(),
        allowedFormats: yup
          .array()
          .of(yup.string())
          .when('type', {
            is: FieldType.IMAGE_FIELD || FieldType.FILE_FIELD,
            then: (schema) => schema.min(1),
            otherwise: (schema) => schema.optional(),
          }),
        reverse: yup.string().when('type', {
          is: FieldType.RELATION_FIELD,
          then: (schema) => schema.required('Required!'),
          otherwise: (schema) => schema.optional(),
        }),
        reverseTarget: yup
          .array()
          .of(yup.string())
          .when('reverse', {
            is: ReversRelationStatus.PRESENT,
            then: (schema) => schema.min(1),
            otherwise: (schema) => schema.optional(),
          }),
      })}
      onSubmit={(values, helpers) => {
        onSubmit(values as Field);
        helpers.resetForm();
      }}
    >
      {(formik) => {
        if (!formik.values.id) {
          formik.setFieldValue('id', v4());
        }
        return (
          <Form style={{ paddingTop: '24px' }}>
            {(formik.values as RelationField).reverse ===
              ReversRelationStatus.REVERSE && (
              <Alert severity="warning" sx={{ mb: 3 }}>
                This is reverse relation, some options are excluded from edition
              </Alert>
            )}
            <FormControl
              variant="standard"
              sx={{ mb: 3, display: 'block', minWidth: '500px' }}
            >
              <InputLabel id={'type'}>Field type</InputLabel>
              <Select
                labelId="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                name="type"
                label="Field type"
                placeholder="Select field type"
                readOnly={
                  (formik.values as RelationField).reverse ===
                  ReversRelationStatus.REVERSE
                }
                sx={{ pr: 3, minWidth: 1 / 1 }}
              >
                {Object.values(FieldType).map((current) => (
                  <MenuItem value={current} key={`type-${current}`}>
                    {current.replaceAll('_', ' ').toLowerCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="standard"
              sx={{ mb: 3, display: 'block', minWidth: '500px' }}
            >
              <FastField
                component={TextInputComponent}
                id="name"
                name="name"
                label="Field name"
                variant="standard"
                readOnly={
                  (formik.values as RelationField).reverse ===
                  ReversRelationStatus.REVERSE
                }
                onChange={formik.handleChange}
                disabled={isUpdate && !!getIn(formik.initialValues, 'name')}
                sx={{ minWidth: 1 / 1 }}
                error={formik.touched.name && !!formik.errors.name}
                helperText={formik.errors.name ? formik.errors.name : ''}
              />
              {isUpdate && (
                <div
                  style={{
                    position: 'absolute',
                    top: '17px',
                  }}
                >
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                      placement: 'top',
                    }}
                    title={`
                       You can't change field name, it may break data consistency.
                       But name is not visible to the user, you stil can change the rest of the visible parameters parameters.
                       Also name can't contain any special characters. They will be replaced with empty characters.
                    `}
                  >
                    <HelpIcon />
                  </Tooltip>
                </div>
              )}
            </FormControl>
            <FormControl
              variant="standard"
              sx={{ mb: 3, display: 'block', minWidth: '500px' }}
            >
              <FastField
                component={TextInputComponent}
                id="label"
                name="label"
                label="Field label"
                variant="standard"
                sx={{ minWidth: 1 / 1 }}
                error={formik.touched.label && !!formik.errors.label}
                helperText={formik.errors.label ? formik.errors.label : ''}
              />
            </FormControl>
            <FormControl
              variant="standard"
              sx={{ mb: 3, display: 'block', minWidth: '500px' }}
            >
              <FastField
                component={TextInputComponent}
                id="description"
                name="description"
                label="Field description"
                variant="standard"
                sx={{ minWidth: 1 / 1 }}
                error={
                  formik.touched.description && !!formik.errors.description
                }
                helperText={
                  formik.errors.description ? formik.errors.description : ''
                }
              />
            </FormControl>
            <FormControl
              variant="standard"
              sx={{ mb: 3, display: 'block', minWidth: '500px' }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name="required"
                    checked={formik.values.required}
                    onChange={(_e, checked) =>
                      formik.setFieldValue('required', checked)
                    }
                  />
                }
                label="Is this field required?"
              />
            </FormControl>
            <FormControl
              variant="standard"
              sx={{ mb: 3, display: 'block', minWidth: '500px' }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name="indexed"
                    checked={formik.values.indexed}
                    onChange={(_e, checked) =>
                      formik.setFieldValue('indexed', checked)
                    }
                    disabled={
                      formik.values.type === FieldType.IMAGE_FIELD ||
                      formik.values.type === FieldType.FILE_FIELD
                    }
                  />
                }
                label="Is this field indexed?"
              />
            </FormControl>
            <FormControl
              variant="standard"
              sx={{ mb: 3, display: 'block', minWidth: '500px' }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name="sortable"
                    checked={formik.values.sortable}
                    onChange={(_e, checked) =>
                      formik.setFieldValue('sortable', checked)
                    }
                    disabled={
                      formik.values.type === FieldType.IMAGE_FIELD ||
                      formik.values.type === FieldType.FILE_FIELD
                    }
                  />
                }
                label="Can list of items be sorted by this field?"
              />
            </FormControl>
            <FormControl
              variant="standard"
              sx={{ mb: 3, display: 'block', minWidth: '500px' }}
            >
              <InputLabel id={'widthLabel'}>Width</InputLabel>
              <Select
                labelId="widthLabel"
                value={formik.values.width}
                onChange={formik.handleChange}
                name="width"
                label="Width"
                placeholder="Select field type"
                sx={{ pr: 3, minWidth: 1 / 1 }}
              >
                <MenuItem value="25%">25%</MenuItem>
                <MenuItem value="33%">33%</MenuItem>
                <MenuItem value="50%">50%</MenuItem>
                <MenuItem value="66%">66%</MenuItem>
                <MenuItem value="75%">75%</MenuItem>
              </Select>
            </FormControl>
            {formik.values.type === FieldType.TEXT_FIELD && (
              <TextFieldFormComponent />
            )}
            {formik.values.type === FieldType.NUMBER_FIELD && (
              <NumberFieldFormComponent />
            )}
            {formik.values.type === FieldType.TEXT_AREA_FIELD && (
              <TextAreaFieldFormComponent />
            )}
            {formik.values.type === FieldType.CHECKBOX_FIELD && (
              <CheckboxFieldFormComponent />
            )}
            {(formik.values.type === FieldType.RADIO_FIELD ||
              formik.values.type === FieldType.SELECT_FIELD ||
              formik.values.type === FieldType.MULTI_SELECT_FIELD ||
              formik.values.type ===
                FieldType.MULTI_SELECT_FIELD_WITH_VALUES) && (
              <RadioOrSelectFieldFormComponent />
            )}
            {formik.values.type === FieldType.RELATION_FIELD && (
              <RelationFieldFormComponent />
            )}
            {formik.values.type === FieldType.IMAGE_FIELD && (
              <ImageFieldFormComponent />
            )}
            {formik.values.type === FieldType.FILE_FIELD && (
              <FileFieldFormComponent />
            )}
            {formik.values.type === FieldType.INVENTORY_NUMBER_FIELD && (
              <InventoryNumberFieldFormComponent />
            )}
            <FormControl
              variant="standard"
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'row',
              }}
            >
              <Button
                className={'new-mission-btn'}
                onClick={onCancel}
                type="button"
              >
                <span className={'add-btn-text'}>cancel</span>
              </Button>
              <Button className={'new-mission-btn'} type="submit">
                <span className={'add-btn-text'}>
                  {isUpdate ? 'update' : 'create'}
                </span>
              </Button>
            </FormControl>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FieldFormComponent;
