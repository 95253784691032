import { NumberField } from '@daisy/daisy-common';
import { FormControl, TextField } from '@mui/material';
import { FormikHandlers } from 'formik';
import React from 'react';

import LabelNodeComponent from './label-node.component';

interface Props extends NumberField {
  error: boolean;
  helperText: string;
  value: string;
  onChange: FormikHandlers['handleChange'];
}

const NumberFieldComponent: React.FC<Props> = (props: Props) => {
  const inputProps: Record<string, string | number> = {
    type: 'number',
    inputMode: 'numeric',
  };
  if (props.min) {
    inputProps.min = props.min;
  }
  if (props.max) {
    inputProps.max = props.max;
  }
  if (props.step) {
    inputProps.step = props.step;
  }
  return (
    <FormControl variant="standard" sx={{ mb: 3, display: 'block' }}>
      <TextField
        id={props.name.replaceAll(' ', '_')}
        name={props.name}
        label={
          <LabelNodeComponent
            title={props.label}
            description={props.description}
          />
        }
        sx={{ minWidth: 1 / 1 }}
        error={props.error}
        value={props.value}
        variant="standard"
        onChange={props.onChange}
        inputProps={inputProps}
        helperText={props.helperText}
      />
    </FormControl>
  );
};

export default NumberFieldComponent;
