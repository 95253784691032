import { CheckboxField } from '@daisy/daisy-common';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';

const CheckboxFieldFormComponent: React.FC = () => {
  const formik = useFormikContext<CheckboxField>();
  return (
    <>
      <FormControl variant="standard" sx={{ mb: 3, display: 'block' }}>
        <FormControlLabel
          control={
            <Checkbox
              id="default"
              name="default"
              checked={formik.values.default}
              onChange={(_e, checked) =>
                formik.setFieldValue('default', checked)
              }
            />
          }
          label="Is this field checked by default?"
        />
      </FormControl>
    </>
  );
};

export default CheckboxFieldFormComponent;
