import { InventoryNumberField, ValuesType } from '@daisy/daisy-common';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { FastField, useFormikContext } from 'formik';
import React from 'react';

import TextInputComponent from '../common/configurator-inputs/text-input.component';

const InventoryNumberFieldFormComponent: React.FC = () => {
  const formik = useFormikContext<InventoryNumberField>();
  return (
    <>
      <FormControl
        variant="standard"
        sx={{ mb: 3, display: 'block', minWidth: '500px' }}
      >
        <FastField
          id="prefix"
          name="prefix"
          label="Field prefix"
          variant="standard"
          sx={{ minWidth: 1 / 1 }}
          component={TextInputComponent}
          helperText={
            formik.touched.prefix && formik.errors.prefix
              ? formik.errors.prefix
              : ''
          }
          error={formik.touched.prefix && !!formik.errors.prefix}
        />
      </FormControl>
      <FormControl
        variant="standard"
        sx={{ mb: 3, display: 'block', minWidth: '500px' }}
        error={formik.touched.valueTypes && !!formik.errors.valueTypes}
      >
        <InputLabel id="valueTypes">Value type</InputLabel>
        <Select
          labelId="valueTypes"
          name="valueTypes"
          sx={{ minWidth: 1 / 1 }}
          value={formik.values.valueTypes}
          onChange={formik.handleChange}
        >
          <MenuItem value=""></MenuItem>
          {Object.values(ValuesType).map((val) => (
            <MenuItem value={val} key={val}>
              {val}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText
          error={formik.touched.valueTypes && !!formik.errors.valueTypes}
        >
          {formik.touched.valueTypes && !!formik.errors.valueTypes
            ? formik.errors.valueTypes
            : ''}
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default InventoryNumberFieldFormComponent;
