import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { selectUser } from '../store/features/user';

export const useAdmin = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  useEffect(() => {
    if (user && !user.admin) {
      navigate('/');
    }
  }, []);
};
