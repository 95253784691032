import './card.css';

import { UserRole } from '@daisy/daisy-common';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import React, { ReactNode, useState } from 'react';

import SecureComponent from '../common/secure-component/secure-component.component';

type CardActionType = {
  actionName: string;
  actionHandler: () => void;
  securityRules?: UserRole[];
  actionConfirmationText?: string;
};

type Props = {
  header?: string | ReactNode;
  headerOnClick?: () => void;
  bodyOnClick?: () => void;
  bodyDivContainerClass?: string;
  body: string | ReactNode;
  actions?: Array<CardActionType>;
  actionsFlexColumn?: boolean;
  actionsDivContainerClass?: string;
  userRole?: UserRole;
};

const CustomCardComponent: React.FC<Props> = ({
  header,
  headerOnClick,
  bodyOnClick,
  bodyDivContainerClass,
  body,
  actions = [],
  actionsFlexColumn,
  actionsDivContainerClass,
  userRole,
}) => {
  const [showAlert, setShowAlert] = useState(false);
  const [currentAction, setCurrentAction] = useState<number | null>(null);
  return (
    <div className="card">
      {header && (
        <div
          onClick={headerOnClick ? headerOnClick : undefined}
          className={'body-container card-header'}
          style={{ marginBottom: '10px' }}
        >
          {header}
        </div>
      )}

      <div
        onClick={bodyOnClick ? bodyOnClick : undefined}
        className={`
          body-container ${bodyDivContainerClass || ''}
          ${bodyOnClick ? ' card-can-clicked' : ''}
        `}
      >
        {body}
      </div>

      <Dialog
        open={showAlert}
        onClose={() => {
          setShowAlert(false);
          setCurrentAction(null);
        }}
      >
        <DialogTitle sx={{ minWidth: '500px' }}>Are you sure?</DialogTitle>
        <DialogActions>
          <Button
            className="login-button add-btn"
            type="button"
            onClick={() => {
              setShowAlert(false);
              setCurrentAction(null);
            }}
          >
            no
          </Button>
          <Button
            className="login-button add-btn"
            type="button"
            onClick={() => {
              if (currentAction) {
                actions[currentAction].actionHandler();
              }
              setShowAlert(false);
              setCurrentAction(null);
            }}
            autoFocus
          >
            yes
          </Button>
        </DialogActions>
      </Dialog>

      {actions.length ? (
        <div
          className={
            `flex action-container ${
              actionsFlexColumn === true ? 'flex-column ' : ''
            }` + (actionsDivContainerClass || '')
          }
        >
          {actions.map((action, i) => (
            <SecureComponent
              key={`action-${i}`}
              roles={action.securityRules || []}
              userRole={userRole || UserRole.BLOCKED}
            >
              {/* TODO alert */}
              <div
                className="action-item"
                onClick={() => {
                  if (action.actionConfirmationText) {
                    setCurrentAction(i);
                    setShowAlert(true);
                  } else {
                    action.actionHandler();
                  }
                }}
              >
                {action.actionName}
              </div>
            </SecureComponent>
          ))}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default CustomCardComponent;
