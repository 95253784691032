import React from 'react';
import { Link } from 'react-router-dom';

import styles from './logo.module.scss';

const Logo: React.FC = () => {
  return (
    <Link to="/">
      <div className={styles.logoContainer}></div>
    </Link>
  );
};

export default Logo;
