import { urlGenerator } from '@daisy/daisy-common';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GeoJSON } from 'geojson';

export const gisLayersApi = createApi({
  reducerPath: 'gisLayersApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/geojson' }),
  tagTypes: ['Layers'],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getGisLayers: builder.query<
      GeoJSON[],
      { layers: string[]; missionId: string }
    >({
      query: ({ layers, missionId }) => ({
        url: urlGenerator(
          `/${missionId}/layer`,
          `${window.location.protocol}//${window.location.host}`,
          layers.map((id) => ['name', id]),
        ),
        credentials: 'include',
      }),
      providesTags: ['Layers'],
    }),
  }),
});

export const { useGetGisLayersQuery } = gisLayersApi;
