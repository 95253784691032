import { Field } from '@daisy/daisy-common';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Card, Typography } from '@mui/material';
import type { Identifier, XYCoord } from 'dnd-core';
import React, { useRef } from 'react';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';

interface Props {
  index: number;
  field: Field;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  onEdit: () => void;
  onDelete: () => void;
  disabledDelete: boolean;
}

interface DragItem {
  index: number;
  type: string;
}

const FieldsListItemComponent: React.FC<Props> = ({
  field,
  moveCard,
  index,
  onEdit,
  onDelete,
  disabledDelete,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'CARD',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'CARD',
    item: () => {
      return { index };
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 1 / 1,
        p: 2,
        mb: 2,
        cursor: 'move',
      }}
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
    >
      <Typography variant="h5" style={{ flex: 1 }}>
        {field.label} - {field.type.replaceAll('_', ' ').toLowerCase()}
      </Typography>
      <Box>
        <Button className={'back-btn'} type="button" onClick={onEdit}>
          <span className={'add-btn-text'}>edit</span>
        </Button>
        {!disabledDelete && (
          <Button className={'back-btn'} type="button" onClick={onDelete}>
            <span className={'add-btn-text'}>
              <DeleteIcon />
            </span>
          </Button>
        )}
      </Box>
    </Card>
  );
};

export default FieldsListItemComponent;
