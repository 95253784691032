import { MissionUserDto, UserRole } from '@daisy/daisy-common';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { useApiError } from '../../hooks/useApiError';
import { useGetUsersQuery } from '../../store/api/user.api';
import { setStep } from '../../store/features/mission-configurator';
import { selectUser } from '../../store/features/user';
import HelpPopupComponent from '../common/help-popup/help-popup.component';
import SpinnerComponent from '../common/spinner/spinner.component';

type Props = {
  activeStep: number;
  initialData: { users: MissionUserDto[] };
  handleSubmit: (values: { users: MissionUserDto[] }) => void;
  isUpdate?: boolean;
};

const MissionUsersFormComponent: React.FC<Props> = ({
  activeStep,
  initialData,
  handleSubmit,
  isUpdate,
}) => {
  const { error, isLoading, data } = useGetUsersQuery();
  const user = useSelector(selectUser);
  useApiError(error);
  const dispatch = useDispatch();
  const [userToAdd, setUserToAdd] = useState<string>('');
  return (
    <div className="width100" style={{ marginBottom: '80px' }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Users
      </Typography>
      <Divider sx={{ mb: 3 }} />
      <Formik
        initialValues={initialData}
        validateOnMount={true}
        enableReinitialize={true}
        validationSchema={yup.object({
          users: yup.array().of(
            yup.object().shape({
              userId: yup.string().required(),
              userRole: yup.string().oneOf(Object.values(UserRole)).required(),
            }),
          ),
        })}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <>
              <Box>
                <Typography variant="body2" sx={{ mb: 3 }}>
                  Add user
                </Typography>
                <FieldArray name="users">
                  {(arrayHelpers) => (
                    <>
                      {isLoading ? (
                        <SpinnerComponent />
                      ) : (
                        <Box sx={{ mb: 3, display: 'flex' }}>
                          <FormControl
                            variant="standard"
                            className="Flex"
                            style={{ width: 'calc(50% - 106px)' }}
                          >
                            <InputLabel id="user">User</InputLabel>
                            <Select
                              id="user"
                              label="User"
                              placeholder="Select user"
                              value={userToAdd}
                              onChange={(e) => {
                                setUserToAdd(e.target.value);
                              }}
                              sx={{ pr: 3 }}
                            >
                              {data
                                ?.filter(
                                  (usr) =>
                                    usr.id !== user?.id &&
                                    !formik.values.users
                                      .map((u) => u.userId)
                                      .includes(usr.id),
                                )
                                .map((item, i) => (
                                  <MenuItem
                                    key={`${item.id}-${i}`}
                                    value={item.id}
                                  >
                                    {item.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                          <Button
                            className="new-mission-btn"
                            type="button"
                            disabled={!userToAdd}
                            onClick={() => {
                              arrayHelpers.push({
                                userId: userToAdd,
                                userRole: UserRole.EXPEDITION_READER,
                              });
                              setUserToAdd('');
                            }}
                          >
                            <span className={'add-btn-text'}>add user</span>
                          </Button>
                        </Box>
                      )}
                      <ErrorMessage name="users" />
                      <Divider sx={{ mb: 3 }} />
                      {isUpdate && (
                        <Alert severity="warning" sx={{ mb: 3 }}>
                          Remember that you can&apos;t remove user from
                          expedition, doing so could compromise data integrity.
                          Garbage can button only updates this list. If you want
                          to restrict user access to expedition set his role to
                          &apos;blocked&apos;
                        </Alert>
                      )}
                      {formik.values.users.map((usr, i) => (
                        <Box key={`${usr.userId}-${i}`} sx={{ mb: 3 }}>
                          <Typography
                            variant="body1"
                            sx={{ display: 'inline-block', width: 1 / 4 }}
                          >
                            {data?.find((u) => u.id === usr.userId)?.name}
                          </Typography>
                          <FormControl
                            variant="standard"
                            sx={{ mb: 3, minWidth: 1 / 4 }}
                          >
                            <input
                              type="hidden"
                              name={`users[${i}].userId`}
                              value={formik.values.users[i].userId}
                            />
                            <Select
                              id={`users[${i}].userRole`}
                              name={`users[${i}].userRole`}
                              label="Role"
                              value={formik.values.users[i].userRole}
                              placeholder="Select role"
                              onChange={formik.handleChange}
                              sx={{ pr: 3 }}
                              style={{ width: 'calc(100% - 32px)' }}
                              disabled={usr.userId === user?.id}
                            >
                              {Object.values(UserRole).map((role) => (
                                <MenuItem value={role} key={role}>
                                  {role.replace('_', ' ')}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {usr.userId !== user?.id && (
                            <Button
                              type="button"
                              onClick={() => arrayHelpers.remove(i)}
                              className="trash-btn"
                            >
                              <DeleteIcon />
                            </Button>
                          )}
                        </Box>
                      ))}
                    </>
                  )}
                </FieldArray>
              </Box>
            </>
            <FormControl
              variant="standard"
              sx={{ mb: 3, minWidth: 320 }}
              fullWidth
            >
              <div
                className="width100 flex"
                style={{
                  justifyContent: 'space-between',
                  position: isUpdate ? 'static' : 'fixed',
                  bottom: '0',
                  left: '0',
                  right: '0',
                  background: '#fff',
                  padding: '20px 10px',
                  borderTop: 'solid 1px rgba(0, 0, 0, 0.12)',
                }}
              >
                <HelpPopupComponent>
                  <Typography variant="h4">Expedition users</Typography>
                  <Typography variant="body1">
                    This section of configurator is responsible for adding users
                    into expedition. You have to add at least one. Select user
                    from list and click &apos;Add user&apos; button to add this
                    particular user. Also here you can set users privileges in
                    that expedition. You can choose from for roles:
                    <ul>
                      <li>admin - has full control over the expedition</li>
                      <li>
                        expedition manager - has almost full control over
                        expedition, but with some limitations, e.g., can&apos;t
                        archive expedition
                      </li>
                      <li>
                        expedition participant - can create and edit new records
                        in expedition, but can&apos;t delete them{' '}
                      </li>
                      <li>
                        expedition reader - has read-only access to expedition
                      </li>
                    </ul>
                  </Typography>
                </HelpPopupComponent>
                <Box>
                  {!isUpdate && (
                    <Button
                      className={'back-btn'}
                      type="button"
                      onClick={() => dispatch(setStep(activeStep - 1))}
                    >
                      <span className={'add-btn-text'}>back</span>
                    </Button>
                  )}
                  <Button className={'new-mission-btn'} type="submit">
                    <span className={'add-btn-text'}>
                      {isUpdate ? 'update users' : 'next'}
                    </span>
                  </Button>
                </Box>
              </div>
            </FormControl>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MissionUsersFormComponent;
