import { DataEntity } from '@daisy/daisy-common';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Box,
  Button,
  Card,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import {
  addEntity,
  deleteEntity,
  selectEntities,
  setStep,
  updateEntity,
} from '../../store/features/mission-configurator';
import EntityHelpComponent from '../entity-help/entity-help.component';
import EntityFormComponent from './entity-form.component';
import EntityFormModalComponent from './entity-form-modal.component';
import FieldsFormComponent from './fields-form.component';
import GisLayers from './gis-layers.component';

type Props = {
  activeStep: number;
  handleSubmit: () => void;
  isUpdate?: boolean;
};

const EntitiesFormComponent: React.FC<Props> = ({
  activeStep,
  handleSubmit,
  isUpdate,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const entities = useSelector(selectEntities);
  const [currentEntity, setCurrentEntity] = useState<DataEntity | undefined>(
    undefined,
  );
  const [currentEntityId, setCurrentEntityId] = useState<number | undefined>(
    undefined,
  );
  const [drawerOpen, setDrawerOpen] = useState(false);
  return (
    <div className="width100" style={{ marginBottom: '80px' }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Entities
      </Typography>
      <Box>
        <div
          className="width100 flex"
          style={{
            justifyContent: 'flex-end',
            background: '#fff',
            padding: '10px',
          }}
        >
          <Button
            className={'new-mission-btn'}
            type="button"
            onClick={() => {
              setOpen(true);
              setCurrentEntity(undefined);
              setCurrentEntityId(undefined);
            }}
          >
            <span className={'add-btn-text'}>add entity</span>
          </Button>
          <EntityFormModalComponent
            open={open}
            onClose={() => setOpen(false)}
            title={currentEntity ? 'Update entity' : 'Add new entity'}
          >
            <EntityFormComponent
              initialValues={currentEntity}
              onCancel={() => {
                setOpen(false);
              }}
              onSubmit={(values) => {
                if (
                  currentEntity &&
                  (currentEntityId || currentEntityId === 0)
                ) {
                  dispatch(updateEntity({ data: values, id: currentEntityId }));
                } else {
                  dispatch(addEntity(values));
                }
                setOpen(false);
                setCurrentEntity(undefined);
                setCurrentEntityId(undefined);
              }}
            />
          </EntityFormModalComponent>
        </div>
      </Box>
      <Divider sx={{ mb: 3 }} />
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{ minWidth: 3 / 4 }}
      >
        <Box sx={{ minWidth: '60vw', p: '30px', position: 'relative' }}>
          <IconButton
            onClick={() => {
              setDrawerOpen(false);
              setCurrentEntity(undefined);
              setCurrentEntityId(undefined);
            }}
            sx={{
              position: 'absolute',
              top: '30px',
              right: '30px',
              fontSize: '2rem',
            }}
          >
            <CloseIcon />
          </IconButton>
          <FieldsFormComponent index={currentEntityId} />
        </Box>
      </Drawer>
      <List>
        {entities.map((entity, i) => (
          <ListItem key={`entity-${i}`}>
            <Card
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: 1 / 1,
                p: 2,
              }}
            >
              <Typography
                variant="h6"
                sx={{ width: 1 / 4 }}
                style={{ flex: 1 }}
              >
                {entity.name}
              </Typography>
              <FormControl
                variant="standard"
                sx={{ mb: 3, display: 'block', width: 1 / 3 }}
                error={!entity.label}
              >
                <InputLabel id={`label-${i}`}>Items label</InputLabel>
                <Select
                  labelId={`label-${i}`}
                  value={entity.label}
                  MenuProps={{
                    style: {
                      maxHeight: 400,
                    },
                  }}
                  onChange={(e) => {
                    dispatch(
                      updateEntity({
                        data: { ...entity, label: e.target.value },
                        id: i,
                      }),
                    );
                  }}
                  label="Items label"
                  placeholder="Select field type"
                  sx={{ pr: 3, width: 1 }}
                >
                  <MenuItem value="" hidden disabled></MenuItem>
                  {entity.fields.map((field) => (
                    <MenuItem value={field.name} key={`label-${field.name}`}>
                      {field.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box>
                <Button
                  className={'back-btn'}
                  type="button"
                  onClick={() => {
                    setOpen(true);
                    setCurrentEntity(entity);
                    setCurrentEntityId(i);
                  }}
                >
                  <span className={'add-btn-text'}>edit details</span>
                </Button>
                <Button
                  className={'back-btn'}
                  type="button"
                  onClick={() => {
                    setDrawerOpen(true);
                    setCurrentEntity(entity);
                    setCurrentEntityId(i);
                  }}
                >
                  <span className={'add-btn-text'}>edit fields</span>
                </Button>
                <Button
                  className={'back-btn'}
                  type="button"
                  onClick={() => dispatch(deleteEntity(i))}
                >
                  <span className={'add-btn-text'}>
                    <DeleteIcon />
                  </span>
                </Button>
              </Box>
              <Box sx={{ width: '48px' }}>
                {!entity.fields.length ? (
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                      placement: 'top',
                    }}
                    title="There's no fields in this entity."
                    sx={{ height: '41px' }}
                  >
                    <WarningIcon className="warning-icon" />
                  </Tooltip>
                ) : (
                  ''
                )}
                {!entity.label && (
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                      placement: 'top',
                    }}
                    title="Entity needs label field."
                    sx={{ height: '41px' }}
                  >
                    <WarningIcon className="warning-icon" />
                  </Tooltip>
                )}
              </Box>
            </Card>
          </ListItem>
        ))}
      </List>
      <Box>
        <Typography variant="h4" sx={{ mb: 3 }}>
          Gis layers
        </Typography>
        <GisLayers />
      </Box>
      <div
        className="width100 flex"
        style={{
          justifyContent: 'space-between',
          position: isUpdate ? 'static' : 'fixed',
          bottom: '0',
          left: '0',
          right: '0',
          background: '#fff',
          padding: '20px 10px',
          borderTop: 'solid 1px rgba(0, 0, 0, 0.12)',
        }}
      >
        <EntityHelpComponent />
        <Box>
          {!isUpdate && (
            <Button
              className={'back-btn'}
              type="button"
              onClick={() => dispatch(setStep(activeStep - 1))}
            >
              <span className={'add-btn-text'}>back</span>
            </Button>
          )}
          <Button
            className={'new-mission-btn'}
            type="submit"
            onClick={() => {
              if (!entities.length) {
                alert('Add at least one entity');
                return;
              }
              const hasLabels = entities.map((entity) => !!entity.label);
              const hasFields = entities.map(
                (entity) => !!entity.fields.length,
              );
              if (hasFields.includes(false)) {
                alert('All entities have to have at least one field.');
              } else if (hasLabels.includes(false)) {
                alert('All entities have to have selected label field.');
              } else {
                handleSubmit();
              }
            }}
          >
            <span className={'add-btn-text'}>
              {isUpdate ? 'update entities' : 'next'}
            </span>
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default EntitiesFormComponent;
